import { Autorenew } from "@mui/icons-material";
import { Autocomplete, Button, TextField } from "@mui/material";
import axios from "axios";
import Sidebar from "components/Sidebar/Sidebar";
import { useAuth } from "context/auth/auth";
import { useLayout } from "context/layout/layout";
import { useStore } from "context/store/store";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { defaultMember } from "utils/state";
import config from "../../../services/config";
import "./index.css";

import medal from "../../../assets/Medalla.png";

export const MemberDetailsV2 = () => {
  return (
    <>
      <Sidebar />
      <Wrapper>
        <AddUserSettings />
      </Wrapper>
    </>
  );
};

const Wrapper = ({ children }) => {
  return (
    <div className="relative h-full" style={{ marginLeft: 200 }}>
      <div className="relative h-full" style={{ paddingBottom: 95 }}></div>
      <div
        className=" mx-auto w-full -m-24"
        style={{ height: "100vh", backgroundColor: "rgb(17, 24, 39)" }}
      >
        <div
          className="flex flex-wrap h-full"
          style={{ backgroundColor: "rgb(17, 24, 39)" }}
        >
          <div
            className="w-full h-full"
            style={{ backgroundColor: "rgb(17, 24, 39)" }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

function AddUserSettings() {
  const auth = useAuth();
  const params = useParams();
  const layout = useLayout();
  const store = useStore();
  const [, /* loading */ setLoading] = React.useState(false);
  const [user, setUser] = React.useState(defaultMember);
  const [memberships, setMemberships] = useState([]);
  const [subscriptionType, setSubcriptionType] = useState("Mensual");
  const [txs, setTxs] = useState(null);
  const navigate = useNavigate();

  /**
   *
   * Get the membership list
   */

  useEffect(() => {
    axios
      .get(`${config.url}/membership/list`)
      .then(({ data }) => {
        setMemberships(data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    fetch(`${config.url}/members/find`, {
      method: "POST",
      body: JSON.stringify({ _id: params._id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        res.json().then((response) => {
          let start = new Date(response.subscription.startDate);
          let startDate = start.toISOString().slice(0, 10);
          setUser({
            birthday: response.birthday,
            email: response.email,
            name: response.name,
            phone: response.phone,
            id_number: response.id_number,
            _id: response._id,
            campus: response.campus,
            subscription: {
              membership: response.subscription.membership,
              type: response.subscription.type,
              startDate: startDate,
              endDate: response.subscription.endDate,
            },
          });
        });
      })
      .catch((err) => console.log({ err }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (params._id) {
      console.log(params._id);
      axios
        .get(`${config.url}/app/tx/record/${params._id}`)
        .then(({ data }) => {
          console.log(data);

          if (data && data.txs.length > 0) {
            console.log(data);
            setTxs(data.txs);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [params._id]);

  const handleSubmit = () => {
    setLoading(true);
    store
      .editMember(user)
      .then((res) => {
        setLoading(false);
        layout.successAlert({ message: "Usuario actualizado con exito" });
        navigate(-1);
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        layout.errorAlert({ message: "Error al crear el alumno" });
        return;
      });
  };

  const handleRenew = () => {
    setLoading(true);
    fetch(`${config.url}/members/renew/${user._id}`, {
      method: "POST",
      body: JSON.stringify({ subscriptionType }),
      headers: {
        "Content-type": "application/json",
      },
    })
      .then((res) => {
        res.json().then((response) => {
          setLoading(false);
          layout.successAlert({ message: "Usuario actualizado con exito" });

          navigate("/admin/members");
        });
      })
      .catch((err) => {
        layout.errorAlert({ message: "Error en el servicio" });
        setLoading(false);
      });
  };

  return (
    <div
      className="relative h-full flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
      style={{ backgroundColor: "rgb(17, 24, 39)" }}
    >
      <div
        className="rounded-t  mb-0 py-6 "
        style={{ backgroundColor: "rgb(17, 24, 39)", paddingLeft: 57 }}
      >
        <div
          className="text-center flex justify-between "
          style={{ backgroundColor: "rgb(17, 24, 39)" }}
        >
          <h6 className="text-white  text-xl font-bold">Registro</h6>
        </div>
      </div>

      <div
        className="flex-auto px-4 lg:px-10 py-10 pt-0"
        style={{ backgroundColor: "rgb(17, 24, 39)" }}
      >
        <form>
          <div className="flex flex-wrap pt-6">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-8">
                <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                  Ingresa el nombre y apellidos del alumno
                </label>

                <TextField
                  className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow  w-full ease-linear transition-all duration-150"
                  id="outlined-basic"
                  sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                  value={user.name}
                  onChange={(e) =>
                    setUser({
                      ...user,
                      name: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-3">
                <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                  Ingresa el correo
                </label>

                <TextField
                  className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  id="outlined-basic"
                  variant="outlined"
                  sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                  value={user.email}
                  onChange={(e) =>
                    setUser({
                      ...user,
                      email: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-8">
                <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                  Fecha de nacimiento
                </label>
                <TextField
                  className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  id="outlined-basic"
                  variant="outlined"
                  placeholder="Fecha de nacimiento"
                  type={"date"}
                  sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                  value={user.birthday}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      birthday: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-8">
                <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                  Ingresa el rut
                </label>
                <TextField
                  className="border-0   placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  id="outlined-basic"
                  variant="outlined"
                  sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                  value={user.id_number}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      id_number: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-8">
                <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                  Ingresa el numero de teléfono celular
                </label>
                <TextField
                  className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  id="outlined-basic"
                  variant="outlined"
                  sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                  value={user.phone}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      phone: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-8">
                <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                  Selecciona el plazo del plan de elección
                </label>
                <TextField
                  disabled
                  className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  id="combo-box-demo"
                  placeholder="Plan"
                  value={user.subscription.type}
                  sx={{
                    padding: 0,
                    border: "unset",
                    backgroundColor: "#cbd5e1",
                  }}
                />
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-8">
                <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                  Fecha de inscripcion
                </label>

                <TextField
                  type="date"
                  className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  id="outlined-basic"
                  variant="outlined"
                  sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                  value={user.subscription.startDate}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      subscription: {
                        ...user.subscription,
                        startDate: e.target.value,
                      },
                    });
                  }}
                />
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative w-full mb-8">
                <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                  Sucursal
                </label>
                <TextField
                  disabled
                  className="border-0  placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  id="combo-box-demo"
                  placeholder="Plan"
                  value={user.campus}
                  sx={{
                    padding: 0,
                    border: "unset",
                    backgroundColor: "#cbd5e1",
                  }}
                />
              </div>
            </div>
          </div>

          {/*        {auth.isAdmin && (
            <div className="flex flex-wrap items-center mr-auto ">
              <div
                className="lg:w-2/12"
                style={{ transform: "translate(-8px, 10px)" }}
              >
                <Autorenew sx={{ color: "#cbd5e1" }} />
              </div>
              <>
                <div className="" style={{ width: "46%" }}>
                  <div className="relative w-full m-0 mr-16">
                    <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                      Selecciona el plazo de renovación
                    </label>
                    <Autocomplete
                      className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      id="combo-box-demo"
                      options={memberships}
                      onChange={(e, value) => setSubcriptionType(value.title)}
                      getOptionLabel={(option) => option.title}
                      sx={{
                        padding: 0,
                        border: "unset",
                        backgroundColor: "#cbd5e1",
                      }}
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Plan socio" />
                      )}
                    />
                  </div>
                </div>
                <div className=" px-4 mt-4">
                  <div className="relative" style={{ marginLeft: 24 }}>
                    <Button
                      onClick={handleRenew}
                      variant="contained"
                      sx={{
                        backgroundColor: "#facc15",
                        color: "#fff",
                        fontWeight: 800,
                        width: 190,
                        height: 52,
                      }}
                    >
                      Renovar
                    </Button>
                  </div>
                </div>{" "}
              </>
            </div>
          )} */}

          <button
            onClick={handleSubmit}
            style={{ height: 50, width: "46.2%", marginLeft: 22 }}
            className=" bg-lightBlue-800 mt-8 text-white active:bg-lightBlue-600 font-bold uppercase text-xs  py-2 rounded shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150"
            type="button"
          >
            Publicar
          </button>
        </form>
        <div className="mt-10 py-4 px-4">
          <div style={{ border: ".2px solid #334155" }}></div>
          <p className="text-lg text-white font-bold mt-10  ml-1">
            Historial de transacciones
          </p>
          {txs &&
            txs.length > 0 &&
            txs.map((t) => (
              <TxCard
                timestamp={t.date}
                title={t.products[0].product.name}
                points={t.totalPrice * 0.01}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export const TxCard = ({ title, timestamp, points }) => {
  return (
    <div className="my-3 px-4 py-4 gap-4 rounded-lg tx-card flex ">
      <img src={medal} alt="..." style={{ width: 60 }} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <p className="font-semibold text-white">{title}</p>
        <p className="font-light text-xs mt-1 text-white">
          {new Date(timestamp).toLocaleDateString()} -{" "}
          {new Date(timestamp).toLocaleTimeString()}
        </p>
      </div>
      <div className="ml-auto flex flex-col items-center justify-center">
        <p className="font-bold" style={{ color: "#15803d" }}>
          +{points}
        </p>
        <p className="font-light text-xs text-white">Puntos</p>
      </div>
    </div>
  );
};
