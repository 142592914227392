import axios from "axios";
import config from "services/config";

export class ReportsService {
  static async getTotalSales() {
    try {
      const { data } = await axios.get(`${config.url}/reports/total-sales`);
      return data;
    } catch (error) {
      throw error;
    }
  }
  static async getTotalProducts() {
    try {
      const { data } = await axios.get(`${config.url}/products/total-products`);
      return data;
    } catch (error) {
      throw error;
    }
  }
  static async getTopSellingProducts() {
    try {
      const { data } = await axios.get(
        `${config.url}/reports/top-selling-products`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  static async getSalesBySalesman() {
    try {
      const { data } = await axios.get(
        `${config.url}/reports/sales-by-salesman`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  static async getWeeklySales() {
    try {
      const { data } = await axios.get(`${config.url}/reports/weekly-sales`);
      return data;
    } catch (error) {
      throw error;
    }
  }
  static async getMonthlySales() {
    try {
      const { data } = await axios.get(`${config.url}/reports/monthly-sales`);
      return data;
    } catch (error) {
      throw error;
    }
  }
  static async getYearlySales() {
    try {
      const { data } = await axios.get(`${config.url}/reports/yearly-sales`);
      return data;
    } catch (error) {
      throw error;
    }
  }
  static async getWarehouseMovementsFromToday(warehouseId) {
    const { data } = await axios.get(
      `${config.url}/warehouse/movements/today/${warehouseId}`
    );
    console.log({ data });
    return data;
  }
  static async bussinesCompare() {
    try {
      const { data } = await axios.get(
        `${config.url}/reports/bussines_compare`
      );
      console.log({ data });
      return data;
    } catch (err) {
      console.log(err);
    }
  }
  static async getRisk() {
    try {
      const { data } = await axios.get(`${config.url}/reports/risk`);
      return data;
    } catch (error) {
      throw error;
    }
  }
  static async getDebts() {
    try {
      const { data } = await axios.get(`${config.url}/reports/staff-debt`);
      console.log(data);
      return data;
    } catch (error) {
      throw error;
    }
  }
}
