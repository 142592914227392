import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import Sidebar from "components/Sidebar/Sidebar";
import { Spinner } from "components/Spinner";
import { useLayout } from "context/layout/layout";
import { useStore } from "context/store/store";
import React, { useEffect, useState } from "react";
import { prices } from "utils/state";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { formatCLP } from "utils";
import config from "services/config";
import { useLocation, useNavigate } from "react-router-dom";
import { PAYMENTS } from "utils/state";
/* import DiscountIcon from "@mui/icons-material/Discount";
 */ import PaidIcon from "@mui/icons-material/Paid";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import "./index.css";
import { useLegacy } from "context/legacy/legacy";

const TYPES_OF_CLIENT = [
  {
    name: "Alumno",
    code: "member",
  },
  {
    name: "Retail",
    code: "retail",
  },
];

export const AddSale = () => {
  return (
    <>
      <Sidebar />
      <Wrapper>
        <AddProductSettings />
      </Wrapper>
    </>
  );
};

const Wrapper = ({ children }) => {
  return (
    <div className="relative h-full" id="daily-sale">
      <div className="relative h-full" style={{ paddingBottom: 95 }}></div>
      <div className=" mx-auto w-full -m-24" style={{ height: "100vh" }}>
        <div className="flex flex-wrap h-full">
          <div className="w-full h-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default function AddProductSettings() {
  const { legacy, setLegacy } = useLegacy();
  const location = useLocation();
  const navigate = useNavigate();
  const layout = useLayout();
  const store = useStore();

  const [discount, setDiscount] = useState({
    points: 0,
    amount: 0,
    percentage: 10,
    newTotal: 0,
    isActive: false,
  });

  const [loading, setLoading] = useState(false);
  const [salesman, setSalesman] = useState(null);
  const [products, setProducts] = useState([]);
  const [salesmanId, setSalesmanId] = useState(null);
  const [productlist, setProductList] = useState([]);
  const [retail, setRetail] = useState(null);
  const [headquarter, setHeadQuarter] = useState(null);

  const [, /* campusList */ setCampusList] = useState([]);
  const [warehouseList, setWarehouseList] = useState([]);
  const [warehouseValue, setWarehouseValue] = useState(null);

  const [memberValue, setMemberValue] = useState(null);
  const [, /* memberSelected */ setMemberSelected] = useState(null);

  const [retailValue, setRetailValue] = useState(null);
  const [, /* retailSelected */ setRetailSelected] = useState(null);

  const [clientType, setClientType] = useState(null);

  const [payment, setPayment] = useState(null);
  const [sale, setSale] = useState({
    totalAmount: 0,
    totalProducts: 0,
  });

  useEffect(() => {
    if (!location.state.shiftId) {
      navigate("/admin/warehouses");
    }

    if (location) {
      if (location.state.type === "gym") {
        setClientType({
          name: "Alumno",
          code: "member",
        });
      }

      if (location.state.type === "retail") {
        setClientType({
          name: "Retail",
          code: "retail",
        });
      }
    }
    // eslint-disable-next-line
  }, [location]);

  //Get the campus
  useEffect(() => {
    axios
      .get(`${config.url}/campus/list`)
      .then(({ data }) => {
        setCampusList(data);
        setHeadQuarter(data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  //Get the warehouses
  useEffect(() => {
    axios
      .get(`${config.url}/warehouse/showall`)
      .then((res) => {
        let HQWarehouse = res.data.filter(
          (location) =>
            location.campus && location.campus.type === "HEADQUARTER"
        );
        setWarehouseValue(HQWarehouse[0]);

        if (location.state.type === "retail") {
          setWarehouseValue(HQWarehouse[1]);
        }

        setWarehouseList(HQWarehouse);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  // Get the salesman
  useEffect(() => {
    axios.get(`${config.url}/users/list/salesman`).then(({ data }) => {
      let salesman = data.map((user) => {
        return {
          ...user,
          label: user.name,
        };
      });
      console.log(salesman);
      setSalesman(salesman);
    });
    // eslint-disable-next-line
  }, []);

  // Get the products
  useEffect(() => {
    if (warehouseValue) {
      axios
        .get(
          `${config.url}/warehouse/products/from/inventory/${warehouseValue._id}`
        )
        .then(({ data }) => {
          console.log({ data });
          let products = data
            .map((prod) => {
              return {
                ...prod,
                label: prod._id,
              };
            })
            .filter((p) => p.enabled);
          console.log(products);
          setProducts(products);
        });
    }
    // eslint-disable-next-line
  }, [warehouseValue]);

  // Get the retail clients *
  useEffect(() => {
    axios
      .get(`${config.url}/costumer/list`)
      .then((res) => {
        console.log({ res: res.data });
        setRetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (productlist.length > 0 && discount.isActive) {
      let amount = 0;
      let items = 0;
      for (let sale of productlist) {
        if (sale.qty && sale.totalAmount) {
          amount = amount + sale.totalAmount;
          items = items + sale.qty;
        }
      }

      let discountedPrice = amount - memberValue.points;
      let discountedAmount = amount - discountedPrice;

      setDiscount({
        ...discount,
        amount: discountedAmount,
        newTotal: discountedPrice,
      });

      setSale({
        totalAmount: amount,
        totalProducts: items,
      });

      return;
    }

    if (productlist.length > 0) {
      let amount = 0;
      let items = 0;
      for (let sale of productlist) {
        if (sale.qty && sale.totalAmount) {
          amount = amount + sale.totalAmount;
          items = items + sale.qty;
        }
      }

      setSale({
        totalAmount: amount,
        totalProducts: items,
      });
    }
    // eslint-disable-next-line
  }, [productlist]);

  function resetSaleState() {
    setProductList([]);
    setSale({
      totalAmount: 0,
      totalProducts: 0,
    });
  }

  function handleOptions(evt, value) {
    if (!value) {
      setProductList([]);
      return;
    }

    setProductList([value]);
  }

  function handleOption(value, idx) {
    console.log("value", value);
    let temp = [...productlist];
    let len = temp.length - 1;

    if (idx === len && !value) {
      temp.pop();
    }

    if (idx > len && value) {
      temp = [...temp, value];
    }

    if (idx === len && value) {
      temp[temp.length - 1] = value;
    }

    setProductList(temp);
  }

  function deleteoption(id) {
    let temp = [...productlist];
    let edited = temp.filter((prod) => prod._id !== id);
    setProductList(edited);
  }

  const getPricesOptions = (index = null) => {
    let options = [];
    if (productlist.length < 1) {
      return [];
    }

    if (!index) {
      for (let [key, value] of Object.entries(productlist[0].prices)) {
        if (value) {
          options = [
            ...options,
            { label: prices[key], price: value, priceType: key },
          ];
        }
      }

      return options;
    }

    if (index && productlist.length > index) {
      for (let [key, value] of Object.entries(productlist[index].prices)) {
        if (value) {
          options = [
            ...options,
            { label: prices[key], price: value, priceType: key },
          ];
        }
      }

      return options;
    }

    return [];
  };

  const handlePriceChange = (value, index = null) => {
    let templist = [...productlist];
    let qty = null;

    if (index) {
      qty = templist[index]?.qty;
      templist[index] = {
        ...templist[index],
        selectedprice: value,
        totalAmount: qty ? value.price * qty : "0.",
      };

      setProductList(templist);
      return;
    }

    qty = templist[0]?.qty;
    templist[0] = {
      ...templist[0],
      selectedprice: value,
      totalAmount: qty ? value.price * qty : "0.",
    };

    setProductList(templist);
  };

  const handleQuantity = (e = null, index = null) => {
    let templist = [...productlist];
    let newSale = { ...sale };
    let selectedprice = null;
    let qty = null;

    if (index) {
      selectedprice = templist[index].selectedprice?.price;
      qty = Number(e.target.value);
      templist[index] = {
        ...templist[index],
        qty: qty,
        totalAmount: selectedprice * qty,
      };

      newSale.totalAmount = newSale.totalAmount + selectedprice * qty;
      newSale.totalProducts = newSale.totalProducts + qty;
      console.log(newSale);
      setSale(newSale);
      setProductList(templist);
      return;
    }

    selectedprice = templist[0].selectedprice?.price;
    qty = Number(e.target.value);
    templist[0] = {
      ...templist[0],
      qty: qty,
      totalAmount: selectedprice * qty,
    };

    newSale.totalAmount = newSale.totalAmount + selectedprice * qty;
    newSale.totalProducts = newSale.totalProducts + qty;
    console.log(newSale);
    setSale(newSale);
    setProductList(templist);
  };

  const handleSubmit = async () => {
    if (!salesmanId) {
      layout.errorAlert({ message: `Debe seleccionar un vendedor` });
      return;
    }
    if (!payment) {
      layout.errorAlert({ message: `Debe seleccionar un método de pago` });
      return;
    }

    if (!salesmanId) {
      layout.errorAlert({ message: `Debe seleccionar un vendedor` });
      return;
    }

    if (!clientType) {
      layout.errorAlert({ message: `Debe seleccionar un tipo de cliente` });
      return;
    }

    if (clientType && clientType.code === "member" && !memberValue) {
      layout.errorAlert({ message: `Debe seleccionar un alumno` });
      return;
    }

    if (clientType && clientType.code === "retail" && !retailValue) {
      layout.errorAlert({ message: `Debe seleccionar un cliente` });
      return;
    }

    if (productlist.length < 1) {
      layout.errorAlert({ message: `Debe seleccionar al menos un producto` });
      return;
    }
    if (sale.totalAmount < 500) {
      layout.errorAlert({ message: `Debe seleccionar el precio` });
      return;
    }

    setLoading(true);
    let selectedProducts = productlist.map((item) => ({
      productId: item._id,
      quantity: item.qty,
      priceType: item.selectedprice.priceType,
    }));

    let CUPON =
      discount.isActive && discount.points === 0 ? discount.percentage : null;

    let document = {
      selectedProducts,
      salesmanId: salesmanId._id,
      shiftId: location.state.shiftId,
      payment: payment.code,
      costumerId: retailValue && retailValue._id,
      memberId: memberValue && memberValue._id,
      campusId: headquarter._id,
      warehouseId: warehouseValue && warehouseValue._id,
      cupon: CUPON,
      points: discount.points,
    };

    store
      .registerSale(document)
      .then((res) => {
        setLoading(false);
        console.log(res);
        resetSaleState();
        layout.successAlert({ message: `Venta creada exitósamente` });

        layout.useConfetti();

        navigate(-1);
      })
      .catch((err) => {
        setLoading(false);
        layout.errorAlert({ message: `Error en el servicio: ${err}` });
        resetSaleState();
        console.log(err);
      });
  };

  //** */
  const handleClienType = (e, v) => {
    if (v.code === "member") {
      setRetailValue(null);
      setRetailSelected(null);
      setClientType(v);
      return;
    }

    if (v.code === "retail") {
      setMemberSelected(null);
      setMemberValue(null);
      setClientType(v);
      return;
    }
  };

  function handleLegacyPoints({ target: { value } }) {
    if (value === "true") {
      setDiscount({
        ...discount,
        points: 0,
        isActive: false,
        amount: 0,
        newTotal: 0,
      });
      return;
    }

    let points = memberValue.points; // 10% discount
    let discountedPrice = sale.totalAmount - points;
    let discountedAmount = sale.totalAmount - discountedPrice;

    if (points > sale.totalAmount) {
      discountedPrice = 0;
      discountedAmount = sale.totalAmount;
      points = points - sale.totalAmount;
    }

    setDiscount({
      ...discount,
      isActive: true,
      points: points,
      amount: discountedAmount,
      newTotal: discountedPrice,
    });
  }

  return (
    <>
      {loading && <Spinner />}

      <div
        className="relative h-full flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
        style={{ backgroundColor: "rgb(17, 24, 39)" }}
      >
        <div
          className="rounded-t  mb-0 py-6 "
          style={{ backgroundColor: "rgb(17, 24, 39)", paddingLeft: 40 }}
        >
          <div className="text-center flex items-center">
            <AddShoppingCartIcon sx={{ marginRight: 2, color: "#ccc" }} />
            <h6 className="text-white text-xl">Ingresar nueva venta</h6>
          </div>
        </div>

        <div className=" lg:px-10 md:px-4 sm:px-2 pt-0">
          <form>
            <div className="flex flex-wrap pt-6">
              <div className="w-full lg:w-4/12 md:w-6/12 px-4 ">
                <div className="relative w-full mb-3 opacity-50">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el campus
                  </label>
                  <TextField
                    disabled
                    value={headquarter && headquarter.title}
                    sx={{
                      padding: 0,
                      marginRight: 2,
                      border: "none",
                      background: "#cbd5e1",
                    }}
                    variant="outlined"
                    type="text"
                    className="rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 md:w-6/12 px-4">
                <div className="relative w-full mb-3 opacity-50">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Bodega Seleccionada
                  </label>
                  <TextField
                    disabled
                    value={warehouseValue && warehouseValue.name}
                    sx={{
                      padding: 0,
                      marginRight: 2,
                      border: "none",
                      background: "#cbd5e1",
                    }}
                    variant="outlined"
                    type="text"
                    className="rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              {/*        <div className="w-full lg:w-4/12 md:w-6/12  px-4">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa bodega
                  </label>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Selecciona bodega" />
                    )}
                    clearIcon={false}
                    options={warehouseList}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option._id}>
                          {option.name}
                        </li>
                      );
                    }}
                    sx={{
                      padding: 0,
                      border: "none",
                      marginRight: 2,
                      backgroundColor: "#cbd5e1",
                    }}
                    value={warehouseValue && warehouseValue}
                    onChange={(e, v) => setWarehouseValue(v)}
                    getOptionLabel={(option) => option.name}
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div> */}
              <div className="w-full lg:w-4/12 md:w-6/12  px-4">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el vendedor
                  </label>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Selecciona un vendedor"
                      />
                    )}
                    clearIcon={false}
                    options={salesman}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option._id}>
                          {option.name}
                        </li>
                      );
                    }}
                    sx={{
                      padding: 0,
                      border: "none",
                      marginRight: 2,
                      backgroundColor: "#cbd5e1",
                    }}
                    onChange={(e, value) => setSalesmanId(value)}
                    getOptionLabel={(option) => option.name}
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 md:w-6/12  px-4">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Método de pago
                  </label>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Selecciona un método de pago"
                      />
                    )}
                    options={PAYMENTS}
                    clearIcon={false}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option._id}>
                          {option.name}
                        </li>
                      );
                    }}
                    sx={{
                      padding: 0,
                      border: "none",
                      marginRight: 2,
                      backgroundColor: "#cbd5e1",
                    }}
                    onChange={(e, value) => setPayment(value)}
                    getOptionLabel={(option) => option.name}
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              {/** */}
              {!clientType && (
                <div className="w-full lg:w-4/12 md:w-6/12  px-4">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el tipo de comprador
                  </label>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Selecciona un tipo" />
                    )}
                    clearIcon={false}
                    options={TYPES_OF_CLIENT}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option._id}>
                          {option.name}
                        </li>
                      );
                    }}
                    sx={{
                      padding: 0,
                      border: "none",
                      marginRight: 2,
                      backgroundColor: "#cbd5e1",
                    }}
                    onChange={handleClienType}
                    getOptionLabel={(option) => option.name}
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              )}
              {clientType && clientType.code === "member" && (
                <div className="w-full lg:w-4/12 md:w-6/12  px-4">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el alumno
                  </label>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Selecciona un alumno"
                      />
                    )}
                    disabled={
                      !clientType ||
                      (clientType && clientType.code === "retail")
                    }
                    clearIcon={false}
                    options={legacy.members}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option._id}>
                          {option.id_number} {option.name}
                        </li>
                      );
                    }}
                    disablePortal
                    sx={{
                      padding: 0,
                      border: "none",
                      marginRight: 2,
                      backgroundColor: "#cbd5e1",
                    }}
                    onChange={(e, v) => setMemberValue(v)}
                    onInputChange={(e, v) => setMemberSelected(v)}
                    getOptionLabel={(option) =>
                      option.id_number || "Default Label"
                    }
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              )}

              {clientType && clientType.code === "retail" && (
                <div className="w-full lg:w-4/12 md:w-6/12  px-4">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el cliente retail
                  </label>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Selecciona un cliente retail"
                      />
                    )}
                    clearIcon={false}
                    options={retail}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option._id}>
                          {option.name}
                        </li>
                      );
                    }}
                    disablePortal
                    sx={{
                      padding: 0,
                      border: "none",
                      marginRight: 2,
                      backgroundColor: "#cbd5e1",
                    }}
                    disabled={
                      !clientType ||
                      (clientType && clientType.code === "member")
                    }
                    onChange={(e, v) => setRetailValue(v)}
                    onInputChange={(e, v) => setRetailSelected(v)}
                    getOptionLabel={(option) => option.name || "Default Label"}
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              )}
            </div>
            <hr className="mt-6 mb-6 border-b-1 border-blueGray-300" />
          </form>
        </div>

        <div
          className="flex-auto px-4 lg:px-10 pt-0"
          style={{ backgroundColor: "rgb(17, 24, 39)" }}
        >
          <form>
            {warehouseValue ? (
              <div className="flex flex-wrap pt-10">
                <div className="w-full lg:w-12/12 px-0 ">
                  <div
                    className="relative w-full mb-3"
                    style={{ display: "flex" }}
                  >
                    <div
                      className="flex items-center"
                      style={{
                        width: 100,
                        gap: 20,
                        padding: "0 15px",
                      }}
                    ></div>

                    <Autocomplete
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Selecciona un producto"
                        />
                      )}
                      options={products}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option._id}>
                            {option.name}
                          </li>
                        );
                      }}
                      sx={{
                        padding: 0,
                        border: "unset",
                        marginRight: 2,
                        width: 600,
                        backgroundColor: "#cbd5e1",
                      }}
                      onChange={handleOptions}
                      getOptionLabel={(option) => option.name}
                      type="text"
                      className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                    <Autocomplete
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Lista de precio" />
                      )}
                      options={getPricesOptions()}
                      sx={{
                        padding: 0,
                        border: "unset",
                        marginRight: 2,
                        width: 300,
                        backgroundColor: "#cbd5e1",
                      }}
                      inputValue={
                        productlist.length > 0 && productlist[0].selectedprice
                          ? productlist[0].selectedprice.label
                          : ""
                      }
                      value={
                        productlist.length > 0 && productlist[0].selectedprice
                          ? productlist[0].selectedprice.label
                          : ""
                      }
                      onChange={(evt, value) => handlePriceChange(value)}
                      type="text"
                      className="border-0  placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    />
                    <TextField
                      disabled
                      value={
                        productlist.length > 0
                          ? productlist[0].selectedprice?.price
                          : ""
                      }
                      placeholder="Precio"
                      sx={{ marginRight: 2, backgroundColor: "#cbd5e1" }}
                      className=" border-0   text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-1/4 ease-linear transition-all duration-150 "
                    />
                    <TextField
                      type="number"
                      label="Unidades"
                      sx={{ marginRight: 2, backgroundColor: "#cbd5e1" }}
                      onChange={handleQuantity}
                      value={
                        productlist.length > 0 && productlist[0].qty
                          ? productlist[0].qty
                          : ""
                      }
                      className=" border-0  placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-1/4 ease-linear transition-all duration-150 "
                    />
                    <TextField
                      value={
                        productlist.length > 0 && productlist[0].totalAmount
                          ? `$ ${productlist[0].totalAmount}`
                          : "$0."
                      }
                      disabled
                      placeholder="Total"
                      sx={{ color: "#000", backgroundColor: "#cbd5e1" }}
                      className=" border-0  text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-1/4 ease-linear transition-all duration-150 "
                    />
                  </div>
                </div>
                {productlist.map((option, idx) => {
                  return (
                    <div className="w-full lg:w-12/12 px-0" key={idx}>
                      <div
                        className="relative w-full mb-3"
                        style={{ display: "flex" }}
                      >
                        <div
                          className="flex items-center"
                          style={{
                            width: 100,
                            gap: 20,
                            padding: "0 15px",
                          }}
                        >
                          {idx < productlist.length - 1 && (
                            <i
                              onClick={() =>
                                deleteoption(productlist[idx + 1]._id)
                              }
                              class="fas fa-trash"
                              style={{
                                fontSize: 19,
                                color: "#dc2626",
                                cursor: "pointer",
                              }}
                            ></i>
                          )}
                        </div>
                        <Autocomplete
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Selecciona un producto"
                            />
                          )}
                          options={products}
                          renderOption={(props, option) => {
                            return (
                              <li {...props} key={option._id}>
                                {option.name}
                              </li>
                            );
                          }}
                          getOptionLabel={(option) => option.name}
                          sx={{
                            padding: 0,
                            border: "unset",
                            marginRight: 2,
                            width: 600,
                            backgroundColor: "#cbd5e1",
                          }}
                          inputValue={
                            productlist.length > idx + 1 &&
                            productlist[idx + 1].name
                              ? productlist[idx + 1].name
                              : ""
                          }
                          value={
                            productlist.length > idx + 1 &&
                            productlist[idx + 1].name
                              ? productlist[idx + 1].name
                              : ""
                          }
                          onChange={(evt, value) =>
                            handleOption(value, idx + 1)
                          }
                          type="text"
                          placeholder="Ej: Sabores"
                          className="border-0 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        />
                        <Autocomplete
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              placeholder="Lista de precios"
                            />
                          )}
                          options={getPricesOptions(idx + 1)}
                          sx={{
                            padding: 0,
                            border: "unset",
                            marginRight: 2,
                            width: 300,
                            backgroundColor: "#cbd5e1",
                          }}
                          onChange={(evt, value) =>
                            handlePriceChange(value, idx + 1)
                          }
                          inputValue={
                            productlist.length > idx + 1 &&
                            productlist[idx + 1].selectedprice
                              ? productlist[idx + 1].selectedprice.label
                              : ""
                          }
                          value={
                            productlist.length > idx + 1 &&
                            productlist[idx + 1].selectedprice
                              ? productlist[idx + 1].selectedprice.label
                              : ""
                          }
                          type="text"
                          placeholder="Ej: Sabores"
                          className="border-0   text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                        />
                        <TextField
                          disabled
                          value={
                            productlist.length > idx + 1 &&
                            productlist[idx + 1].selectedprice
                              ? productlist[idx + 1].selectedprice?.price
                              : ""
                          }
                          placeholder="Precio"
                          sx={{ marginRight: 2, backgroundColor: "#cbd5e1" }}
                          className=" border-0   text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-1/4 ease-linear transition-all duration-150 "
                        />
                        <TextField
                          type="number"
                          placeholder="Cantidad"
                          value={
                            productlist.length > idx + 1 &&
                            productlist[idx + 1].qty
                              ? productlist[idx + 1].qty
                              : ""
                          }
                          onChange={(e) => handleQuantity(e, idx + 1)}
                          sx={{ marginRight: 2, backgroundColor: "#cbd5e1" }}
                          className=" border-0   text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-1/4 ease-linear transition-all duration-150 "
                        />
                        <TextField
                          disabled
                          placeholder="Total"
                          value={
                            productlist.length > idx + 1 &&
                            productlist[idx + 1].totalAmount
                              ? `$ ${productlist[idx + 1].totalAmount}`
                              : "$0."
                          }
                          sx={{ color: "#000", backgroundColor: "#cbd5e1" }}
                          className=" border-0  placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-1/4 ease-linear transition-all duration-150 "
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <p className="text-white text-center">
                Selecciona una bodega para cargar productos en stock
              </p>
            )}

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <div>
              <div
                style={{
                  width: "100%",
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div className="flex flex-row mt-2 w-full items-center">
                  <LoyaltyIcon sx={{ color: "#ccc", marginRight: 4 }} />
                  <h2 className="flex flex-row w-full items-center text-white">
                    ¿Usar Legacy puntos? - Tienes{" "}
                    {memberValue ? memberValue.points : 0} Legacy puntos
                    <span style={{ marginLeft: "auto" }}>
                      <label className="mr-2 text-xs">Aplicar</label>
                      <input
                        type={"checkbox"}
                        value={discount.isActive}
                        onChange={handleLegacyPoints}
                      />
                    </span>
                  </h2>
                </div>
                {/*     <div className="flex flex-row mt-2 w-full items-center">
                  <DiscountIcon sx={{ color: "#ccc", marginRight: 4 }} />
                  <h2 className="flex flex-row w-full items-center text-white">
                    ¿Tienes un descuento?
                    <Autocomplete
                      disabled
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Cupones" />
                      )}
                      options={[{ label: "10%" }]}
                      sx={{
                        padding: 0,
                        border: "unset",
                        marginLeft: "auto",
                        width: 130,
                        backgroundColor: "#cbd5e1",
                      }}
                      onChange={handleDiscount}
                      type="text"
                      className="border-0   text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    />
                  </h2>
                </div> */}

                <div className="flex flex-row mt-3 w-full items-center">
                  <ShoppingCartIcon sx={{ color: "#ccc", marginRight: 4 }} />
                  <h2 className="flex flex-row w-full items-center text-white">
                    Cantidad de productos{" "}
                    <p
                      style={{
                        marginLeft: "auto",
                        fontSize: 22,
                        fontWeight: 500,
                      }}
                    >
                      {sale.totalProducts} <span className="text-xs">uni.</span>
                    </p>
                  </h2>
                </div>
                <div className="flex flex-row mt-3 w-full items-center">
                  <PaidIcon sx={{ color: "#ccc", marginRight: 4 }} />
                  <h2 className="flex flex-row w-full items-center text-white">
                    Subtotal{" "}
                    <p
                      style={{
                        marginLeft: "auto",
                        fontSize: 22,
                        fontWeight: 500,
                      }}
                    >
                      {formatCLP(sale.totalAmount)}
                    </p>
                  </h2>
                </div>
                <div className="flex flex-row mt-3 w-full items-center">
                  <PaidIcon sx={{ color: "#ccc", marginRight: 4 }} />
                  <h2 className="flex flex-row w-full items-center text-white">
                    Descuentos{" "}
                    <p
                      style={{
                        marginLeft: "auto",
                        fontSize: 22,
                        fontWeight: 500,
                      }}
                    >
                      {discount.isActive
                        ? formatCLP(discount.amount)
                        : formatCLP(0)}
                    </p>
                  </h2>
                </div>
                <div className="flex flex-row mt-3 w-full items-center">
                  <PaidIcon sx={{ color: "#ccc", marginRight: 4 }} />
                  <h2 className="flex flex-row w-full items-center text-white">
                    Total{" "}
                    <p
                      style={{
                        marginLeft: "auto",
                        fontSize: 22,
                        fontWeight: 500,
                      }}
                    >
                      {discount.isActive
                        ? formatCLP(discount.newTotal)
                        : formatCLP(sale.totalAmount)}
                    </p>
                  </h2>
                </div>
              </div>
            </div>
            <div
              className="my-6 w-full"
              style={{ transform: "translateX(23px)" }}
            >
              <button
                onClick={handleSubmit}
                style={{ height: 50, width: "46%" }}
                className=" bg-lightBlue-500  text-white active:bg-lightBlue-600 font-bold uppercase text-xs rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
                Publicar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
