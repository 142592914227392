import React from "react";
import PropTypes from "prop-types";
import { formatCLP } from "utils";
import { Skeleton } from "@mui/material";
import { Visibility } from "@mui/icons-material";

export default function CardStats({
  handleIconClick,
  statSubtitle,
  statTitle,
  statArrow,
  statPercent,
  statPercentColor,
  statDescripiron,
  statIconName,
  statIconColor,
  icon = null,
}) {
  return (
    <>
      <div
        className="  rounded  shadow-lg w-1/5"
        style={{
          backgroundColor: "#082f49",
          width: "100%",
          minWidth: "100%",
          minHeight: "100%", // Adjust width to fill the grid cell
          height: "100%",
        }}
      >
        <div className="p-4 ">
          <div className="flex">
            <div className="w-full">
              <h5 className="text-blueGray-400 uppercase font-bold text-xs mb-1">
                {statSubtitle}
              </h5>

              {statTitle ? (
                <div className="flex justify-between w-full items-center">
                  <span className="font-semibold text-lg text-white ">
                    {statTitle}
                  </span>
                  {icon ? (
                    <Visibility
                      onClick={handleIconClick}
                      sx={{ fontSize: 22, color: "#ccc", cursor: "pointer" }}
                    />
                  ) : null}
                </div>
              ) : (
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem", bgcolor: "#075985" }}
                />
              )}
            </div>
          </div>
          <p className="text-sm text-white ">
            {statPercent && (
              <span className={statPercentColor + " mr-2"}>
                {" "}
                {formatCLP(statPercent)}
              </span>
            )}
            {statTitle ? (
              <div className="mt-1" style={{ display: "inline-block" }}>
                <span className="whitespace-nowrap text-xs">
                  {statDescripiron}
                </span>
              </div>
            ) : (
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem", bgcolor: "#075985" }}
              />
            )}
          </p>
        </div>
      </div>
    </>
  );
}

CardStats.defaultProps = {
  statSubtitle: "Traffic",
  statTitle: "350,897",
  statArrow: null,
  statPercent: null,
  statPercentColor: "text-emerald-500",
  statDescripiron: "",
  statIconName: "far fa-chart-bar",
  statIconColor: "bg-red-500",
};

CardStats.propTypes = {
  statSubtitle: PropTypes.string,
  statTitle: PropTypes.string,
  statArrow: PropTypes.oneOf(["up", "down"]),
  statPercent: PropTypes.string,
  // can be any of the text color utilities
  // from tailwindcss
  statPercentColor: PropTypes.string,
  statDescripiron: PropTypes.string,
  statIconName: PropTypes.string,
  // can be any of the background color utilities
  // from tailwindcss
  statIconColor: PropTypes.string,
};
