/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// components

import PagesDropdown from "components/Dropdowns/PagesDropdown.js";
import { useAuth } from "context/auth/auth";

export default function Navbar(props) {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  const auth = useAuth();

  const handleLogout = () => {
    auth.revokeAuth({ isMember: true });
  };

  return (
    <>
      <nav className="top-0 absolute z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg">
        <p
          className="text-white px-4"
          onClick={handleLogout}
          style={{ cursor: "pointer" }}
        >
          Cerrar sesión
        </p>
      </nav>
    </>
  );
}
