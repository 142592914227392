import React, { useEffect, useState, useCallback } from "react";
import Sidebar from "components/Sidebar/Sidebar.js";
import CardTable from "components/Cards/CardTable";
import axios from "axios";
import { TableDataItem } from "components/Cards/CardTable";
import { Spinner } from "components/Spinner";
import config from "services/config";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  DialogContentText,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import SwitchAccessShortcutAddIcon from "@mui/icons-material/SwitchAccessShortcutAdd";
import { useAuth } from "context/auth/auth";
import { useLayout } from "context/layout/layout";
import { useLegacy } from "context/legacy/legacy";

const productsHeaders = [
  "Nombre",
  "Membresia",
  "Rut",
  "Termino",
  "Status",
  "Puntos",
];
const ACTIONS = ["Acciones"];

export const MembersList = React.memo(({ color = null }) => {
  const { legacy, setLegacy } = useLegacy();
  const auth = useAuth();
  const layout = useLayout();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [temp, setTemp] = useState(legacy.members);
  const [query, setQuery] = useState("");

  const [freepassSelected, setFreepassSelected] = useState({
    open: false,
    member: null,
    memberId: null,
    available: null,
    invited_rut: "",
    invited_email: "",
  });

  useEffect(() => {
    setLoading(false);

    if (legacy.members.length < 1) {
      setLoading(true);
      axios
        .get(`${config.url}/members/showall`)
        .then((res) => {
          setLegacy({ ...legacy, members: res.data });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    return () => {
      setLegacy({ ...legacy, members: temp });
    };
  }, []);

  useEffect(() => {
    if (query.length > 0 && temp && temp.length > 0) {
      const filtered = temp.filter((p) => p.id_number.includes(query));
      setLegacy({ ...legacy, members: filtered });
    } else {
      setLegacy({ ...legacy, members: temp });
    }
  }, [query, temp]);

  /*   useEffect(() => {
    axios
      .get(`${config.url}/members/showall`)
      .then((res) => {
        setMembers(res.data);
        setTemp(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []); */

  const seeDetails = useCallback(
    ({ member_id }) => {
      navigate(`/members/view/${member_id}`);
    },
    [navigate]
  );

  /*   const handleGymVisit = useCallback(
    ({ memberId }) => {
      setLoading(true);
      axios
        .get(`${config.url}/visit/new-visit/${memberId}`)
        .then(() => {
          setLoading(false);
          layout.successAlert({ message: "Visita registrada exitosamente" });
        })
        .catch(() => {
          setLoading(false);
          layout.errorAlert({ message: "Error en el servidor" });
        });
    },
    [layout]
  ); */

  return (
    <>
      {loading && <Spinner />}
      <Sidebar />
      {legacy.members.length < 1 && null}
      <div className="relative" style={{ marginLeft: 200 }}>
        <div className="relative " style={{ paddingBottom: 95 }}></div>
        <div className=" mx-auto w-full -m-24">
          <div className="flex flex-wrap">
            <div className="p-4 ">
              <p className="text-white ml-2">
                Haz click en Ctrl + F para buscar por rut o nombre
              </p>
            </div>
            <div className="w-full  h-full">
              <CardTable
                title={"ALUMNOS TEMPLE GYM"}
                headers={[...productsHeaders, ...ACTIONS]}
                /*                 filterComponent={true}
                 */ add={auth.isAdmin ? "Agregar Alumno" : null}
                routeadd={auth.isAdmin ? "/members/add" : null}
                query={query}
                setQuery={setQuery}
                color={"dark"}
                /*                 filterPlaceholder="Buscar usuario por rut"
                 */
              >
                {legacy.members.length > 0 &&
                  legacy.members.map((m, idx) => (
                    <tr key={idx}>
                      <TableDataItem
                        sx={{
                          maxWidth: 40,
                          fontWeight: 600,
                          fontSize: 12,
                          textAlign: "left",
                          transform: "translateX(10px)",
                        }}
                      >
                        {m.name}
                      </TableDataItem>

                      <TableDataItem sx={{ fontSize: 12, maxWidth: 100 }}>
                        {m.subscription
                          ? m.subscription.membership?.title ||
                            m.subscription.type
                          : "No hay datos"}
                      </TableDataItem>
                      <TableDataItem sx={{ fontSize: 12, maxWidth: 150 }}>
                        {m.id_number}
                      </TableDataItem>

                      <TableDataItem sx={{ fontSize: 12, maxWidth: 100 }}>
                        {new Date(m.subscription.endDate).toLocaleDateString()}
                      </TableDataItem>
                      <TableDataItem sx={{ fontSize: 12 }}>
                        <p
                          style={{
                            backgroundColor:
                              new Date(m.subscription.endDate) > new Date()
                                ? "#15803d"
                                : "red",
                            color: "#fff",
                            borderRadius: 5,
                            padding: "2px 5px",
                          }}
                        >
                          {new Date(m.subscription.endDate) > new Date()
                            ? "En curso"
                            : "Vencido"}
                        </p>
                      </TableDataItem>
                      <TableDataItem
                        sx={{
                          fontSize: 12,
                          maxWidth: 50,
                          textAlign: "center",
                        }}
                      >
                        {m.points}
                      </TableDataItem>
                      <TableDataItem sx={{ maxWidth: 50 }}>
                        <Tooltip title={"Freepass"}>
                          <IconButton
                            onClick={() =>
                              window.open(`/freepass/view/${m._id}`, "_blank")
                            }
                          >
                            <SwitchAccessShortcutAddIcon
                              sx={{ color: "#ccc", fontSize: 20 }}
                            />
                          </IconButton>
                        </Tooltip>

                        <IconButton
                          onClick={() =>
                            seeDetails({
                              member_id: m._id,
                            })
                          }
                        >
                          <VisibilityIcon
                            sx={{ color: "#ccc", fontSize: 20 }}
                          />
                        </IconButton>
                      </TableDataItem>
                    </tr>
                  ))}
              </CardTable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
});
