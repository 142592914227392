import React, { useEffect, useState } from "react";
import Sidebar from "components/Sidebar/Sidebar.js";
import CardTable from "components/Cards/CardTable";
import axios from "axios";
import { TableDataItem } from "components/Cards/CardTable";
/* import { useStore } from "../../context/store/store";
 */ import { Spinner } from "components/Spinner";
/* import { useStore } from "context/store/store";
 */ /* import VisibilityIcon from "@mui/icons-material/Visibility";
 */ import config from "services/config";
import { useAuth } from "context/auth/auth";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useStore } from "context/store/store";

const PRODUCT_HEADERS = ["Nombre", "Email", "Rol"];

const ACTIONS = "Acciones";

const ADMIN_HEADERS = [...PRODUCT_HEADERS, ACTIONS];

export function UsersList({ color = null }) {
  const store = useStore();
  const auth = useAuth();
  const [products, setProductos] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${config.url}/users/showall`)
      .then((res) => {
        setProductos(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDelete = async ({ user_id }) => {
    setLoading(true);
    try {
      await store.deleteUser({ user_id });
      let newproducts = products.filter((p) => p._id !== user_id);
      setLoading(false);
      setProductos(newproducts);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  return (
    <>
      {loading && <Spinner />}
      <Sidebar />
      <div className="relative" style={{ marginLeft: 200 }}>
        <div className="relative " style={{ paddingBottom: 95 }}></div>
        <div className=" mx-auto w-full -m-24">
          <div className="flex flex-wrap">
            <div className="w-full  h-full">
              <CardTable
                title={"Lista de usuarios"}
                headers={auth.isAdmin ? ADMIN_HEADERS : PRODUCT_HEADERS}
                add="Agregar usuario"
                routeadd="/users/add"
                color={"dark"}
              >
                {products &&
                  products.length > 0 &&
                  products.map((product, idx) => {
                    return (
                      <tr>
                        <TableDataItem
                          sx={{
                            fontWeight: 600,
                            textAlign: "left",
                            transform: "translateX(25px)",
                          }}
                        >
                          {product.name}
                        </TableDataItem>
                        <TableDataItem>{product.email}</TableDataItem>

                        <TableDataItem>{product.role}</TableDataItem>

                        {auth.isAdmin && (
                          <TableDataItem>
                            <IconButton
                              onClick={() =>
                                handleDelete({ user_id: product._id })
                              }
                            >
                              <DeleteIcon sx={{ color: "#b91c1c" }} />
                            </IconButton>
                          </TableDataItem>
                        )}
                      </tr>
                    );
                  })}
              </CardTable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
