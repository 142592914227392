import axios from "axios";
import config from "services/config";

export class DailyB2bService {
  static async openShift({ initialBalance }) {
    try {
      const { data } = await axios.get(
        `${config.url}/dailyb2b/open-shift/${initialBalance}`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async delete(shiftId) {
    try {
      const { data } = await axios.delete(
        `${config.url}/dailyb2b/delete/${shiftId}`
      );
      console.log(data);
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async verifyShift() {
    try {
      const { data } = await axios.get(
        `${config.url}/dailyb2b/get-shift-for-today`
      );
      return data;
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
}
