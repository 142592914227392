import { createContext, useState, useContext, useEffect } from "react";

const LegacyContext = createContext();

const LEGACY = {
  warehouses: [],
  members: [],
  users: [],
};

const LegacyProvider = ({ children }) => {
  const [legacy, setLegacy] = useState(LEGACY);
  const actions = {};

  let value = {
    legacy,
    setLegacy,
  };

  return (
    <LegacyContext.Provider value={value}>{children}</LegacyContext.Provider>
  );
};

const useLegacy = () => {
  const context = useContext(LegacyContext);
  if (context === undefined) {
    throw new Error("useLegacy must be used within a AuthProvider");
  }
  return context;
};

export { LegacyProvider, useLegacy };
