import { TextField } from "@mui/material";
import Sidebar from "components/Sidebar/Sidebar";
import { Spinner } from "components/Spinner";
import { useLayout } from "context/layout/layout";
import { useStore } from "context/store/store";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { defaultProduct } from "utils/state";

export const ProductDetail = () => {
  return (
    <>
      <Sidebar />
      <Wrapper>
        <AddProductSettings />
      </Wrapper>
    </>
  );
};

const Wrapper = ({ children }) => {
  return (
    <div className="relative h-full" style={{ marginLeft: 200 }}>
      <div className="relative h-full" style={{ paddingBottom: 95 }}></div>
      <div className=" mx-auto w-full -m-24" style={{ height: "100vh" }}>
        <div className="flex flex-wrap h-full">
          <div className="w-full h-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default function AddProductSettings() {
  const navigate = useNavigate();
  const location = useLocation();
  const layout = useLayout();
  const store = useStore();

  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(defaultProduct);
  const [options, setOptions] = useState({
    name: "",
    values: [
      {
        idx: 0,
        name: "",
      },
    ],
  });

  useEffect(() => {
    let detail = location.state.detail;
    if (!detail) {
      return navigate(-1);
    }

    if (detail) {
      setProduct(detail);
      console.log({ detail });
      if (detail.options.length > 0) {
        let optionvalues = detail.options[0].values.map((value, idx) => ({
          name: value,
          idx,
        }));
        setOptions({ name: detail.options[0].name, values: optionvalues });
      }
      console.log({ detail });
    }
    // eslint-disable-next-line
  }, []);

  function handleOptions({ target: { value } }) {
    setOptions({
      ...options,
      name: value,
    });
  }

  function handleOption(e, idx) {
    let temp = { ...options };
    temp.values[idx] = {
      idx: idx,
      name: e.target.value,
    };
    setOptions(temp);
  }

  function addoption() {
    setOptions({
      ...options,
      values: [
        ...options.values,
        {
          idx: options.values.length,
          name: "",
        },
      ],
    });
  }

  function deleteoption(idx) {
    let temp = options;
    let edited = temp.values.filter((row) => row.idx !== idx);
    setOptions({
      ...options,
      values: edited,
    });
  }

  const handleSubmit = () => {
    if (Object.values(product).some((v) => v === "")) {
      return layout.errorAlert({
        message: "Todos los campos son obligatorios",
      });
    }

    let nOptions;
    let nProduct = product;

    if (options.name) {
      nOptions = {
        name: options.name,
        values: options.values.map((v) => v.name),
      };

      nProduct = {
        ...product,
        options: nOptions,
      };
    }

    console.log({ nProduct });

    setLoading(true);
    store
      .updateProduct({ payload: nProduct, id: product._id })
      .then((res) => {
        console.log({ res });
        setLoading(false);
        navigate(-1);
        layout.successAlert({
          message: "Producto actualizado con exito",
        });
      })
      .catch((err) => {
        setLoading(false);
        return layout.errorAlert({
          message: "Error al actualizar el producto",
        });
      });
  };

  useEffect(() => {
    console.log({ product });
    console.log({ options });
  }, [product, options]);

  return (
    <>
      {loading && <Spinner />}

      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              EDITAR PRODUCTO
            </h6>
          </div>
        </div>

        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <div className="flex flex-wrap pt-6">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <TextField
                    onChange={({ target: { value } }) =>
                      setProduct({ ...product, name: value })
                    }
                    value={product.name}
                    label="Nombre"
                    className=" placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <TextField
                    value={product.description}
                    label={"Descripcion"}
                    onChange={({ target: { value } }) =>
                      setProduct({ ...product, description: value })
                    }
                    type="text"
                    className="  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3 mt-3">
                  <TextField
                    value={product.category}
                    label="Categoria"
                    type="text"
                    onChange={({ target: { value } }) =>
                      setProduct({ ...product, category: value })
                    }
                    className=" placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3 mt-3">
                  <TextField
                    value={product.code}
                    label="Código"
                    onChange={({ target: { value } }) =>
                      setProduct({ ...product, code: value })
                    }
                    type="text"
                    className=" placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3 mt-3">
                  <TextField
                    value={product.limit_stock}
                    label="Stock límite"
                    onChange={({ target: { value } }) =>
                      setProduct({ ...product, limit_stock: value })
                    }
                    type="text"
                    className=" placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3 mt-3">
                  <TextField
                    value={product.inventory}
                    label="Inventario"
                    onChange={({ target: { value } }) =>
                      setProduct({ ...product, inventory: value })
                    }
                    type="text"
                    className=" placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />
          </form>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <div className="flex flex-wrap pt-0">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <TextField
                    label={"Precio retail"}
                    value={product.prices.retail}
                    onChange={({ target: { value } }) =>
                      setProduct({
                        ...product,
                        prices: {
                          ...product.prices,
                          retail: Number(value),
                        },
                      })
                    }
                    type="text"
                    className="  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <TextField
                    label="Precio mayorista"
                    value={product.prices.wholesale}
                    onChange={({ target: { value } }) =>
                      setProduct({
                        ...product,
                        prices: {
                          ...product.prices,
                          wholesale: Number(value),
                        },
                      })
                    }
                    type="text"
                    className=" placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3 mt-3">
                  <TextField
                    value={product.prices.special}
                    label={"Precio especial"}
                    onChange={({ target: { value } }) =>
                      setProduct({
                        ...product,
                        prices: {
                          ...product.prices,
                          special: Number(value),
                        },
                      })
                    }
                    type="text"
                    className="  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3 mt-3">
                  <TextField
                    value={product.prices.staff}
                    label="Precio staff"
                    onChange={({ target: { value } }) =>
                      setProduct({
                        ...product,
                        prices: {
                          ...product.prices,
                          staff: Number(value),
                        },
                      })
                    }
                    type="text"
                    className="  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />
          </form>
        </div>
        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <div className="flex flex-wrap pt-6">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <TextField
                    value={options.name}
                    label="Variedades - Nombre de variedad"
                    onChange={handleOptions}
                    type="text"
                    className="  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              {options.values.map((option, idx) => {
                return (
                  <div className="w-full lg:w-12/12 px-4" key={idx}>
                    <div
                      className="relative w-full mb-3"
                      style={{ display: "flex" }}
                    >
                      <div
                        className="flex items-center"
                        style={{
                          width: 100,
                          gap: 20,
                          padding: "0 15px",
                        }}
                      >
                        <i
                          onClick={addoption}
                          class="fas fa-plus-square"
                          style={{
                            fontSize: 20,
                            color: "rgba(2, 132, 199)",
                            cursor: "pointer",
                          }}
                        ></i>
                        {idx !== 0 && (
                          <i
                            onClick={() => deleteoption(idx)}
                            class="fas fa-trash"
                            style={{
                              fontSize: 19,
                              color: "#dc2626",
                              cursor: "pointer",
                            }}
                          ></i>
                        )}
                      </div>
                      <TextField
                        value={option.name}
                        type="text"
                        label="Ej: Frutilla"
                        onChange={(e) => handleOption(e, option.idx)}
                        className=" placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <hr className="mt-6 border-b-1 border-blueGray-300" />
            <div
              className="my-6 w-full"
              style={{ transform: "translateX(23px)" }}
            >
              <button
                onClick={handleSubmit}
                style={{ height: 50, width: "46%" }}
                className=" bg-lightBlue-500  text-white active:bg-lightBlue-600 font-bold uppercase text-xs rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
                Publicar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
