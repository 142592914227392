export const months = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

export const validateEmail = (email) => {
  var re =
    // eslint-disable-next-line
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export function date(str) {
  let options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "UTC",
  };

  let date = new Date(str);
  let formatter = new Intl.DateTimeFormat("es-ES", options);

  return formatter.format(date);
}

export function formatCLP(num) {
  const integerPart = Math.floor(num);
  const integerString = integerPart
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return "$" + integerString + " CLP";
}

export function formatRut(rut) {
  rut = rut.toString().replace(/\D/g, ""); // Remove non-numeric characters

  if (rut.length < 2) {
    return rut; // Return the original input if it's too short
  }

  const dv = rut.slice(-1); // Get the last digit (verifying digit)
  const number = rut.slice(0, -1); // Get the number part without the verifying digit

  return `${number}-${dv}`;
}
