import { Button, TextField } from "@mui/material";
import axios from "axios";
import Sidebar from "components/Sidebar/Sidebar";
import { useLayout } from "context/layout/layout";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import config from "services/config";
import logo from "../../assets/LL.png";

export const FreepassView = () => {
  const params = useParams();
  const layout = useLayout();

  const [freepass, setFreepass] = useState({
    memberName: "",
    memberId: "",
    userId: "",
    email: "",
    available: 0,
  });

  useEffect(() => {
    axios
      .post(
        `${config.url}/members/find`,
        { _id: params.memberId },
        { headers: { "Content-Type": "application/json" } }
      )
      .then(({ data }) => {
        setFreepass({
          ...freepass,
          memberId: params.memberId,
          memberName: data.name,
          available: data.freePass.available,
        });
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  function handleEmail({ target: { value } }) {
    setFreepass({
      ...freepass,
      email: value,
    });
  }
  function handleUserId({ target: { value } }) {
    setFreepass({
      ...freepass,
      userId: value,
    });
  }

  function handleFreepass() {
    if (freepass.available < 1) {
      layout.errorAlert({ message: "No tienes freepass disponibles" });

      return;
    }

    axios
      .post(`${config.url}/leads/new`, {
        memberId: params.memberId,
        userId: freepass.userId,
        email: freepass.email,
      })
      .then(() => {
        layout.successAlert({ message: "Lead guardado con exito" });
      })
      .catch(() => {
        layout.errorAlert({ message: "Error en el servidor" });
      });

    axios
      .get(`${config.url}/members/freepass/subtract/${params.memberId}`)
      .then(() => {
        layout.successAlert({ message: "Freepass descontado exitosamente" });
      })
      .catch(() => {
        layout.errorAlert({ message: "Error en el servidor" });
      });
  }

  return (
    <div className="flex w-full justify-center">
      <img src={logo} style={{ width: 200, position: "absolute", top: 40 }} />
      <div
        className="flex flex-col justify-center items-center "
        style={{ minHeight: "100vh" }}
      >
        <p className="text-white font-bold text-xl py-2">
          {freepass.memberName}
        </p>
        <p className="text-white font-light pb-5">
          Tienes {freepass.available} freepass disponibles{" "}
        </p>

        <div className="relative w-full mb-2" style={{ maxWidth: 300 }}>
          <label style={{ fontSize: 12, color: "#fff" }}>
            Ingresa rut del invitado
          </label>
          <TextField
            value={freepass.userId}
            onChange={handleUserId}
            sx={{
              padding: 0,
              marginRight: 2,
              border: "none",
              background: "#cbd5e1",
            }}
            variant="outlined"
            type="text"
            className="rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>
        <div className="relative w-full mb-5" style={{ maxWidth: 300 }}>
          <label style={{ fontSize: 12, color: "#fff" }}>
            Ingresa el correo del invitado
          </label>
          <TextField
            value={freepass.email}
            onChange={handleEmail}
            sx={{
              padding: 0,
              marginRight: 2,
              border: "none",
              background: "#cbd5e1",
            }}
            variant="outlined"
            type="text"
            className="rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
          />
        </div>

        <Button onClick={handleFreepass} className="mt-2" variant="contained">
          Confirmar
        </Button>
      </div>
    </div>
  );
};
