import { createContext, useState, useContext, useEffect } from "react";
import { AuthService } from "services/auth";

const AuthContext = createContext();

const SUPERADMIN = "superadmin";

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAdmin, setAdmin] = useState(false);
  const [verified, setVerified] = useState(false);

  const actions = {};

  useEffect(() => {
    if (user && user.role && user.role.toLowerCase().includes(SUPERADMIN)) {
      setAdmin(true);
    }
  }, [user]);

  actions.verifyAuth = () => {
    const user = JSON.parse(localStorage.getItem("userdata:admin"));
    if (user) {
      setUser(user);
      setVerified(true);
    } else {
      setUser(null);
      setVerified(true);
    }
  };

  actions.requestAuthentication = async function ({ credentials }) {
    try {
      let user = await AuthService.authenticate({ credentials });
      actions.authenticate(user);
      return user;
    } catch (err) {
      throw new Error(err);
    }
  };

  actions.requestMemberAuthentication = async function ({ credentials }) {
    let member = await AuthService.authenticateMember({ credentials });
    actions.authenticate(member);
    return member;
  };

  actions.authenticate = (user) => {
    localStorage.setItem("userdata:admin", JSON.stringify(user));
    setUser(user);
    setVerified(true);
  };

  actions.revokeAuth = ({ isMember = null }) => {
    localStorage.removeItem("userdata:admin");
    setUser(null);
    setVerified(false);
    if (isMember) {
      return window.location.replace("/alumnos");
    }
    window.location.replace("/");
  };

  let value = {
    user,
    verified,
    isAdmin,
    revokeAuth: actions.revokeAuth,
    requestAuthentication: actions.requestAuthentication,
    requestMemberAuthentication: actions.requestMemberAuthentication,
    verifyAuthentication: actions.verifyAuth,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export { AuthProvider, useAuth };
