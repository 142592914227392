import React, { useEffect, useState } from "react";
import Sidebar from "components/Sidebar/Sidebar.js";
import CardTable from "components/Cards/CardTable";
import axios from "axios";
import { TableDataItem } from "components/Cards/CardTable";
/* import { useStore } from "../../context/store/store";
 */ import { Spinner } from "components/Spinner";
import config from "services/config";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

const productsHeaders = ["Nombre", "Ubicacion", "Ver"];

export function WarehouseList({ color = null }) {
  const navigate = useNavigate();
  const [warehouses, setWarehouses] = useState(null);
  const [loading /* setLoading */] = useState(false);

  useEffect(() => {
    axios
      .get(`${config.url}/warehouse/showall`)
      .then((res) => {
        setWarehouses(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  /*   const handleDelete = async (warehouseId) => {
    console.log(warehouseId);
    setLoading(true);
    try {
      await store.deleteWarehouse(warehouseId);
      let newproducts = warehouses.filter((p) => p._id !== warehouseId);
      setLoading(false);
      setWarehouses(newproducts);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }; */

  function navigateToDetails(detail) {
    navigate(`/warehouse/view/${detail._id}`, {
      state: { detail: detail },
    });
  }

  return (
    <>
      {loading && <Spinner />}
      <Sidebar />
      <div className="relative" style={{ marginLeft: 200 }}>
        <div className="relative " style={{ paddingBottom: 95 }}></div>
        <div className=" mx-auto w-full -m-24">
          <div className="flex flex-wrap">
            <div className="w-full  h-full">
              <CardTable
                title={"LISTA DE BODEGAS"}
                headers={productsHeaders}
                add="Agregar bodega"
                routeadd="/warehouse/add"
                color={"dark"}
              >
                {warehouses &&
                  warehouses.length > 0 &&
                  warehouses.map((w, idx) => {
                    return (
                      <tr>
                        <TableDataItem
                          sx={{
                            fontWeight: 600,
                            fontSize: 15,
                            textAlign: "left",
                            transform: "translateX(25px)",
                          }}
                        >
                          {w.name}
                        </TableDataItem>
                        <TableDataItem>
                          {w.campus ? w.campus.title : w.location}
                        </TableDataItem>
                        <TableDataItem clickable>
                          <IconButton onClick={() => navigateToDetails(w)}>
                            <VisibilityIcon sx={{ color: "#ccc" }} />
                          </IconButton>
                        </TableDataItem>
                      </tr>
                    );
                  })}
              </CardTable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
