import React, { useEffect, useState } from "react";
import Sidebar from "components/Sidebar/Sidebar.js";
import CardTable from "components/Cards/CardTable";
import axios from "axios";
import { TableDataItem } from "components/Cards/CardTable";
/* import { useStore } from "../../context/store/store";
 */ import { Spinner } from "components/Spinner";
/* import { useStore } from "context/store/store";
 */ /* import VisibilityIcon from "@mui/icons-material/Visibility";
 */ import config from "services/config";
import { IconButton, Tooltip } from "@mui/material";
import BlockIcon from "@mui/icons-material/Block";
import { useAuth } from "context/auth/auth";
import VerifiedIcon from "@mui/icons-material/Verified";
import { formatCLP } from "utils";

const productsHeaders = [
  "Nombre",
  "ID",
  /*   "Fecha de nacimiento", */
  /*   "Telefono", */
  "Precio",
  "Status",
];
const ACTIONS = ["Acciones"];

export function MembershipList({ color = null }) {
  const auth = useAuth();
  const [memberships, setMemberships] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchMemberships();
  }, []);

  const fetchMemberships = async () => {
    axios
      .get(`${config.url}/membership/list`)
      .then((res) => {
        console.log(res);
        setMemberships(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const changeStatus = (M_ID) => {
    setLoading(true);
    axios
      .get(`${config.url}/membership/change_status/${M_ID}`)
      .then(async (res) => {
        console.log(res);
        setMemberships(null);
        await fetchMemberships();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      {loading && <Spinner />}
      <Sidebar />
      <div className="relative" style={{ marginLeft: 200 }}>
        <div className="relative " style={{ paddingBottom: 95 }}></div>
        <div className=" mx-auto w-full -m-24">
          <div className="flex flex-wrap">
            <div className="w-full  h-full">
              <CardTable
                title={"PLANES TEMPLE GYM"}
                headers={
                  auth.isAdmin
                    ? [...productsHeaders, ...ACTIONS]
                    : productsHeaders
                }
                add={auth.isAdmin ? "Agregar Plan" : null}
                routeadd={auth.isAdmin ? "/membership/add" : null}
                color={"dark"}
              >
                {memberships &&
                  memberships.length > 0 &&
                  memberships.map((m, idx) => {
                    return (
                      <tr>
                        <TableDataItem
                          sx={{
                            fontWeight: 600,
                            fontSize: 15,
                            textAlign: "left",
                            transform: "translateX(25px)",
                          }}
                        >
                          {m.title}
                        </TableDataItem>
                        <TableDataItem>{m._id}</TableDataItem>
                        <TableDataItem>{formatCLP(m.price)}</TableDataItem>

                        <TableDataItem>
                          <p
                            style={{
                              backgroundColor:
                                m.enabled === "true" ? "#15803d" : "red",
                              color: "#fff",
                              borderRadius: 5,
                              padding: "2px 5px",
                            }}
                          >
                            {m.enabled === "true"
                              ? "Habilitado"
                              : "Deshabilitado"}
                          </p>
                        </TableDataItem>

                        {auth.isAdmin && (
                          <TableDataItem>
                            <Tooltip title="Deshabilitar">
                              <IconButton onClick={() => changeStatus(m._id)}>
                                {m.enabled === "true" ? (
                                  <VerifiedIcon sx={{ color: "#ccc" }} mui />
                                ) : (
                                  <BlockIcon sx={{ color: "#ccc" }} mui />
                                )}
                              </IconButton>
                            </Tooltip>
                          </TableDataItem>
                        )}
                      </tr>
                    );
                  })}
              </CardTable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
