import axios from "axios";
import config from "services/config";

export class WarehouseService {
  static async create(payload) {
    console.log({ payload });
    try {
      const { data } = await axios.post(
        `${config.url}/warehouse/create/`,
        payload,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async delete(warehouseId) {
    const { data } = await axios.delete(
      `${config.url}/warehouse/delete/${warehouseId}`
    );
    return data;
  }

  static async update({ payload, id }) {
    try {
      const { data } = await axios.post(
        `${config.url}/warehouse/update/${id}`,
        payload,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );

      return data;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

  static async getWarehouseById(warehouseId) {
    try {
      const { data } = await axios.get(
        `${config.url}/warehouse/find/${warehouseId}`
      );
      return data;
    } catch (err) {
      throw new Error(err);
    }
  }

  static async addInventory(payload) {
    console.log({ PAYLOAD_: payload });
    const { data } = await axios.post(
      `${config.url}/warehouse/inventory/entry`,
      payload,
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    console.log({ data });
    return data;
  }

  static async removeInventory(payload) {
    console.log({ PAYLOAD_: payload });
    const { data } = await axios.post(
      `${config.url}/warehouse/inventory/remove`,
      payload,
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    console.log({ data });
    return data;
  }

  static async getInventory(warehouseId) {
    const { data } = await axios.get(
      `${config.url}/warehouse/inventory/${warehouseId}`
    );
    console.log({ data });
    return data;
  }
}
