import { TextField } from "@mui/material";
import axios from "axios";
import Sidebar from "components/Sidebar/Sidebar";
import { Spinner } from "components/Spinner";
import { useLayout } from "context/layout/layout";
import { useStore } from "context/store/store";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { defaultRetailClient } from "utils/state";
import config from "../../../services/config";

export const RetailDetail = () => {
  return (
    <>
      <Sidebar />
      <Wrapper>
        <AddUserSettings />
      </Wrapper>
    </>
  );
};

const Wrapper = ({ children }) => {
  return (
    <div className="relative h-full" style={{ marginLeft: 200 }}>
      <div className="relative h-full" style={{ paddingBottom: 95 }}></div>
      <div
        className=" mx-auto w-full -m-24"
        style={{ height: "100vh", backgroundColor: "rgb(17, 24, 39)" }}
      >
        <div
          className="flex flex-wrap h-full"
          style={{ backgroundColor: "rgb(17, 24, 39)" }}
        >
          <div
            className="w-full h-full"
            style={{ backgroundColor: "rgb(17, 24, 39)" }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

function AddUserSettings() {
  const params = useParams();
  const layout = useLayout();
  const store = useStore();
  const [loading, setLoading] = React.useState(false);
  const [user, setUser] = React.useState(defaultRetailClient);

  function getDetails() {
    axios
      .get(`${config.url}/costumer/find/${params._id}`)
      .then(({ data }) => {
        setUser(data);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    //obtener el detalle del cliente con el id
    getDetails();

    /*     console.log(user.subscription.startDate);
    fetch(`${config.url}/members/find`, {
      method: "POST",
      body: JSON.stringify({ _id: params._id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        res.json().then((response) => {
          let start = new Date(response.subscription.startDate);
          let startDate = start.toISOString().slice(0, 10);
          setUser({
            birthday: response.birthday,
            email: response.email,
            name: response.name,
            phone: response.phone,
            id_number: response.id_number,
            _id: response._id,
            campus: response.campus,
            subscription: {
              type: response.subscription.type,
              startDate: startDate,
              endDate: response.subscription.endDate,
            },
          });
        });
      })
      .catch((err) => console.log({ err }));
    console.log(config); */
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    /*     let isValidEmail = validateEmail(user.email);

    if (!isValidEmail) {
      layout.errorAlert({ message: "Ingresar un correo valido" });
      return;
    } */

    setLoading(true);
    store
      .editCustomer(user)
      .then((res) => {
        setLoading(false);
        layout.successAlert({ message: "Usuario creado con exito" });
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        layout.errorAlert({ message: "Error al crear el alumno" });
        return;
      });
  };

  return (
    <>
      {loading && <Spinner />}

      <div
        className="relative h-full flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
        style={{ backgroundColor: "rgb(17, 24, 39)" }}
      >
        <div
          className="rounded-t  mb-0 py-6 "
          style={{ backgroundColor: "rgb(17, 24, 39)", paddingLeft: 57 }}
        >
          <div className="text-center flex justify-between ">
            <h6 className="text-white  text-xl font-bold">DETALLE CLIENTE</h6>
          </div>
        </div>

        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <div className="flex flex-wrap pt-6">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-8">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el nombre y apellidos del alumno
                  </label>

                  <TextField
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow  w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={user.name}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el correo
                  </label>

                  <TextField
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={user.email}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-8">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el rut
                  </label>
                  <TextField
                    className="border-0   placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={user.id_number}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        id_number: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-8">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el numero de teléfono celular
                  </label>
                  <TextField
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={user.phone}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        phone: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-8">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa dirección
                  </label>
                  <TextField
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={user.address}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        address: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="relative w-full mb-8">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa otra dirección
                  </label>
                  <TextField
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={user.address_2}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        address_2: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
            </div>

            <button
              onClick={handleSubmit}
              style={{ height: 45, width: "48%", marginLeft: 15 }}
              className=" bg-lightBlue-800 mt-8 text-white active:bg-lightBlue-600 font-bold uppercase text-xs  py-2 rounded shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150"
              type="button"
            >
              Publicar
            </button>
          </form>
        </div>
      </div>
    </>
  );

  /*   return (
    <>
      {loading && <Spinner />}

      <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <div className="rounded-t bg-white mb-0 px-6 py-6">
          <div className="text-center flex justify-between">
            <h6 className="text-blueGray-700 text-xl font-bold">
              Agregar Alumno
            </h6>
          </div>
        </div>

        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <div className="flex flex-wrap pt-6">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-8">
                  <TextField
                    className="border-0  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    sx={{ width: "100%" }}
                    label="Nombre"
                    value={user.name}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <TextField
                    className="border-0  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    label="Correo"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    value={user.email}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-8">
                  <label style={{ fontSize: 12 }}>Fecha de nacimiento</label>
                  <TextField
                    className="border-0  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Fecha de nacimiento"
                    type={"date"}
                    sx={{ width: "100%" }}
                    value={user.birthday}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        birthday: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-8">
                  <TextField
                    className="border-0   placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    label="Rut"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    value={user.id_number}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        id_number: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-8">
                  <TextField
                    className="border-0  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    label="Telefono"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    value={user.phone}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        phone: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-8">
                  <Autocomplete
                    className="border-0  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="combo-box-demo"
                    placeholder="Plan"
                    options={[
                      { label: "Mensual" },
                      { label: "Trimestral" },
                      { label: "Semestral" },
                      { label: "Anual" },
                    ]}
                    value={user.subscription.type}
                    onChange={(e, value) =>
                      setUser({
                        ...user,
                        subscription: {
                          type: value,
                        },
                      })
                    }
                    onInputChange={(e, value) => {
                      setUser({
                        ...user,
                        subscription: {
                          type: value,
                        },
                      });
                    }}
                    inputValue={user.subscription.type}
                    sx={{ padding: 0, border: "unset" }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-8">
                  <label style={{ fontSize: 12 }}>Fecha de inscripcion</label>

                  <TextField
                    type="date"
                    className="border-0  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    value={user.subscription.startDate}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        subscription: {
                          ...user.subscription,
                          startDate: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-8">
                  <label style={{ fontSize: 12 }}>Sede</label>

                  <Autocomplete
                    className="border-0  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="combo-box-demo"
                    placeholder="Plan"
                    options={[
                      { label: "Temple Gym Malloco" },
                      { label: "Temple Gym Talagante" },
                    ]}
                    value={user.campus}
                    onChange={(e, value) =>
                      setUser({
                        ...user,
                        campus: value,
                      })
                    }
                    onInputChange={(e, value) => {
                      setUser({
                        ...user,
                        campus: value,
                      });
                    }}
                    inputValue={user.campus}
                    sx={{ padding: 0, border: "unset" }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />
            <h6 className="py-4 font-semibold text-lg">Renovar Subscripcion</h6>
            <div className="flex flex-wrap items-center mr-auto">
              <div className="lg:w-2/12">
                <Autorenew />
              </div>
              <div className=" px-4" style={{ width: "49%" }}>
                <div className="relative w-full m-0 mr-16">
                  <Autocomplete
                    className="border-0  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="combo-box-demo"
                    placeholder="Plan"
                    options={[
                      { label: "Mensual" },
                      { label: "Trimestral" },
                      { label: "Semestral" },
                      { label: "Anual" },
                    ]}
                    value={subscriptionType}
                    onChange={(e, value) => setSubcriptionType(value.label)}
                    onInputChange={(e, value) => {
                      setSubcriptionType(value);
                    }}
                    inputValue={subscriptionType}
                    sx={{ padding: 0, border: "unset" }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </div>
              <div className=" px-4">
                <div className="relative ml-20">
                  <Button
                    onClick={handleRenew}
                    variant="contained"
                    sx={{
                      backgroundColor: "#facc15",
                      color: "#fff",
                      fontWeight: 800,
                      width: 200,
                    }}
                  >
                    Renovar
                  </Button>
                </div>
              </div>
            </div>

            <button
              onClick={handleSubmit}
              style={{ height: 35, width: "100%" }}
              className=" bg-lightBlue-500 mt-8 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Publicar
            </button>
          </form>
        </div>
      </div>
    </>
  ); */
}
