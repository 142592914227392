let env = "prod";

let config = {
  prod: {
    url: "https://api.lobarede.cl/api",
  },
  qa: { url: "https://qa.lobarede.cl" },
  dev: {
    url: "http://localhost:9005/api",
  },
};

export default config[env];
