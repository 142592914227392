import Dashboard from "views/admin/Dashboard";
import Maps from "views/admin/Maps";
import { AdminLayout } from "layouts/Admin";
import { AuthLayout } from "layouts/Auth";
import Login from "views/auth/Login";
import MemberLogin from "views/members/login/Login";
import Profile from "views/Profile";
import { ProductsList } from "views/products";
import { AddProduct } from "views/products/new-product";
import { UsersList } from "views/users";
import { AddUser } from "views/users/new-user";
import { WarehouseList } from "views/warehouses";
import { AddWarehouse } from "views/warehouses/new-warehouse";
import { SalesList } from "views/sales";
import { AddSale } from "views/sales/new-sale";
import { SaleDetail } from "views/sales/detail";
import { ProductDetail } from "views/products/detail";
import { MembersList } from "views/members";
import { AddMember } from "views/members/new-member";
import { MemberDetails } from "views/members/detail";
import { MessagesList } from "views/messages";
import { AddMessage } from "views/messages/new-message";
import { MessageDetail } from "views/messages/detail";
import { Campus } from "views/campus";
import { WarehouseDetail } from "views/warehouses/detail";
import { Daily } from "views/daily";
import { CostumerList } from "views/costumer";
import { RetailDetail } from "views/costumer/detail";
import { AddCostumer } from "views/costumer/new-costumer";
import { AddWithdraw } from "views/sales/new-withdraw";
import { AddExpense } from "views/sales/new-expense";
import { DailyRetail } from "views/daily-retail";
import { DailyHistory } from "views/daily-history";
import { DailyDetail } from "views/daily-history/daily-detail";
import { AddEnrollment } from "views/sales/new-enrollment";
import { NewInscription } from "views/sales/new-inscription";
import { MembershipList } from "views/memberships";
import { AddMembership } from "views/memberships/new-member";
import { DailyB2B } from "views/daily-b2b";
import { AddComment } from "views/daily/new-comment";
import { VerifyMember } from "views/verify-member";
import { TrainingList } from "views/training-list";
import { MarketingList } from "views/makreting-list";
import { MemberDetailsV2 } from "views/members/detail-v2";
import { NewProgress } from "views/progress/new-progress";
import { ProgressList } from "views/progress";
import { FreepassView } from "views/freepass";

export const routes = [
  {
    path: "/admin/dashboard",
    isPrivate: true,
    component: () => (
      <AdminLayout>
        <Dashboard />,
      </AdminLayout>
    ),
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/admin/maps",
    isPrivate: true,
    component: Maps,
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/admin/warehouses",
    isPrivate: true,
    component: () => <WarehouseList />,
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/admin/training",
    isPrivate: true,
    component: () => <TrainingList />,
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/freepass/view/:memberId",
    isPrivate: true,
    component: FreepassView,
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/admin/marketing",
    isPrivate: true,
    component: () => <MarketingList />,
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/admin/sales",
    isPrivate: true,
    component: () => <SalesList />,
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/admin/products",
    isPrivate: true,
    component: ProductsList,
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/campus",
    isPrivate: false,
    component: Campus,
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/admin/users",
    isPrivate: true,
    component: UsersList,
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/admin/progress",
    isPrivate: true,
    component: NewProgress,
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/admin/messages",
    isPrivate: true,
    component: MessagesList,
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/admin/daily/history",
    isPrivate: true,
    component: DailyHistory,
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/admin/members",
    isPrivate: true,
    component: MembersList,
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/admin/memberships/settings",
    isPrivate: true,
    component: MembershipList,
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/admin/costumers",
    isPrivate: true,
    component: CostumerList,
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/costumers/add",
    isPrivate: true,
    component: AddCostumer,
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/profile",
    isPrivate: true,
    component: Profile,
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/member/:member_id",
    isPrivate: true,
    component: Campus,
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/",
    isPrivate: false,
    component: () => (
      <AuthLayout>
        <Login />
      </AuthLayout>
    ),
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/alumnos",
    isPrivate: false,
    component: () => (
      <AuthLayout>
        <MemberLogin />
      </AuthLayout>
    ),
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/products/add",
    isPrivate: true,
    component: AddProduct,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/warehouse/add",
    isPrivate: true,
    component: AddWarehouse,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/messages/add",
    isPrivate: true,
    component: AddMessage,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/sales/add",
    isPrivate: true,
    component: AddSale,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/enrollment/add",
    isPrivate: true,
    component: AddEnrollment,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/enrollment/new",
    isPrivate: true,
    component: NewInscription,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/withdraw/add",
    isPrivate: true,
    component: AddWithdraw,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/comment/add",
    isPrivate: true,
    component: AddComment,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/verify/member",
    isPrivate: true,
    component: VerifyMember,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/expense/add",
    isPrivate: true,
    component: AddExpense,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/sales/view/:_id",
    isPrivate: true,
    component: SaleDetail,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/retail/view/:_id",
    isPrivate: true,
    component: RetailDetail,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/messages/view/:_id",
    isPrivate: true,
    component: MessageDetail,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/product/view/:_id",
    isPrivate: true,
    component: ProductDetail,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/warehouse/view/:_id",
    isPrivate: true,
    component: WarehouseDetail,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/daily/gym",
    isPrivate: true,
    component: Daily,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/daily/b2b",
    isPrivate: true,
    component: DailyB2B,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/daily/retail",
    isPrivate: true,
    component: DailyRetail,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/daily/view/:id",
    isPrivate: true,
    component: DailyDetail,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/users/add",
    isPrivate: true,
    component: AddUser,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/members/add",
    isPrivate: true,
    component: AddMember,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/membership/add",
    isPrivate: true,
    component: AddMembership,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/members/view/:_id",
    isPrivate: true,
    component: MemberDetailsV2,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/progress/list",
    isPrivate: true,
    component: ProgressList,
    props: {
      title: "Add new Product",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  /*   {
    path: "/landing",
    isPrivate: true,
    component: Landing,
    props: {
      title: "List of Products",
      endpoint: "/api/products",
      userRole: "",
    },
  }, */
  /*   
  {
    path: "/products/:id",
    isPrivate: true,
    component: ProductDetails,
    props: {
      title: "Product Details",
      endpoint: "/api/products",
      userRole: "",
    },
  },
  {
    path: "/sales",
    isPrivate: true,
    component: SalesList,
    props: {
      title: "List of Sales",
      endpoint: "/api/sales",
      userRole: "",
    },
  },
  {
    path: "/sales/new",
    isPrivate: true,
    component: SaleForm,
    props: {
      title: "New Sale",
      endpoint: "/api/sales/create",
      userRole: "",
    },
  },
  {
    path: "/sales/:id",
    isPrivate: true,
    component: SaleDetails,
    props: {
      title: "SalesDetails",
      endpoint: "/api/sales/create",
    },
  }, */
];
