import React from "react";

import Navbar from "components/Navbars/AuthNavbar.js";
import { useAuth } from "context/auth/auth";

export default function Profile() {
  const auth = useAuth();

  return (
    <>
      <Navbar transparent />
      <main className="profile-page">
        <section className="relative block h-500-px">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80')",
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
            style={{ transform: "translateZ(0)" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-blueGray-200 fill-current"
                points="2560 0 2560 100 0 100"
              ></polygon>
            </svg>
          </div>
        </section>
        <section
          className="relative py-16 bg-blueGray-200 "
          style={{ height: "40vh" }}
        >
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6 py-6">
                <div className="text-center">
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-blueGray-700">
                    {auth.user.name}
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-blueGray-400 font-bold uppercase">
                    <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>{" "}
                    {auth.user.campus}
                  </div>
                  <div
                    className="mb-2 text-blueGray-600 mt-6"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <p
                      style={{
                        backgroundColor:
                          new Date(auth.user.subscription.endDate) > new Date()
                            ? "#15803d"
                            : "red",
                        color: "#fff",
                        borderRadius: 5,
                        padding: "5px 15px",
                        maxWidth: 200,
                      }}
                    >
                      {new Date(auth.user.subscription.endDate) > new Date()
                        ? "Plan Vigente"
                        : "Vencido"}
                    </p>
                  </div>
                  <div className="mb-2 text-blueGray-600 mt-5">
                    Fecha vencimiento:{" "}
                    {new Date(
                      auth.user.subscription.endDate
                    ).toLocaleDateString()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
