import { useAuth } from "context/auth/auth";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import PersonPinIcon from "@mui/icons-material/PersonPin";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import logo from "../../assets/logo_blanco.png";
import { useEffect, useState } from "react";
import { IconButton } from "@mui/material";
import InstagramIcon from "@mui/icons-material/Instagram";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import { Cube } from "components/Loaders/Cube";
import config from "services/config";
import LinkIcon from "@mui/icons-material/Link";

let iconStyles = {
  color: "#fff",
  fontSize: 32,
  margin: "0 10px",
};

export const Campus = () => {
  const [selectedView, setSelectedView] = useState(1);
  const auth = useAuth();

  useEffect(() => {
    document.querySelector("body").style.backgroundColor = "#0f172a";
  }, []);

  return (
    <BackgroundContainer>
      <Navbar auth={auth} setSelectedView={setSelectedView} />
      {selectedView === 0 && <MessagesApp />}
      {selectedView === 1 && <UserProfile auth={auth} />}
      <Footer />
    </BackgroundContainer>
  );
};

const MessagesApp = () => {
  const [loading, setLoading] = useState(true);
  const [messages, setMessages] = useState(null);
  const [messageSelected, setMessageSelected] = useState(null);

  useEffect(() => {
    if (!messages) {
      fetch(`${config.url}/messages/showall`, {
        method: "GET",
      }).then((res) => {
        res
          .json()
          .then(({ messages }) => {
            setLoading(false);
            setMessages(messages.reverse());
          })
          .catch((err) => {
            setLoading(false);
          });
      });
    }
  }, [messages]);

  return (
    <>
      {loading && <Cube />}
      <div
        style={{
          paddingTop: "70px",
          maxWidth: "100vw",
        }}
      >
        {messages &&
          !messageSelected &&
          messages.map((m) => {
            return (
              <div
                onClick={() => setMessageSelected(m)}
                style={{
                  maxWidth: "100vw",
                  borderBottom: "1px dotted #ccc",
                  display: "flex",
                  alignItems: "center",
                  padding: "0px 20px",
                  height: 80,
                  background: "#020617",
                }}
              >
                <FitnessCenterIcon
                  sx={{ color: "#ccc", fontSize: 20, marginRight: 2 }}
                />
                <p style={{ color: "#ccc", fontWeight: 500, fontSize: 14 }}>
                  {m.title}
                </p>
              </div>
            );
          })}

        {messageSelected && (
          <div
            style={{
              backgroundColor: "#020617",
              borderTop: "1px solid #ccc",
              padding: "30px",
            }}
          >
            <p
              style={{
                color: "#fff",
                fontSize: 22,
                fontWeight: 500,
                marginBottom: 20,
                textAlign: "center",
              }}
            >
              {messageSelected.title}
            </p>
            <p style={{ color: "#fff", textAlign: "center", fontSize: 18 }}>
              {messageSelected.subtitle}
            </p>

            {messageSelected.url && (
              <div
                onClick={() => {
                  window.open(messageSelected.url);
                }}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <div
                  style={{
                    backgroundColor: "#64748b",
                    width: "fit-content",
                    display: "flex",
                    padding: "2px 12px",
                    borderRadius: 9,
                  }}
                >
                  <LinkIcon sx={{ color: "#fff" }} />
                  <p
                    style={{
                      marginLeft: 5,
                      marginBottom: 0,
                      padding: 0,
                      color: "#fff",
                    }}
                  >
                    Mas información
                  </p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

const BackgroundContainer = ({ children }) => {
  return (
    <div
      style={{
        height: "100%",
        width: "100vw",
        backgroundColor: "#0f172a",
        maxWidth: "100vw",
      }}
    >
      {children}
    </div>
  );
};

const UserProfile = ({ auth }) => {
  return (
    <>
      <div
        style={{
          width: "100%",
          height: 220,
          transform: "translateY(70px)",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            padding: 20,
            backgroundColor: "#020617",
            height: 220,
            marginTop: 0,
            borderTop: "1px solid #ccc",
            borderBottom: "1px solid #ccc",
          }}
        >
          <p
            style={{
              fontSize: 32,
              fontWeight: 500,
              width: "100%",
              textAlign: "center",
              color: "#fff",
            }}
          >
            {auth.user.name}
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 4,
              width: "100%",
              justifyContent: "center",
            }}
          >
            <LocationOnIcon sx={{ color: "#fff" }} />
            <p
              style={{
                fontSize: 20,
                fontWeight: 300,
                marginLeft: 2,
                color: "#fff",
              }}
            >
              {auth.user.campus}
            </p>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 4,
              width: "100%",
              justifyContent: "center",
            }}
          >
            <p
              style={{
                fontSize: 20,
                fontWeight: 300,
                marginLeft: 2,
                color: "#fff",
              }}
            >
              {auth.user.points} puntos Legacy
            </p>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginTop: 30,
            }}
          >
            <p
              style={{
                backgroundColor:
                  new Date(auth.user.subscription.endDate) > new Date()
                    ? "#15803d"
                    : "red",
                color: "#fff",
                borderRadius: 5,
                padding: "5px 20px",
                textAlign: "center",
                width: "60%",
                fontSize: 20,
              }}
            >
              {new Date(auth.user.subscription.endDate) > new Date()
                ? "Plan Vigente"
                : "Vencido"}
            </p>
          </div>
        </div>
      </div>

      {/*       <div style={{ marginTop: 70 }}>
        <img src={horarios} />
      </div> */}
    </>
  );
};

const Navbar = ({ auth, setSelectedView }) => {
  return (
    <nav
      style={{
        zIndex: 100,
        height: 70,
        position: "fixed",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        padding: "0 20px",
        width: "100vw",
        backgroundColor: "#020617",
        boxShadow:
          "0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)",
      }}
    >
      <img
        src={logo}
        width={110}
        style={{ position: "absolute", left: 25 }}
        alt="img"
      />
      <IconButton
        sx={{ margin: 0, padding: 0 }}
        onClick={() => setSelectedView(0)}
      >
        <div
          style={{
            width: 12,
            height: 12,
            position: "absolute",
            backgroundColor: "#dc2626",
            top: 0,
            right: 12,
            borderRadius: "50%",
            zIndex: 10000,
          }}
        ></div>
        <NotificationsActiveIcon sx={iconStyles} />
      </IconButton>
      <IconButton
        sx={{ margin: 0, padding: 0 }}
        onClick={() => setSelectedView(1)}
      >
        <PersonPinIcon sx={iconStyles} />
      </IconButton>
      <IconButton
        sx={{ margin: 0, padding: 0 }}
        onClick={() => auth.revokeAuth({ isMember: true })}
      >
        <ExitToAppIcon sx={iconStyles} />
      </IconButton>
    </nav>
  );
};

const Footer = () => {
  return (
    <>
      <footer
        style={{
          position: "absolute",
          bottom: 60,
          display: "flex",
          width: "100%",
          justifyContent: "center",
        }}
      >
        <IconButton
          sx={{ padding: 0 }}
          onClick={() =>
            window.open("https://www.instagram.com/templegym.oficial/")
          }
        >
          <InstagramIcon sx={iconStyles} />
        </IconButton>
        <IconButton
          sx={{ padding: 0 }}
          onClick={() => {
            window.open("https://lobaredenutrition.com/");
          }}
        >
          <ShoppingCartIcon sx={iconStyles} />
        </IconButton>
      </footer>
    </>
  );
};
