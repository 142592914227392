/*eslint-disable*/
import React, { useEffect, useRef, useState } from "react";
import "./index.css";
import { Link } from "react-router-dom";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import GroupIcon from "@mui/icons-material/Group";
import WarehouseIcon from "@mui/icons-material/Warehouse";

import { useAuth } from "context/auth/auth";
import MeetingRoomIcon from "@mui/icons-material/MeetingRoom";
import SettingsIcon from "@mui/icons-material/Settings";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PlaceIcon from "@mui/icons-material/Place";
import HomeIcon from "@mui/icons-material/Home";
import logo2 from "../../assets/logo_blanco.png";
import CampaignIcon from "@mui/icons-material/Campaign";
import logo from "../../assets/LL.png";
import {
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";

let textXs = { fontSize: 10 };

export default function Sidebar() {
  const auth = useAuth();
  const gymdropref = useRef();
  const b2bdropref = useRef();
  const [gymdrop, openGymDrop] = useState(true);
  const [b2bdrop, openB2BDrop] = useState(true);

  const [collapseShow, setCollapseShow] = React.useState("hidden");

  useEffect(() => {
    if (gymdrop) {
      gymdropref.current.style.display = "unset";
    }
    if (!gymdrop) {
      gymdropref.current.style.display = "none";
    }
  }, [gymdrop]);

  useEffect(() => {
    if (b2bdrop) {
      b2bdropref.current.style.display = "unset";
    }
    if (!b2bdrop) {
      b2bdropref.current.style.display = "none";
    }
  }, [b2bdrop]);

  return (
    <>
      <nav
        className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative  z-10 "
        style={{
          backgroundColor: "#111827",
          width: 210,
          boxShadow:
            "0 1px 2px 0 rgba(60,64,67,.3), 0 1px 3px 1px rgba(60,64,67,.15)",
        }}
      >
        <div className=" md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto ">
          <div
            className={
              "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded " +
              collapseShow
            }
          >
            <ul className="md:flex-col md:min-w-full flex flex-col list-none flex-1 pb-3">
              <li
                className="items-center px-4"
                style={{ borderBottom: "0.2px dotted #ccc" }}
              >
                <img
                  src={logo}
                  width={130}
                  height={130}
                  className=" mb-3 my-1"
                />
              </li>

              <div id="Location" className="mb-3">
                <li
                  className="px-1"
                  style={{
                    borderBottom: "0.2px dotted #ccc",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#cbd5e1",
                      fontSize: 18,
                      margin: "0px 5px",
                    }}
                  >
                    ⛩️🐉
                  </span>
                  <p className="text-white text-xs my-2">Casa Matriz</p>
                </li>
                <li
                  className="px-1"
                  style={{
                    borderBottom: "0.2px dotted #ccc",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      color: "#cbd5e1",
                      fontSize: 18,
                      margin: "0px 5px",
                    }}
                  >
                    ⚔️
                  </span>
                  <p className="text-white text-xs my-2">
                    Templario {auth.user && auth.user.name.split(" ")[0]}{" "}
                  </p>
                </li>
              </div>

              <div
                className="gym-navigation"
                style={{ marginRight: 5, marginLeft: 5, marginBottom: 8 }}
              >
                <li
                  className="items-center "
                  onClick={() => openGymDrop(!gymdrop)}
                >
                  <div
                    className="flex flex-row items-center px-3 py-2  gym-btn"
                    style={{
                      color: "#cbd5e1",
                      backgroundColor: "#082f49",
                      borderTopLeftRadius: 15,
                      borderTopRightRadius: 15,
                      borderBottomLeftRadius: !gymdrop ? 15 : 0,
                      borderBottomRightRadius: !gymdrop ? 15 : 0,
                    }}
                  >
                    <p
                      className="text-xs    font-light uppercase block"
                      style={textXs}
                    >
                      Gym
                    </p>
                    {gymdrop ? (
                      <KeyboardDoubleArrowDown
                        sx={{
                          color: "#cbd5e1",
                          marginLeft: "auto",
                          fontSize: 15,
                        }}
                      />
                    ) : (
                      <KeyboardDoubleArrowRight
                        sx={{
                          color: "#cbd5e1",
                          marginLeft: "auto",
                          fontSize: 15,
                        }}
                      />
                    )}
                  </div>
                </li>
                <div
                  ref={gymdropref}
                  style={{ display: "none", transition: "1s" }}
                >
                  <li className="items-center">
                    <Link to="/daily/gym">
                      <div
                        className="flex flex-row items-center px-3 py-2 gym-btn"
                        style={{
                          color: "#cbd5e1",
                          backgroundColor:
                            window.location.pathname === "/daily/gym"
                              ? "#075985"
                              : "#082f49",
                        }}
                      >
                        <AddBusinessIcon
                          sx={{
                            color: "#cbd5e1",
                            marginRight: 1,
                            fontSize: 15,
                          }}
                        />
                        <p
                          className="text-xs    font-light uppercase block"
                          style={textXs}
                        >
                          Turno
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li className="items-center">
                    <Link to="/admin/daily/history">
                      <div
                        className="flex flex-row items-center px-3 py-2 gym-btn"
                        style={{
                          color: "#cbd5e1",
                          backgroundColor:
                            window.location.pathname === "/admin/daily/history"
                              ? "#075985"
                              : "#082f49",
                        }}
                      >
                        <AddBusinessIcon
                          sx={{
                            color: "#cbd5e1",
                            marginRight: 1,
                            fontSize: 15,
                          }}
                        />
                        <p
                          className="text-xs    font-light uppercase block"
                          style={textXs}
                        >
                          Cajas
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li className="items-center">
                    <Link to="/admin/members">
                      <div
                        className="flex flex-row items-center px-3 py-2 gym-btn"
                        style={{
                          color: "#cbd5e1",
                          backgroundColor:
                            window.location.pathname === "/admin/members"
                              ? "#075985"
                              : "#082f49",
                        }}
                      >
                        <FitnessCenterIcon
                          sx={{
                            color: "#cbd5e1",
                            marginRight: 1,
                            fontSize: 15,
                          }}
                        />
                        <p
                          className="text-xs    font-light uppercase block"
                          style={textXs}
                        >
                          Socios
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li className="items-center">
                    <Link to="/progress/list">
                      <div
                        className="flex flex-row items-center px-3 py-2 gym-btn"
                        style={{
                          color: "#cbd5e1",
                          backgroundColor:
                            window.location.pathname === "/progress/list"
                              ? "#075985"
                              : "#082f49",
                        }}
                      >
                        <FitnessCenterIcon
                          sx={{
                            color: "#cbd5e1",
                            marginRight: 1,
                            fontSize: 15,
                          }}
                        />
                        <p
                          className="text-xs    font-light uppercase block"
                          style={textXs}
                        >
                          Evaluaciones
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li className="items-center">
                    <Link to="/admin/training">
                      <div
                        className="flex flex-row items-center px-3 py-2 gym-btn"
                        style={{
                          color: "#cbd5e1",
                          backgroundColor:
                            window.location.pathname === "/admin/training"
                              ? "#075985"
                              : "#082f49",
                        }}
                      >
                        <FitnessCenterIcon
                          sx={{
                            color: "#cbd5e1",
                            marginRight: 1,
                            fontSize: 15,
                          }}
                        />
                        <p
                          className="text-xs    font-light uppercase block"
                          style={textXs}
                        >
                          Rutinas
                        </p>
                      </div>
                    </Link>
                  </li>

                  <li className="items-center">
                    <Link to="/admin/marketing">
                      <div
                        className="flex flex-row items-center px-3 py-2 gym-btn"
                        style={{
                          color: "#cbd5e1",
                          backgroundColor:
                            window.location.pathname === "/admin/marketing"
                              ? "#075985"
                              : "#082f49",
                        }}
                      >
                        <CampaignIcon
                          sx={{
                            color: "#cbd5e1",
                            marginRight: 1,
                            fontSize: 15,
                          }}
                        />
                        <p
                          className="text-xs    font-light uppercase block"
                          style={textXs}
                        >
                          Campañas
                        </p>
                      </div>
                    </Link>
                  </li>

                  <li className="items-center">
                    <Link to="/admin/memberships/settings ">
                      <div
                        className="flex flex-row items-center px-3 py-2 gym-btn"
                        style={{
                          color: "#cbd5e1",
                          borderBottomLeftRadius: 15,
                          borderBottomRightRadius: 15,
                          backgroundColor:
                            window.location.pathname ===
                            "/admin/memberships/settings"
                              ? "#075985"
                              : "#082f49",
                        }}
                      >
                        <FitnessCenterIcon
                          sx={{
                            color: "#cbd5e1",
                            marginRight: 1,
                            fontSize: 15,
                          }}
                        />
                        <p
                          className="text-xs    font-light uppercase block"
                          style={textXs}
                        >
                          Planes
                        </p>
                      </div>
                    </Link>
                  </li>
                </div>
              </div>

              <div
                className="b2b-navigation"
                style={{ marginRight: 5, marginLeft: 5, marginBottom: 8 }}
              >
                <li
                  className="items-center "
                  onClick={() => openB2BDrop(!b2bdrop)}
                >
                  <div
                    className="flex flex-row items-center px-3 py-2  b2b-btn"
                    style={{
                      color: "#cbd5e1",
                      backgroundColor: "#082f49",
                      borderTopLeftRadius: 15,
                      borderTopRightRadius: 15,
                      borderBottomLeftRadius: !b2bdrop ? 15 : 0,
                      borderBottomRightRadius: !b2bdrop ? 15 : 0,
                    }}
                  >
                    <p
                      className="text-xs    font-light uppercase block"
                      style={textXs}
                    >
                      B2B
                    </p>
                    {b2bdrop ? (
                      <KeyboardDoubleArrowDown
                        sx={{
                          color: "#cbd5e1",
                          marginLeft: "auto",
                          fontSize: 15,
                        }}
                      />
                    ) : (
                      <KeyboardDoubleArrowRight
                        sx={{
                          color: "#cbd5e1",
                          marginLeft: "auto",
                          fontSize: 15,
                        }}
                      />
                    )}
                  </div>
                </li>
                <div
                  ref={b2bdropref}
                  style={{ display: "none", transition: "1s" }}
                >
                  <li className="items-center">
                    <Link to="/daily/b2b">
                      <div
                        className="flex flex-row items-center px-3 py-2 gym-btn"
                        style={{
                          color: "#cbd5e1",
                          backgroundColor:
                            window.location.pathname === "/daily/b2b"
                              ? "#075985"
                              : "#082f49",
                        }}
                      >
                        <AddBusinessIcon
                          sx={{
                            color: "#cbd5e1",
                            marginRight: 1,
                            fontSize: 15,
                          }}
                        />
                        <p
                          className="text-xs    font-light uppercase block"
                          style={textXs}
                        >
                          Turno
                        </p>
                      </div>
                    </Link>
                  </li>

                  <li className="items-center">
                    <Link to="/admin/costumers">
                      <div
                        className="flex flex-row items-center px-3 py-2 gym-btn"
                        style={{
                          color: "#cbd5e1",
                          backgroundColor:
                            window.location.pathname === "/admin/costumers"
                              ? "#075985"
                              : "#082f49",
                        }}
                      >
                        <FitnessCenterIcon
                          sx={{
                            color: "#cbd5e1",
                            marginRight: 1,
                            fontSize: 15,
                          }}
                        />
                        <p
                          className="text-xs    font-light uppercase block"
                          style={textXs}
                        >
                          Clientes
                        </p>
                      </div>
                    </Link>
                  </li>
                  <li className="items-center">
                    <Link to="/daily/retail">
                      <div
                        className="flex flex-row items-center px-3 py-2  gym-btn"
                        style={{
                          color: "#cbd5e1",
                          borderBottomLeftRadius: 15,
                          borderBottomRightRadius: 15,
                          backgroundColor:
                            window.location.pathname === "/daily/retail"
                              ? "#075985"
                              : "#082f49",
                        }}
                      >
                        <FitnessCenterIcon
                          sx={{
                            color: "#cbd5e1",
                            marginRight: 1,
                            fontSize: 15,
                          }}
                        />
                        <p
                          className="text-xs    font-light uppercase block"
                          style={textXs}
                        >
                          Pedidos
                        </p>
                      </div>
                    </Link>
                  </li>
                </div>
              </div>

              <div id="Navigation" className="px-2">
                {auth.isAdmin && (
                  <li className="items-center">
                    <Link
                      className={
                        "text-xs  py-1 my-1 font-light uppercase block "
                      }
                      to="/admin/dashboard"
                    >
                      <div
                        className="flex flex-row items-center px-3 py-1"
                        style={{
                          color: "#cbd5e1",
                          borderRadius: 12,
                          backgroundColor:
                            window.location.href.indexOf("/admin/dashboard") !==
                            -1
                              ? "#075985"
                              : "unset",
                        }}
                      >
                        <DashboardIcon
                          sx={{
                            color: "#cbd5e1",
                            marginRight: 1,
                            fontSize: 15,
                          }}
                        />
                        <p
                          className="text-xs    font-light uppercase block"
                          style={textXs}
                        >
                          Dashboard
                        </p>
                      </div>
                    </Link>
                  </li>
                )}

                <li className="items-center">
                  <Link
                    className={"text-xs  py-1 my-1 font-light uppercase block "}
                    to="/admin/products"
                  >
                    <div
                      className="flex flex-row items-center px-3 py-1"
                      style={{
                        color: "#cbd5e1",
                        borderRadius: 12,
                        backgroundColor:
                          window.location.href.indexOf("/admin/products") !== -1
                            ? "#075985"
                            : "unset",
                      }}
                    >
                      <ShoppingCartIcon
                        sx={{ color: "#cbd5e1", marginRight: 1, fontSize: 15 }}
                      />
                      <p
                        className="text-xs    font-light uppercase block"
                        style={textXs}
                      >
                        Productos
                      </p>
                    </div>
                  </Link>
                </li>

                <li className="items-center">
                  <Link
                    className={"text-xs  py-1 my-1 font-light uppercase block "}
                    to="/admin/warehouses"
                  >
                    <div
                      className="flex flex-row items-center px-3 py-1"
                      style={{
                        color: "#cbd5e1",
                        borderRadius: 12,
                        backgroundColor:
                          window.location.href.indexOf("/admin/warehouses") !==
                          -1
                            ? "#075985"
                            : "unset",
                      }}
                    >
                      <WarehouseIcon
                        sx={{ color: "#cbd5e1", marginRight: 1, fontSize: 15 }}
                      />
                      <p
                        className="text-xs    font-light uppercase block"
                        style={textXs}
                      >
                        Bodegas
                      </p>
                    </div>
                  </Link>
                </li>

                {/*       <li className="items-center">
                <Link
                  className={
                    "text-xs  py-2 my-1 font-light uppercase block  " +
                    (window.location.href.indexOf("/admin/messages") !== -1
                      ? "text-lightBlue-500 hover:text-lightBlue-600"
                      : "text-blueGray-700 hover:text-blueGray-500")
                  }
                  to="/admin/messages"
                >
                  <div
                    className="flex flex-row items-center"
                    style={{ position: "relative", color: "#cbd5e1" }}
                  >
                    <NotificationsIcon
                      sx={{ color: "#cbd5e1", marginRight: 1, fontSize: 15 }}
                    />
                    Mensajes
                  </div>
                </Link>
              </li> */}
              </div>

              <div className="px-3 mt-auto">
                <li
                  className="items-center"
                  onClick={() => auth.revokeAuth({ isMember: false })}
                >
                  <Link
                    className={
                      "text-xs  py-2  font-light uppercase block  " +
                      (window.location.href.indexOf("/admin/signout") !== -1
                        ? "text-lightBlue-500 hover:text-lightBlue-600"
                        : "text-blueGray-700 hover:text-blueGray-500")
                    }
                  >
                    <div
                      className="flex flex-row items-center"
                      style={{ position: "relative", color: "#cbd5e1" }}
                    >
                      <MeetingRoomIcon
                        sx={{ color: "#cbd5e1", marginRight: 1, fontSize: 15 }}
                      />
                      <p
                        className="text-xs    font-light uppercase block"
                        style={textXs}
                      >
                        Cerrar Sesión
                      </p>
                    </div>
                  </Link>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
