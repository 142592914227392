const { useLayout } = require("context/layout/layout");

export const Alert = () => {
  const layout = useLayout();

  let severity = ` text-white px-6 py-4 border-0 rounded relative mb-4 ${layout.bg_theme}`;

  let styles = {};

  styles.container = {
    position: "fixed",
    bottom: 0,
    left: layout.alert ? 20 : -920,
    width: "600px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    transition: ".5s",
    zIndex: 9999999,
  };

  return (
    <div className={severity} style={styles.container}>
      <i class="fas fa-bell mr-4"></i>
      <p class="mr-auto">{layout.message}</p>
      <i class="fas fa-times"></i>
    </div>
  );
};
