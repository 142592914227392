import { Autocomplete, IconButton, TextField } from "@mui/material";
import Sidebar from "components/Sidebar/Sidebar";
import { Spinner } from "components/Spinner";
import { useLayout } from "context/layout/layout";
import { useStore } from "context/store/store";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { defaultWarehouse } from "utils/state";
import axios from "axios";
import config from "services/config";
import { TableDataItem } from "components/Cards/CardTable";
import CardTable from "components/Cards/CardTable";
import FilterListIcon from "@mui/icons-material/FilterList";
import DownloadIcon from "@mui/icons-material/Download";
import SearchIcon from "@mui/icons-material/Search";
import { useAuth } from "context/auth/auth";

let icons = {
  color: "#cbd5e1",
  fontSize: 25,
  margin: "0 10px",
};

export const WarehouseDetail = () => {
  return (
    <>
      <Sidebar />
      <Wrapper>
        <AddWarehouseSettings />
      </Wrapper>
    </>
  );
};

const Wrapper = ({ children }) => {
  return (
    <div className="relative h-full" style={{ marginLeft: 200 }}>
      <div className="relative h-full" style={{ paddingBottom: 95 }}></div>
      <div className=" mx-auto w-full -m-24" style={{ height: "100vh" }}>
        <div className="flex flex-wrap h-full">
          <div className="w-full h-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

function AddWarehouseSettings() {
  const auth = useAuth();
  const layout = useLayout();
  const location = useLocation();
  const navigate = useNavigate();

  const store = useStore();
  const [loading, setLoading] = React.useState(true);
  const [warehouse, setWarehouse] = React.useState(defaultWarehouse);
  const [movementsToday, setMovementsToday] = React.useState(null);
  const [products, setProducts] = useState(null);
  const [inventory, setInventory] = useState(null);
  const [, /* isSearching */ setIsSearching] = useState(null);
  const [productList, setProductList] = useState({
    isEntry: false,
    isVisible: false,
    current: "",
    currentName: "",
    currentQty: 0,
  });

  useEffect(() => {
    let detail = location.state.detail;
    if (!detail) {
      return navigate(-1);
    }

    if (detail) {
      setWarehouse(detail);

      store
        .getInventory(detail._id)
        .then((res) => {
          console.log({ res });
          setInventory(res);
        })
        .catch((err) => {
          console.log(err);
        });

      store
        .getWarehouseMovementsFromToday(detail._id)
        .then((res) => {
          console.log({ res });
          setMovementsToday(res.movements);
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    axios
      .get(`${config.url}/products/showall`)
      .then((res) => {
        setProducts(res.data.products);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = () => {
    if (Object.values(warehouse).some((v) => v === "")) {
      layout.errorAlert({ message: "Todos los campos sob obligatorios" });
      return;
    }
    console.log(warehouse);
    setLoading(true);
    store
      .updateWarehouse({ payload: warehouse, id: warehouse._id })
      .then((res) => {
        setLoading(false);
        navigate(-1);
        layout.successAlert({ message: "Bodega actualizada con exito" });
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        layout.errorAlert({ message: `Error al actualizar la bodega: ${err}` });
        console.log(err);
      });
  };

  const handleEntry = () => {
    setProductList({
      ...productList,
      isEntry: true,
      isVisible: true,
    });
  };

  const handleRemove = () => {
    setProductList({
      ...productList,
      isEntry: false,
      isVisible: true,
    });
  };

  const handleWarehouseEntry = () => {
    if (!productList.current) {
      return layout.errorAlert({ message: "Debe selecionar un producto" });
    }

    if (!productList.currentQty) {
      return layout.errorAlert({ message: "Debe ingresar la cantidad" });
    }

    setLoading(true);
    store
      .addInventory({
        productId: productList.current._id,
        warehouseId: warehouse._id,
        quantityToAdd: Number(productList.currentQty),
      })
      .then((res) => {
        setProductList({
          isEntry: false,
          isVisible: false,
          current: null,
          currentName: "",
          currentQty: 0,
        });
        warehouseRefresh();
        setLoading(false);

        layout.successAlert({ message: `Entrada registrada exitosamente` });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);

        layout.errorAlert({ message: `Error en el servicio: ${err}` });
      });
  };

  const handleWarehouseRemove = () => {
    if (!productList.current) {
      return layout.errorAlert({ message: "Debe selecionar un producto" });
    }

    if (!productList.currentQty) {
      return layout.errorAlert({ message: "Debe ingresar la cantidad" });
    }

    store
      .removeInventory({
        productId: productList.current._id,
        warehouseId: warehouse._id,
        quantityToRemove: Number(productList.currentQty),
      })
      .then((res) => {
        console.log(res);
        setProductList({
          isEntry: false,
          isVisible: false,
          current: null,
          currentName: "",
          currentQty: 0,
        });
        layout.successAlert({ message: `Salida registrada exitosamente` });
        warehouseRefresh();
      })
      .catch((err) => {
        console.log(err);
        layout.errorAlert({ message: `Error en el servicio: ${err}` });
      });
  };

  /**
   * Handler to search item in inventory list
   */
  const handleInventorySearch = () => {
    setIsSearching(true);
  };

  /**
   * Handler to search item in todays movement list
   */
  const handleTodaysMovementSearch = () => {
    setIsSearching(true);
  };
  /**
   * Handler to search item in warehouse movement list
   */
  const handleWarehouseMovements = () => {
    setIsSearching(true);
  };

  /**
   *
   * Warehouse refresh
   */

  const warehouseRefresh = async () => {
    const [warehouse, inventory, todaysMov] = await Promise.allSettled([
      await store.getWarehouse(location.state.detail._id),
      await store.getInventory(location.state.detail._id),
      await store.getWarehouseMovementsFromToday(location.state.detail._id),
    ]);

    if (warehouse.value) {
      setWarehouse(warehouse.value);
    }

    if (inventory.value) {
      setInventory(inventory.value);
    }

    if (todaysMov.value) {
      setMovementsToday(todaysMov.value.movements);
    }
  };

  return (
    <>
      {loading && <Spinner />}

      <div
        className="relative h-full flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
        style={{ backgroundColor: "rgb(17, 24, 39)" }}
      >
        <div
          className="rounded-t  mb-0 pt-6"
          style={{ backgroundColor: "rgb(17, 24, 39)", paddingLeft: 50 }}
        >
          <div className="text-center flex justify-between">
            <h6 className="text-white  text-xl font-bold">
              Detalle bodega: {warehouse._id}
            </h6>
          </div>
        </div>

        <div
          className="flex-auto px-4 lg:px-10 py-10 pt-0"
          style={{ backgroundColor: "rgb(17, 24, 39)" }}
        >
          <form>
            <div className="flex flex-wrap pt-6">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Nombre
                  </label>
                  <TextField
                    disabled
                    className="border-0  placeholder-black text-blueGray-600 rounded text-sm shadow  w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    sx={{
                      width: "100%",
                      backgroundColor: "#cbd5e1",
                    }}
                    value={warehouse.name}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Ubicacion
                  </label>
                  <TextField
                    className="border-0  placeholder-black text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={warehouse.campus.title}
                    disabled
                  />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-6 my-4">
                <h6 className="text-white  text-lg font-bold ">
                  Crear un movimiento
                </h6>
              </div>
              <div
                className="w-full lg:w-6/12 px-4 my-2"
                style={{ visibility: "hidden" }}
              >
                <h6 className="text-white  text-xl font-bold">
                  Detalle bodega: {warehouse.name}
                </h6>
              </div>

              <div className="w-full lg:w-6/12 px-4 my-2">
                <button
                  onClick={handleEntry}
                  style={{
                    height: 50,
                    width: "40%",
                    backgroundColor: "#15803d",
                  }}
                  className="  text-white  font-bold uppercase text-xs rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  Entrada
                </button>
              </div>

              {auth && auth.isAdmin && (
                <div className="w-full lg:w-6/12 px-4 my-2">
                  <button
                    onClick={handleRemove}
                    style={{
                      height: 50,
                      width: "40%",
                      backgroundColor: "#991b1b",
                    }}
                    className=" bg-lightBlue-500  text-white active:bg-lightBlue-600 font-bold uppercase text-xs rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                    type="button"
                  >
                    Salida
                  </button>
                </div>
              )}

              {productList.isVisible && (
                <>
                  <div className="w-full lg:w-6/12 px-4 my-4">
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Selecciona un producto
                      </label>
                      <Autocomplete
                        className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        id="combo-box-demo"
                        options={products}
                        getOptionLabel={(option) => option.name}
                        onChange={(e, value) => {
                          setProductList({
                            ...productList,
                            current: value,
                            currentName: value.name,
                          });
                        }}
                        sx={{
                          padding: 0,
                          border: "unset",
                          backgroundColor: "#cbd5e1",
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Selecciona un producto"
                          />
                        )}
                      />
                    </div>
                  </div>

                  <div className="w-full lg:w-6/12 px-4 my-4">
                    <div className="relative w-full mb-3 ">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="grid-password"
                      >
                        Ingresa la cantidad
                      </label>
                      <TextField
                        type="number"
                        value={productList.currentQty}
                        onChange={({ target }) =>
                          setProductList({
                            ...productList,
                            currentQty: target.value,
                          })
                        }
                        sx={{ marginRight: 5, backgroundColor: "#cbd5e1" }}
                        className=" border-0  placeholder-blueGray-300 text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-1/4 ease-linear transition-all duration-150 "
                      />
                      <button
                        onClick={
                          productList.isEntry
                            ? handleWarehouseEntry
                            : handleWarehouseRemove
                        }
                        style={{
                          height: 50,
                          width: "40%",
                          backgroundColor: productList.isEntry
                            ? "#15803d"
                            : "#991b1b",
                        }}
                        className=" bg-lightBlue-500  text-white active:bg-lightBlue-600 mt-1 font-bold uppercase text-xs rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                        type="button"
                      >
                        {productList.isEntry
                          ? "Confirmar entrada"
                          : "Confirmar salida"}
                      </button>
                    </div>
                  </div>
                </>
              )}

              {inventory && inventory.length && (
                <div style={{ width: "100%" }} className="px-4 py-2">
                  <div className="flex justify-between items-center">
                    <h6 className="text-white  text-lg font-bold py-3 ">
                      Inventario
                    </h6>

                    <div style={{ display: "flex" }}>
                      <div>
                        <TextField
                          className="border-0  placeholder-black text-blueGray-600 rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          id="outlined-basic"
                          variant="outlined"
                          placeholder="Buscar"
                          InputLabelProps={{
                            style: {
                              height: 30,
                              padding: "0 10px",
                            },
                          }}
                          inputProps={{
                            style: {
                              height: 30,
                              padding: "0 10px",
                            },
                          }}
                          sx={{
                            width: "100%",
                            height: 30,
                            padding: 0,
                            backgroundColor: "#cbd5e1",
                            transform: "translateX(-10px)",
                          }}
                        />
                      </div>
                      <IconButton
                        sx={{ margin: 0, padding: 0 }}
                        onClick={handleInventorySearch}
                      >
                        <SearchIcon sx={icons} />
                      </IconButton>
                      <IconButton sx={{ margin: 0, padding: 0 }}>
                        <DownloadIcon sx={icons} />
                      </IconButton>
                      <IconButton sx={{ margin: 0, padding: 0 }}>
                        <FilterListIcon sx={icons} />
                      </IconButton>
                    </div>
                  </div>
                  <CardTable
                    headers={["Nombre", "Codigo", "Id", "Unidades"]}
                    color={"dark"}
                  >
                    {inventory.map((p) => {
                      return (
                        <tr>
                          <TableDataItem
                            sx={{
                              fontWeight: 600,
                              fontSize: 15,
                              textAlign: "left",
                              transform: "translateX(25px)",
                            }}
                          >
                            {p.name}
                          </TableDataItem>

                          <TableDataItem>{p.code}</TableDataItem>
                          <TableDataItem>{p.productId}</TableDataItem>
                          <TableDataItem>{p.quantity}</TableDataItem>
                        </tr>
                      );
                    })}
                  </CardTable>
                </div>
              )}
              {movementsToday && movementsToday.length && (
                <div style={{ width: "100%" }} className="px-4 py-2">
                  <div className="flex justify-between items-center">
                    <h6 className="text-white  text-lg font-bold py-3 ">
                      Movimientos de hoy, {new Date().toLocaleDateString()}
                    </h6>
                    <div style={{ display: "flex" }}>
                      <div>
                        <TextField
                          className="border-0  placeholder-black text-blueGray-600 rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          id="outlined-basic"
                          variant="outlined"
                          placeholder="Buscar"
                          InputLabelProps={{
                            style: {
                              height: 30,
                              padding: "0 10px",
                            },
                          }}
                          inputProps={{
                            style: {
                              height: 30,
                              padding: "0 10px",
                            },
                          }}
                          sx={{
                            width: "100%",
                            height: 30,
                            padding: 0,
                            backgroundColor: "#cbd5e1",
                            transform: "translateX(-10px)",
                          }}
                        />
                      </div>
                      <IconButton
                        sx={{ margin: 0, padding: 0 }}
                        onClick={handleTodaysMovementSearch}
                      >
                        <SearchIcon sx={icons} />
                      </IconButton>
                      <IconButton sx={{ margin: 0, padding: 0 }}>
                        <DownloadIcon sx={icons} />
                      </IconButton>
                      <IconButton sx={{ margin: 0, padding: 0 }}>
                        <FilterListIcon sx={icons} />
                      </IconButton>
                    </div>
                  </div>
                  <CardTable
                    headers={[
                      "Producto",
                      "Codigo",
                      "Tipo de movimiento",
                      "Unidades",
                      "Fecha",
                    ]}
                    color={"dark"}
                  >
                    {movementsToday.map((m) => {
                      return (
                        <tr>
                          <TableDataItem
                            sx={{
                              fontWeight: 600,
                              fontSize: 15,
                              textAlign: "left",
                              transform: "translateX(25px)",
                            }}
                          >
                            {m.product && m.product.name}
                          </TableDataItem>
                          <TableDataItem>
                            {m.product && m.product.code}
                          </TableDataItem>

                          {m.movementType === "IN" && (
                            <TableDataItem
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p
                                style={{
                                  backgroundColor: "#15803d",

                                  color: "#fff",
                                  borderRadius: 5,
                                  padding: "3px 5px",
                                  width: "50%",
                                }}
                              >
                                Entrada
                              </p>
                            </TableDataItem>
                          )}
                          {m.movementType === "OUT" && (
                            <TableDataItem
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <p
                                style={{
                                  backgroundColor: "#991b1b",

                                  color: "#fff",
                                  borderRadius: 5,
                                  padding: "3px 5px",
                                  width: "50%",
                                }}
                              >
                                Salida
                              </p>
                            </TableDataItem>
                          )}
                          <TableDataItem>{m.quantity}</TableDataItem>
                          <TableDataItem>{m.timestamp}</TableDataItem>
                        </tr>
                      );
                    })}
                  </CardTable>
                </div>
              )}
              {warehouse.movementHistory &&
                warehouse.movementHistory.length && (
                  <div style={{ width: "100%" }} className="px-4 py-2">
                    <div className="flex justify-between items-center">
                      <h6 className="text-white  text-lg font-bold py-3 ">
                        Historial de movimientos
                      </h6>
                      <div style={{ display: "flex" }}>
                        <div>
                          <TextField
                            className="border-0  placeholder-black text-blueGray-600 rounded text-xs shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            id="outlined-basic"
                            variant="outlined"
                            placeholder="Buscar"
                            InputLabelProps={{
                              style: {
                                height: 30,
                                padding: "0 10px",
                              },
                            }}
                            inputProps={{
                              style: {
                                height: 30,
                                padding: "0 10px",
                              },
                            }}
                            sx={{
                              width: "100%",
                              height: 30,
                              padding: 0,
                              backgroundColor: "#cbd5e1",
                              transform: "translateX(-10px)",
                            }}
                          />
                        </div>
                        <IconButton
                          sx={{ margin: 0, padding: 0 }}
                          onClick={handleWarehouseMovements}
                        >
                          <SearchIcon sx={icons} />
                        </IconButton>
                        <IconButton sx={{ margin: 0, padding: 0 }}>
                          <DownloadIcon sx={icons} />
                        </IconButton>
                        <IconButton sx={{ margin: 0, padding: 0 }}>
                          <FilterListIcon sx={icons} />
                        </IconButton>
                      </div>
                    </div>
                    <CardTable
                      headers={[
                        "Producto",
                        "Código",
                        "Tipo de movimiento",
                        "Unidades",
                        "Fecha",
                      ]}
                      color={"dark"}
                    >
                      {warehouse.movementHistory.map((m) => {
                        return (
                          <tr>
                            <TableDataItem
                              sx={{
                                fontWeight: 600,
                                fontSize: 15,
                                textAlign: "left",
                                transform: "translateX(25px)",
                              }}
                            >
                              {m.product && m.product.name}
                            </TableDataItem>
                            <TableDataItem>
                              {m.product && m.product.code}
                            </TableDataItem>

                            {m.movementType === "IN" && (
                              <TableDataItem
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <p
                                  style={{
                                    backgroundColor: "#15803d",

                                    color: "#fff",
                                    borderRadius: 5,
                                    padding: "3px 5px",
                                    width: "50%",
                                  }}
                                >
                                  Entrada
                                </p>
                              </TableDataItem>
                            )}
                            {m.movementType === "OUT" && (
                              <TableDataItem
                                sx={{
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <p
                                  style={{
                                    backgroundColor: "#991b1b",

                                    color: "#fff",
                                    borderRadius: 5,
                                    padding: "3px 5px",
                                    width: "50%",
                                  }}
                                >
                                  Salida
                                </p>
                              </TableDataItem>
                            )}
                            <TableDataItem>{m.quantity}</TableDataItem>
                            <TableDataItem>{m.timestamp}</TableDataItem>
                          </tr>
                        );
                      })}
                    </CardTable>
                  </div>
                )}
            </div>
            <div
              className="py-10 w-full"
              style={{ transform: "translateX(23px)" }}
            >
              <button
                onClick={handleSubmit}
                style={{ height: 50, width: "46%" }}
                className=" bg-lightBlue-500  text-white active:bg-lightBlue-600 font-bold uppercase text-xs rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
                Guardar cambios
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
