import axios from "axios";
import config from "services/config";

export class SalesService {
  static async create(payload) {
    console.log({ payload });
    try {
      const { data } = await axios.post(`${config.url}/sales/create`, payload, {
        headers: {
          "Content-type": "application/json",
        },
      });
      return data;
    } catch (error) {
      throw error;
    }
  }
}
