import React, { useEffect, useState } from "react";
import Sidebar from "components/Sidebar/Sidebar.js";
import CardTable from "components/Cards/CardTable";
import axios from "axios";
import { TableDataItem } from "components/Cards/CardTable";
/* import { useStore } from "../../context/store/store";
 */ import { Spinner } from "components/Spinner";
import { useStore } from "context/store/store";
import config from "services/config";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { IconButton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useLayout } from "context/layout/layout";
import { PAYMENTS } from "utils/state";
import { formatCLP } from "utils";

/* let icons = {
  color: "#cbd5e1",
  fontSize: 25,
  margin: "0 10px",
};
 */
export function DailyDetail({ color = null }) {
  const store = useStore();
  const layout = useLayout();
  const params = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [shift, setShift] = useState({
    isInProgress: false,
    shift: null,
  });
  const [message, setMessage] = useState({
    isOpen: false,
    handleConfirm: () => handleConfirm(),
    handleCancel: () => handleCancel(),
  });

  useEffect(() => {
    console.log(params);
    axios
      .get(`${config.url}/daily/find/${params.id}`)
      .then(({ data }) => {
        setShift(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  const handleOpenShift = () => {
    setMessage({
      ...message,
      isOpen: true,
      title: `Usted esta apunto de abrir caja para el dia ${new Date().toLocaleDateString()}`,
      body: "Confirme la acción selecionada",
    });
  };

  const handleCancel = () => {
    setMessage({
      ...message,
      isOpen: false,
    });
  };

  const handleConfirm = () => {
    setLoading(true);
    setMessage({
      ...message,
      isOpen: false,
    });
    openShift();
  };

  const openShift = () => {
    store
      .openShift()
      .then((res) => {
        console.log(res);
        setLoading(false);
        setShift({
          isInProgress: true,
          shift: res,
        });
        layout.successAlert({ message: "Apertura de turno exitosa" });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        layout.errorAlert({ message: `Error en el servicio: ${err}` });
      });
  };

  /*   const handleConfirmDelete = () => {
    store
      .deleteShift(shift.shift._id)
      .then((res) => {
        console.log(res);
        setShift({
          isInProgress: false,
          shift: null,
        });
        setMessage({
          ...message,
          isOpen: false,
        });
        layout.successAlert({ message: `Turno eliminado con éxito` });
      })
      .catch((err) => {
        console.log(err);
        layout.errorAlert({ message: `Error en el servicio: ${err}` });
      });
  }; */

  /*   const handleDelete = () => {
    setMessage({
      ...message,
      isOpen: true,
      title: "Desea eliminar este turno",
      body: "Esta accioón es irreversible, verifique antes de eliminar el turno",
      handleConfirm: () => handleConfirmDelete(),
    });
  }; */

  /*   const navigateToNewOrder = () => {
    navigate(`/sales/add`, {
      state: { shiftId: shift.shift._id },
    });
  }; */

  /*   function calculateCash() {
    let cashItem = shift.paymentResume.find((p) => p.code === "CASH");
    return cashItem.amount;
  } */

  /*   const navigateToWithdraw = () => {
    let cash = calculateCash();
    if (cash < 1) {
      return layout.errorAlert({ message: "No existe efectivo para retirar" });
    }

    navigate(`/withdraw/add`, {
      state: { shiftId: shift.shift._id, cashAmount: cash },
    });
  }; */

  /*   const navigateToExpense = () => {
    let cash = calculateCash();
    if (cash < 1) {
      return layout.errorAlert({ message: "No existe efectivo para retirar" });
    }
    navigate(`/expense/add`, {
      state: { shiftId: shift.shift._id, cashAmount: cash },
    });
  }; */

  function navigateToDetails(detail) {
    console.log(detail);
    navigate(`/sales/view/${detail._id}`, {
      state: { detail: detail },
    });
  }

  function getPaymentName(payment) {
    let obj = PAYMENTS.find((p) => p.code === payment) || null;
    if (obj) {
      return obj.name;
    }
    return "";
  }

  return (
    <>
      {loading && <Spinner />}
      <Sidebar />
      <div
        className="relative"
        style={{
          marginLeft: 210,
          backgroundColor: "rgb(17, 24, 39)",
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <div>
          <div className="py-5 px-4">
            <h6 className="text-white  text-lg font-bold ">
              DETALLE DEL TURNO: {new Date().toLocaleDateString()}
            </h6>
            {!shift.isInProgress && (
              <div className="w-full lg:w-6/12 my-3">
                <button
                  onClick={handleOpenShift}
                  style={{
                    height: 45,
                    width: "40%",
                    backgroundColor: "#15803d",
                  }}
                  className="  text-white  font-bold uppercase text-xs rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  Apertura turno {new Date().toLocaleDateString()}
                </button>
              </div>
            )}

            {shift && shift.shift && (
              <div>
                <p>Turno en progreso</p>
                <p>ID del turno: {shift.shift._id}</p>
                <p>
                  Hora de apertura:{" "}
                  {shift.shift.timeopen.split("T")[1].slice(0, 8)}
                </p>
              </div>
            )}
          </div>
          {shift && shift.shift && shift.shift.sales && (
            <div style={{ width: "100%" }} className="px-4 py-2">
              <div className="flex justify-between items-center">
                <h6 className="text-white  text-lg font-bold py-3 ">Ventas</h6>
              </div>
              <CardTable
                headers={[
                  "Vendedor",
                  "Hora",
                  "Monto",
                  "Método de pago",
                  "Acciones",
                ]}
                color={"dark"}
              >
                {shift.shift.sales.map((s) => {
                  return (
                    <tr>
                      <TableDataItem>{s.salesman.name}</TableDataItem>
                      <TableDataItem>
                        {s.date.split("T")[1].slice(0, 8)}
                      </TableDataItem>
                      <TableDataItem>{formatCLP(s.totalPrice)}</TableDataItem>
                      <TableDataItem>{getPaymentName(s.payment)}</TableDataItem>
                      <TableDataItem>
                        <IconButton onClick={() => navigateToDetails(s)}>
                          <VisibilityIcon sx={{ color: "#ccc" }} />
                        </IconButton>
                      </TableDataItem>
                    </tr>
                  );
                })}
                {shift.shift.enrollments.map((s) => {
                  return (
                    <tr>
                      <TableDataItem>{s.salesman.name}</TableDataItem>
                      <TableDataItem>
                        {s.date.split("T")[1].slice(0, 8)}
                      </TableDataItem>
                      <TableDataItem>{formatCLP(s.totalPrice)}</TableDataItem>
                      <TableDataItem>{getPaymentName(s.payment)}</TableDataItem>
                      <TableDataItem>
                        <IconButton onClick={() => navigateToDetails(s)}>
                          <VisibilityIcon sx={{ color: "#ccc" }} />
                        </IconButton>
                      </TableDataItem>
                    </tr>
                  );
                })}
              </CardTable>
            </div>
          )}

          {shift && shift.shift && shift.shift.expenses && (
            <div style={{ width: "100%" }} className="px-4 py-2">
              <div className="flex justify-between items-center">
                <h6 className="text-white  text-lg font-bold py-3 ">Gastos</h6>
              </div>
              <CardTable
                headers={["Stafff", "Monto", "Hora", "Comentarios"]}
                color={"dark"}
              >
                {shift.shift.expenses.map((s) => {
                  return (
                    <tr>
                      <TableDataItem
                        sx={{
                          textAlign: "left",
                          transform: "translateX(40px)",
                        }}
                      >
                        {s.staff.name}
                      </TableDataItem>
                      <TableDataItem>{formatCLP(s.amount)}</TableDataItem>
                      <TableDataItem>
                        {s.date.split("T")[1].slice(0, 8)}
                      </TableDataItem>

                      <TableDataItem>{s.comments}</TableDataItem>
                    </tr>
                  );
                })}
              </CardTable>
            </div>
          )}

          {shift && shift.shift && shift.shift.withdraw && (
            <div style={{ width: "100%" }} className="px-4 py-2">
              <div className="flex justify-between items-center">
                <h6 className="text-white  text-lg font-bold py-3 ">
                  Retiro efectivo
                </h6>
              </div>
              <CardTable
                headers={["Stafff", "Monto", "Hora", "Comentarios"]}
                color={"dark"}
              >
                {shift.shift.withdraw.map((s) => {
                  return (
                    <tr>
                      <TableDataItem
                        sx={{
                          textAlign: "left",
                          transform: "translateX(40px)",
                        }}
                      >
                        {s.staff.name}
                      </TableDataItem>
                      <TableDataItem>{formatCLP(s.amount)}</TableDataItem>
                      <TableDataItem>
                        {s.date.split("T")[1].slice(0, 8)}
                      </TableDataItem>

                      <TableDataItem>{s.comments}</TableDataItem>
                    </tr>
                  );
                })}
              </CardTable>
            </div>
          )}

          <div className="py-6 px-6">
            <p className="text-white text-lg my-2">Resumen</p>
            {shift.paymentResume &&
              shift.paymentResume.map((payment) => {
                if (payment.code === "WITHDRAW") {
                  return (
                    <div
                      className="flex justify-between px-4"
                      style={{ borderBottom: ".5px dotted #64748b" }}
                    >
                      <p className="text-white text-sm font-light   my-2">
                        {payment.name}
                      </p>
                      <p className="text-white font-light text-sm my-2">
                        {formatCLP(payment.amount)}
                      </p>
                    </div>
                  );
                }
                if (payment.code === "DEBT") {
                  return (
                    <div
                      className="flex justify-between   px-4"
                      style={{ borderBottom: ".5px dotted #64748b" }}
                    >
                      <p className="text-white text-sm font-light   my-2">
                        {payment.name}
                      </p>
                      <p className="text-white font-light text-sm my-2">
                        {formatCLP(payment.amount)}
                      </p>
                    </div>
                  );
                }
                if (payment.code === "TOTAL") {
                  return (
                    <div className="flex justify-between px-4">
                      <p className="text-white text-xl font-semibold my-2">
                        {payment.name}
                      </p>
                      <p className="text-white font-semibold text-xl my-2">
                        {formatCLP(payment.amount)}
                      </p>
                    </div>
                  );
                }
                return (
                  <div className="flex justify-between px-4">
                    <p className="text-white font-light text-sm my-2">
                      {payment.name}
                    </p>
                    <p className="text-white font-light text-sm my-2">
                      {formatCLP(payment.amount)}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <Dialog
        open={message.isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "rgb(17, 24, 39)",
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ backgroundColor: "rgb(17, 24, 39)", color: "#fff" }}
        >
          {message.title}
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "rgb(17, 24, 39)" }}>
          <DialogContentText
            id="alert-dialog-description"
            sx={{ color: "#fff" }}
          >
            {message.body}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ backgroundColor: "rgb(17, 24, 39)" }}>
          <Button
            sx={{ color: "#fff" }}
            variant="contained"
            color="success"
            onClick={message.handleConfirm}
          >
            Confirmar
          </Button>
          <Button
            sx={{ color: "#fff" }}
            variant="contained"
            color="error"
            onClick={message.handleCancel}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
