import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import Sidebar from "components/Sidebar/Sidebar";
import { Spinner } from "components/Spinner";
import { useLayout } from "context/layout/layout";
import { useStore } from "context/store/store";
import React, { useEffect } from "react";
import config from "services/config";
import { defaultWarehouse } from "utils/state";

export const AddWarehouse = () => {
  return (
    <>
      <Sidebar />
      <Wrapper>
        <AddWarehouseSettings />
      </Wrapper>
    </>
  );
};

const Wrapper = ({ children }) => {
  return (
    <div className="relative h-full" style={{ marginLeft: 200 }}>
      <div className="relative h-full" style={{ paddingBottom: 95 }}></div>
      <div className=" mx-auto w-full -m-24" style={{ height: "100vh" }}>
        <div className="flex flex-wrap h-full">
          <div className="w-full h-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

function AddWarehouseSettings() {
  const layout = useLayout();
  const store = useStore();
  const [loading, setLoading] = React.useState(false);
  const [campusList, setCampusList] = React.useState([]);
  const [warehouse, setWarehouse] = React.useState(defaultWarehouse);

  useEffect(() => {
    axios
      .get(`${config.url}/campus/list`)
      .then(({ data }) => {
        console.log(data);
        setCampusList(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = () => {
    if (Object.values(warehouse).some((v) => v === "")) {
      layout.errorAlert({ message: "Todos los campos sob obligatorios" });
      return;
    }

    setLoading(true);
    store
      .createWarehouse(warehouse)
      .then((res) => {
        setLoading(false);
        setWarehouse(defaultWarehouse);
        layout.successAlert({ message: "Bodega creada con éxito" });
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      {loading && <Spinner />}

      <div
        className="relative h-full flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
        style={{ backgroundColor: "rgb(17, 24, 39)" }}
      >
        <div
          className="rounded-t  mb-0 py-6 "
          style={{ backgroundColor: "rgb(17, 24, 39)", paddingLeft: 57 }}
        >
          <div className="text-center flex justify-between">
            <h6 className="text-white  text-xl font-bold">CREAR BODEGA</h6>
          </div>
        </div>

        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <div className="flex flex-wrap pt-6">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Nombre
                  </label>
                  <TextField
                    className="border-0  placeholder-black text-blueGray-600 rounded text-sm shadow  w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    placeholder="Nombre de la bodega"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={warehouse.name}
                    onChange={(e) =>
                      setWarehouse({
                        ...warehouse,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Ubicacion
                  </label>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Selecciona un campus"
                      />
                    )}
                    getOptionLabel={(option) => option.title}
                    className="border-0  placeholder-black text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    options={campusList}
                    placeholder="Ubicación de la bodega"
                    variant="outlined"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    onChange={(e, value) =>
                      setWarehouse({
                        ...warehouse,
                        campus: value._id,
                      })
                    }
                  />
                </div>
              </div>
            </div>
            <div
              className="my-6 w-full"
              style={{ transform: "translateX(23px)" }}
            >
              <button
                onClick={handleSubmit}
                style={{ height: 50, width: "46%" }}
                className=" bg-lightBlue-500  text-white active:bg-lightBlue-600 font-bold uppercase text-xs rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
                Publicar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
