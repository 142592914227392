import React, { useEffect, useState } from "react";
import Sidebar from "components/Sidebar/Sidebar.js";
import CardTable from "components/Cards/CardTable";
import axios from "axios";
import { TableDataItem } from "components/Cards/CardTable";
/* import { useStore } from "../../context/store/store";
 */ import { Spinner } from "components/Spinner";
/* import { useStore } from "context/store/store";
 */ /* import VisibilityIcon from "@mui/icons-material/Visibility";
 */ import config from "services/config";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useStore } from "context/store/store";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "context/auth/auth";

const productsHeaders = ["Nombre", "Rut", "Dirección", "Acciones"];

export function CostumerList({ color = null }) {
  const auth = useAuth();
  const store = useStore();
  const navigate = useNavigate();
  const [members, setMembers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [temp, setTemp] = useState(null);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (query.length > 0 && temp && temp.length > 0) {
      let filtered = temp.filter((p) => p.id_number.includes(query));
      setMembers(filtered);
    }

    if (query === "") {
      setMembers(temp);
    }
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    axios
      .get(`${config.url}/costumer/list`)
      .then((res) => {
        console.log(res);
        setMembers(res.data);
        setTemp(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  const handleDelete = async ({ member_id }) => {
    setLoading(true);
    try {
      await store.deleteRetailClient(member_id);
      let newproducts = members.filter((p) => p._id !== member_id);
      setLoading(false);
      setMembers(newproducts);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const seeDetails = ({ member_id, member }) => {
    navigate(`/retail/view/${member_id}`);
  };

  return (
    <>
      {loading && <Spinner />}
      <Sidebar />
      <div className="relative" style={{ marginLeft: 200 }}>
        <div className="relative " style={{ paddingBottom: 95 }}></div>
        <div className=" mx-auto w-full -m-24">
          <div className="flex flex-wrap">
            <div className="w-full  h-full">
              <CardTable
                title={"RETAIL LOBAREDE NUTRITION"}
                headers={productsHeaders}
                add="Agregar Cliente"
                routeadd="/costumers/add"
                filterComponent={true}
                query={query}
                setQuery={setQuery}
                color={"dark"}
                filterPlaceholder="Buscar usuario por rut"
              >
                {members &&
                  members.length > 0 &&
                  members.map((product, idx) => {
                    return (
                      <tr>
                        <TableDataItem
                          sx={{
                            fontWeight: 600,
                            fontSize: 15,
                            textAlign: "left",
                            transform: "translateX(25px)",
                          }}
                        >
                          {product.name}
                        </TableDataItem>
                        <TableDataItem>{product.id_number}</TableDataItem>

                        <TableDataItem>{product.address}</TableDataItem>

                        <TableDataItem>
                          {auth.isAdmin && (
                            <IconButton
                              onClick={() =>
                                handleDelete({ member_id: product._id })
                              }
                            >
                              <DeleteIcon sx={{ color: "#b91c1c" }} />
                            </IconButton>
                          )}
                          <IconButton
                            onClick={() =>
                              seeDetails({
                                member_id: product._id,
                                member: product,
                              })
                            }
                          >
                            <VisibilityIcon sx={{ color: "#ccc" }} />
                          </IconButton>
                        </TableDataItem>
                      </tr>
                    );
                  })}
              </CardTable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
