import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import Sidebar from "components/Sidebar/Sidebar";
import { Spinner } from "components/Spinner";
/* import { useLayout } from "context/layout/layout"; */
import React, { useEffect, useState } from "react";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import config from "services/config";
import { useLocation, useNavigate } from "react-router-dom";

export const AddComment = () => {
  return (
    <>
      <Sidebar />
      <Wrapper>
        <AddProductSettings />
      </Wrapper>
    </>
  );
};

const Wrapper = ({ children }) => {
  return (
    <div className="relative h-full" style={{ marginLeft: 200 }}>
      <div className="relative h-full" style={{ paddingBottom: 95 }}></div>
      <div className=" mx-auto w-full -m-24" style={{ height: "100vh" }}>
        <div className="flex flex-wrap h-full">
          <div className="w-full h-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default function AddProductSettings() {
  const location = useLocation();
  const navigate = useNavigate();
  /*   const layout = useLayout(); */

  const [loading, setLoading] = useState(false);
  const [salesman, setSalesman] = useState([]);
  const [withdraw, setWithdraw] = useState({
    staff: { name: "" },
    comments: "",
    shiftId: "",
  });

  useEffect(() => {
    if (!location.state.shiftId) {
      navigate("/daily/gym");
    }

    setWithdraw({
      ...withdraw,
      shiftId: location.state.shiftId,
    });
    // eslint-disable-next-line
  }, [location]);

  // Get the salesman
  useEffect(() => {
    axios.get(`${config.url}/users/showall`).then(({ data }) => {
      let salesman = data.map((user) => {
        return {
          ...user,
          label: user.name,
        };
      });
      console.log(salesman);
      setSalesman(salesman);
    });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    axios
      .post(
        `${config.url}/daily/new-comment`,
        {
          staff: withdraw.staff._id,
          comments: withdraw.comments,
          shiftId: withdraw.shiftId,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setLoading(false);
        navigate(-1);
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);

        console.log(err);
      });
  };

  const handleStaff = (e, v) => {
    setWithdraw({
      ...withdraw,
      staff: v,
    });
  };

  return (
    <>
      {loading && <Spinner />}

      <div
        className="relative h-full flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
        style={{ backgroundColor: "rgb(17, 24, 39)" }}
      >
        <div
          className="rounded-t  mb-0 py-6 "
          style={{ backgroundColor: "rgb(17, 24, 39)", paddingLeft: 40 }}
        >
          <div className="text-center flex items-center">
            <AddShoppingCartIcon sx={{ marginRight: 2, color: "#ccc" }} />
            <h6 className="text-white text-xl">Agregar comentario </h6>
          </div>
        </div>

        <div className="px-4 lg:px-10 pt-0">
          <form>
            <div className="flex  pt-2">
              <div className="flex items-center">
                <PersonSearchIcon
                  sx={{
                    color: "#fff",
                    fontSize: 29,
                    marginRight: 3,
                    transform: "translate(18px, 0)",
                  }}
                />
              </div>
              <div className="w-1/2  px-4">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el staff
                  </label>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Selecciona el staff"
                      />
                    )}
                    clearIcon={false}
                    options={salesman}
                    value={withdraw.staff}
                    inputValue={withdraw.staff.name}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option._id}>
                          {option.name}
                        </li>
                      );
                    }}
                    sx={{
                      padding: 0,
                      border: "none",
                      marginRight: 2,
                      backgroundColor: "#cbd5e1",
                    }}
                    onChange={handleStaff}
                    getOptionLabel={(option) => option.name}
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-1/2  px-4">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa comentarios u observaciones
                  </label>
                  <TextField
                    sx={{
                      padding: 0,
                      border: "none",
                      marginRight: 2,
                      backgroundColor: "#cbd5e1",
                    }}
                    value={withdraw.comments}
                    onChange={({ target }) => {
                      setWithdraw({ ...withdraw, comments: target.value });
                    }}
                    placeholder="Observaciones"
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            <hr className="mt-6 mb-6 border-b-1 border-blueGray-300" />
          </form>
        </div>

        <div
          className="flex-auto px-4 lg:px-10 pt-0"
          style={{ backgroundColor: "rgb(17, 24, 39)" }}
        >
          <form>
            <div className="w-full" style={{ transform: "translateX(76px)" }}>
              <button
                onClick={handleSubmit}
                style={{ height: 50, width: "46%" }}
                className=" bg-lightBlue-500  text-white active:bg-lightBlue-600 font-bold uppercase text-xs rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
                Publicar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
