export const files = [
  { filename: "basico1-cubillos.pdf", title: "Básico 1 - Exequiel Cubillos" },
  {
    filename: "basico1-patologia-cubillos.pdf",
    title: "Básico 1 Patología - Exequiel Cubillos",
  },
  { filename: "basico2-cubillos.pdf", title: "Básico 2 - Exequiel Cubillos" },
  { filename: "basico3-cubillos.pdf", title: "Básico 3 - Exequiel Cubillos" },
  { filename: "basico4-cubillos.pdf", title: "Básico 4 - Exequiel Cubillos" },
  { filename: "basico5-cubillos.pdf", title: "Básico 5 - Exequiel Cubillos" },
  { filename: "BASICO-1-LUIS-CELIS.pdf", title: "Básico 1 - Luis Isaias" },
  {
    filename: "BASICO-2-LUIS-CELIS.pdf",
    title: "Básico 2 - Luis Isaias",
  },
  { filename: "BASICO-3-LUIS-CELIS.pdf", title: "Básico 3 - Luis Isaias" },
  { filename: "BASICO-4-LUIS-CELIS.pdf", title: "Básico 4 - Luis Isaias" },
];

export const files2 = [
  { filename: "BASICO-1-LUIS-CELIS", title: "Básico 1 - Luis Isaias" },
  {
    filename: "BASICO-2-LUIS CELIS",
    title: "Básico 2 - Luis Isaias",
  },
  { filename: "BASICO-3-LUIS CELIS", title: "Básico 3 - Luis Isaias" },
  { filename: "BASICO-4-LUIS CELIS", title: "Básico 4 - Luis Isaias" },
];
