import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { Button } from "@mui/material";

export default function CardTable({
  rounded = false,
  bgColor = null,
  color,
  data,
  title = null,
  headers = null,
  add,
  children,
  routeadd,
  query = null,
  setQuery = null,
  filterComponent,
  filterPlaceholder = null,
}) {
  return (
    <>
      <CardWrapper color={color} bgColor={bgColor} rounded={rounded}>
        {title && (
          <CardTitle
            color={color}
            title={title}
            add={add}
            route={routeadd}
            filterComponent={filterComponent}
            query={query}
            setQuery={setQuery}
            filterPlaceholder={filterPlaceholder}
          />
        )}
        <TableContainer>
          <TableHeaders color={color} headers={headers} />
          <tbody>{children}</tbody>
        </TableContainer>
      </CardWrapper>
    </>
  );
}

export const TableDataItem = ({
  children,
  clickable = false,
  onClick,
  sx = {},
}) => {
  return (
    <td
      className=" border-l-0 border-r-0 text-xs whitespace-nowrap px-3 py-2"
      style={{
        cursor: clickable ? "pointer" : "unset",
        fontSize: 14,
        borderBottom: ".5px dotted #64748b",
        borderTop: ".5px dotted #64748b",
        textAlign: "center",

        ...sx,
      }}
      onClick={onClick}
    >
      {children}
    </td>
  );
};

const TableHeaders = ({ color, headers }) => {
  return (
    <thead>
      <tr>
        {!headers && <DefaultHeaders color={color} />}
        {headers &&
          headers.map((header, idx) => {
            return (
              <th
                key={idx}
                className={
                  `  py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold ${
                    idx === 0 ? "px-4" : "text-center"
                  } ` +
                  (color === "light"
                    ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                    : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                }
              >
                {header}
              </th>
            );
          })}
      </tr>
    </thead>
  );
};

const TableContainer = ({ children }) => {
  return (
    <div className="block w-full overflow-x-auto">
      <table className="items-center w-full bg-transparent border-collapse">
        {children}{" "}
      </table>
    </div>
  );
};

const CardTitle = ({
  color,
  title,
  add,
  route,
  filterComponent,
  filterPlaceholder,
  query,
  setQuery,
}) => {
  const navigate = useNavigate();

  return (
    <div className=" mb-0  border-0">
      <div className="flex flex-wrap items-center">
        <div
          className="relative w-full px-4 max-w-full flex-row"
          style={{ display: "flex", alignItems: "center" }}
        >
          <h3
            style={{ marginRight: "auto" }}
            className={
              "font-semibold text-base py-3 " +
              (color === "light" ? "text-blueGray-700" : "text-white")
            }
          >
            {title}
          </h3>
          {filterComponent && (
            <input
              type={"text"}
              style={{
                borderRadius: 5,
                marginRight: 10,
                height: 36,
                width: 300,
                color: "#000",
              }}
              placeholder={filterPlaceholder}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            />
          )}

          {add && (
            <Button
              sx={{ height: 35, textTransform: "capitalize" }}
              onClick={() => navigate(route)}
              variant="contained"
              startIcon={<AddIcon />}
            >
              {add}
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

const CardWrapper = ({ children, color, bgColor, rounded }) => {
  return (
    <div
      className={
        "relative flex flex-col  break-words w-full mb-6 pb-12 shadow-lg " +
        (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
      }
      style={{
        backgroundColor: bgColor ? bgColor : "rgb(17, 24, 39)",
        borderRadius: rounded ? 8 : 0,
      }}
    >
      {children}
    </div>
  );
};

const DefaultHeaders = ({ color }) => {
  return (
    <>
      <th
        className={
          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
          (color === "light"
            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
        }
      >
        Product Name
      </th>
      <th
        className={
          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
          (color === "light"
            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
        }
      >
        Description
      </th>
      <th
        className={
          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
          (color === "light"
            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
        }
      >
        Category
      </th>
      <th
        className={
          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
          (color === "light"
            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
        }
      >
        Retail price
      </th>
      <th
        className={
          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
          (color === "light"
            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
        }
      >
        Wholesale price
      </th>
      <th
        className={
          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
          (color === "light"
            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
        }
      >
        Special price
      </th>
      <th
        className={
          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
          (color === "light"
            ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
            : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
        }
      >
        Warehouse
      </th>
    </>
  );
};

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
