import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import Sidebar from "components/Sidebar/Sidebar";
import { Spinner } from "components/Spinner";
import { useLayout } from "context/layout/layout";
import React, { useEffect, useState } from "react";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import config from "services/config";
import { useNavigate } from "react-router-dom";
import { defaultStat } from "utils/state";

import "./index.css";

export const NewProgress = () => {
  return (
    <>
      <Sidebar />
      <Wrapper>
        <AddProductSettings />
      </Wrapper>
    </>
  );
};

const Wrapper = ({ children }) => {
  return (
    <div className="relative h-full" id="progress-container">
      <div className="relative h-full" style={{ paddingBottom: 95 }}></div>
      <div className=" mx-auto w-full -m-24" style={{ height: "100vh" }}>
        <div className="flex flex-wrap h-full">
          <div className="w-full h-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default function AddProductSettings() {
  const layout = useLayout();

  const [loading, setLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const [memberValue, setMemberValue] = useState(null);
  const [email, newEmail] = useState(null);

  const [stat, setStat] = useState(defaultStat);

  useEffect(() => {
    //(this.body_fat_omrom * this.weight) / 100 > kg_fat
    //this.weight - this.kg_fat > muscle_fat_free

    if (stat.weight > 0 && stat.body_fat_omrom > 0) {
      let kgFat = (stat.body_fat_omrom * stat.weight) / 100;
      setStat({
        ...stat,
        kg_fat: kgFat,
        muscle_fat_free: stat.weight - kgFat,
      });
    }
  }, [stat.weight, stat.body_fat_omrom]);

  useEffect(() => {
    if (memberValue) {
      newEmail(memberValue.email);
    }
  }, [memberValue]);

  // Get the members
  useEffect(() => {
    axios
      .get(`${config.url}/members/showall`)
      .then((res) => {
        setMembers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    if (!email) {
      layout.errorAlert({ message: `Debes ingresar un correo para el alumno` });
      return;
    }

    if (!memberValue) {
      layout.errorAlert({ message: `Debe ingresar un alumno` });
      return;
    }

    if (Object.values(stat).some((v) => v === 0)) {
      layout.errorAlert({ message: `Debes completar todos los campos` });
      return;
    }

    setLoading(true);

    try {
      let document = {
        memberId: memberValue._id,
        email: email,
        stat: {
          ...stat,
        },
      };

      await axios.post(
        `${config.url}/progress/new`,
        { ...document },
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );

      layout.successAlert({ message: `Evaluacion creada exitosamente` });
      setLoading(false);
    } catch (err) {
      console.log(err);
      layout.errorAlert({ message: `Error al guardar la evaluación` });

      setLoading(false);
    }
  };

  return (
    <>
      {loading && <Spinner />}

      <div
        className="relative h-full flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
        style={{ backgroundColor: "rgb(17, 24, 39)" }}
      >
        <div
          className="rounded-t  mb-0 py-6 "
          style={{ backgroundColor: "rgb(17, 24, 39)", paddingLeft: 40 }}
        >
          <div className="text-center flex items-center">
            <AddShoppingCartIcon sx={{ marginRight: 2, color: "#ccc" }} />
            <h6 className="text-white text-xl">
              Evaluacíon Standard Hombre Temple Legacy Gym
            </h6>
          </div>
        </div>

        <div
          className="flex-auto px-4 lg:px-10 py-10 pt-0"
          style={{ backgroundColor: "rgb(17, 24, 39)" }}
        >
          <form>
            <div className="flex flex-wrap pt-6">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el alumno
                  </label>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Selecciona un alumno"
                      />
                    )}
                    clearIcon={false}
                    options={members}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option._id}>
                          {option.id_number} {option.name}
                        </li>
                      );
                    }}
                    disablePortal
                    sx={{
                      padding: 0,
                      border: "none",
                      marginRight: 2,
                      backgroundColor: "#cbd5e1",
                    }}
                    onChange={(e, v) => setMemberValue(v)}
                    getOptionLabel={(option) =>
                      option.id_number || "Default Label"
                    }
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              {memberValue && (
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-6">
                    <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                      Ingresa correo alumno
                    </label>
                    <TextField
                      placeholder="Correo ingresado"
                      value={email}
                      onChange={({ target }) => newEmail(target.value)}
                      sx={{
                        padding: 0,
                        marginRight: 2,
                        border: "none",
                        background: "#cbd5e1",
                      }}
                      variant="outlined"
                      type="text"
                      className="rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className="flex flex-wrap pt-6">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Peso (KG)
                  </label>
                  <TextField
                    placeholder="Peso del alumno (KG)"
                    sx={{
                      padding: 0,
                      marginRight: 2,
                      border: "none",
                      background: "#cbd5e1",
                    }}
                    value={stat.weight}
                    onChange={(e) =>
                      setStat({ ...stat, weight: e.target.value })
                    }
                    variant="outlined"
                    type="number"
                    className="rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Pectoral (CM)
                  </label>
                  <TextField
                    value={stat.pec}
                    placeholder="Pectoral del alumno (cm)"
                    sx={{
                      padding: 0,
                      marginRight: 2,
                      border: "none",
                      background: "#cbd5e1",
                    }}
                    onChange={(e) => setStat({ ...stat, pec: e.target.value })}
                    variant="outlined"
                    type="number"
                    className="rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Biceps (CM)
                  </label>
                  <TextField
                    value={stat.bicep}
                    placeholder="Biceps del alumno (cm)"
                    sx={{
                      padding: 0,
                      marginRight: 2,
                      border: "none",
                      background: "#cbd5e1",
                    }}
                    onChange={(e) =>
                      setStat({ ...stat, bicep: e.target.value })
                    }
                    variant="outlined"
                    type="number"
                    className="rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Cintura (CM)
                  </label>
                  <TextField
                    value={stat.waist}
                    placeholder="Cintura del alumno (cm)"
                    sx={{
                      padding: 0,
                      marginRight: 2,
                      border: "none",
                      background: "#cbd5e1",
                    }}
                    onChange={(e) =>
                      setStat({ ...stat, waist: e.target.value })
                    }
                    variant="outlined"
                    type="number"
                    className="rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Cadera (CM)
                  </label>
                  <TextField
                    value={stat.hips}
                    placeholder="Cadera del alumno (cm)"
                    sx={{
                      padding: 0,
                      marginRight: 2,
                      border: "none",
                      background: "#cbd5e1",
                    }}
                    onChange={(e) => setStat({ ...stat, hips: e.target.value })}
                    variant="outlined"
                    type="number"
                    className="rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    % Graso Omrom
                  </label>
                  <TextField
                    value={stat.body_fat_omrom}
                    placeholder="% Graso Omrom"
                    sx={{
                      padding: 0,
                      marginRight: 2,
                      border: "none",
                      background: "#cbd5e1",
                    }}
                    onChange={(e) =>
                      setStat({ ...stat, body_fat_omrom: e.target.value })
                    }
                    variant="outlined"
                    type="number"
                    className="rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>IMC</label>
                  <TextField
                    value={stat.imc}
                    placeholder="IMC"
                    sx={{
                      padding: 0,
                      marginRight: 2,
                      border: "none",
                      background: "#cbd5e1",
                    }}
                    onChange={(e) => setStat({ ...stat, imc: e.target.value })}
                    variant="outlined"
                    type="number"
                    className="rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Kg de Grasa
                  </label>
                  <TextField
                    value={stat.kg_fat}
                    placeholder="Kg de Grasa"
                    sx={{
                      padding: 0,
                      marginRight: 2,
                      border: "none",
                      background: "#cbd5e1",
                    }}
                    variant="outlined"
                    type="number"
                    className="rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Masa Libre de Grasa
                  </label>
                  <TextField
                    value={stat.muscle_fat_free}
                    placeholder="Masa Libre de Grasa"
                    sx={{
                      padding: 0,
                      marginRight: 2,
                      border: "none",
                      background: "#cbd5e1",
                    }}
                    variant="outlined"
                    type="number"
                    className="rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            <button
              onClick={handleSubmit}
              style={{ height: 50, width: "200px", marginLeft: 25 }}
              className=" bg-lightBlue-800 mt-8 text-white active:bg-lightBlue-600 font-bold uppercase text-xs  py-2 rounded shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150"
              type="button"
            >
              Publicar
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
