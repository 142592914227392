import { TextField } from "@mui/material";
import Sidebar from "components/Sidebar/Sidebar";
import { Spinner } from "components/Spinner";
import { useLayout } from "context/layout/layout";
import { useStore } from "context/store/store";
import React, { useState } from "react";
import { REGISTER_PRODUCT } from "utils/state";

export const AddProduct = () => {
  return (
    <>
      <Sidebar />
      <Wrapper>
        <AddProductSettings />
      </Wrapper>
    </>
  );
};

const Wrapper = ({ children }) => {
  return (
    <div className="relative h-full" style={{ marginLeft: 200 }}>
      <div className="relative h-full" style={{ paddingBottom: 95 }}></div>
      <div className=" mx-auto w-full -m-24" style={{ height: "100vh" }}>
        <div className="flex flex-wrap h-full">
          <div className="w-full h-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default function AddProductSettings() {
  const layout = useLayout();
  const store = useStore();

  const [loading, setLoading] = useState(false);
  const [product, setProduct] = useState(REGISTER_PRODUCT);
  const [options /* setOptions */] = useState({
    name: "",
    values: [
      {
        idx: 0,
        name: "",
      },
    ],
  });

  /*   function handleOptions({ target: { value } }) {
    setOptions({
      ...options,
      name: value,
    });
  } */

  /*   function handleOption(e, idx) {
    let temp = options;
    temp.values[idx] = {
      idx: idx,
      name: e.target.value,
    };
    setOptions(temp);
  } */

  /*   function addoption() {
    setOptions({
      ...options,
      values: [
        ...options.values,
        {
          idx: options.values.length,
          name: "",
        },
      ],
    });
  } */

  /*   function deleteoption(idx) {
    let temp = options;
    let edited = temp.values.filter((row) => row.idx !== idx);
    setOptions({
      ...options,
      values: edited,
    });
  } */

  const handleSubmit = () => {
    console.log({ product });
    if (Object.values(product).some((v) => v === "")) {
      return layout.errorAlert({
        message: "Todos los campos son obligatorios",
      });
    }

    let nOptions;
    let nProduct = product;

    if (options.name) {
      nOptions = {
        name: options.name,
        values: options.values.map((v) => v.name),
      };

      nProduct = {
        ...product,
        options: nOptions,
      };
    }

    setLoading(true);
    store
      .createProduct(nProduct)
      .then((res) => {
        console.log({ res });
        layout.successAlert({
          message: "Producto creado con exito",
        });
        setProduct(REGISTER_PRODUCT);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        return layout.errorAlert({
          message: "Error al crear el producto",
        });
      });
  };

  return (
    <>
      {loading && <Spinner />}

      <div
        className="relative h-full flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
        style={{ backgroundColor: "rgb(17, 24, 39)" }}
      >
        <div
          className="rounded-t  mb-0 py-6 "
          style={{ backgroundColor: "rgb(17, 24, 39)", paddingLeft: 57 }}
        >
          <div className="text-center flex justify-between">
            <h6 className="text-white  text-xl font-bold">CREAR PRODUCTO</h6>
          </div>
        </div>
        <div className="flex-auto px-4 lg:px-10  pt-0">
          <form>
            <div className="flex flex-wrap ">
              <div className="w-full lg:w-6/12 px-4 py-2">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el nombre del producto
                  </label>
                  <TextField
                    placeholder="Nombre"
                    onChange={({ target: { value } }) =>
                      setProduct({ ...product, name: value })
                    }
                    value={product.name}
                    type="text"
                    sx={{ backgroundColor: "#cbd5e1" }}
                    className="border-0  placeholder-black text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4 py-2">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa la descripcion
                  </label>
                  <TextField
                    placeholder="Descripcion"
                    onChange={({ target: { value } }) =>
                      setProduct({ ...product, description: value })
                    }
                    value={product.description}
                    sx={{ backgroundColor: "#cbd5e1" }}
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4 py-2">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el codigo del producto
                  </label>
                  <TextField
                    placeholder="Código"
                    onChange={({ target: { value } }) =>
                      setProduct({ ...product, code: value })
                    }
                    type="text"
                    value={product.code}
                    sx={{ backgroundColor: "#cbd5e1" }}
                    className="border-0  placeholder-black text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4 py-2">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el stock limite
                  </label>
                  <TextField
                    placeholder="Stock limite"
                    value={product.limit_stock}
                    onChange={({ target: { value } }) =>
                      setProduct({ ...product, limit_stock: Number(value) })
                    }
                    sx={{ backgroundColor: "#cbd5e1" }}
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4 py-2">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa la categoria
                  </label>
                  <TextField
                    placeholder="Categoria"
                    value={product.category}
                    sx={{ backgroundColor: "#cbd5e1" }}
                    type="text"
                    onChange={({ target: { value } }) =>
                      setProduct({ ...product, category: value })
                    }
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>

              {/*       <div className="w-full lg:w-6/12 px-4 py-2">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el stock
                  </label>
                  <TextField
                    label="Inventario"
                    onChange={({ target: { value } }) =>
                      setProduct({ ...product, inventory: Number(value) })
                    }
                    sx={{ backgroundColor: "#cbd5e1" }}
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div> */}
              <div className="w-full lg:w-6/12 px-4 py-2">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el precio retail
                  </label>
                  <TextField
                    placeholder="Precio retail"
                    value={product.prices.retail}
                    onChange={({ target: { value } }) =>
                      setProduct({
                        ...product,
                        prices: {
                          ...product.prices,
                          retail: Number(value),
                        },
                      })
                    }
                    sx={{ backgroundColor: "#cbd5e1" }}
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              {/*          <div className="w-full lg:w-6/12 px-4 py-2">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el nombre y apellidos del alumno
                  </label>
                  <TextField
                    sx={{ backgroundColor: "#cbd5e1" }}
                    label="Precio mayorista"
                    onChange={({ target: { value } }) =>
                      setProduct({
                        ...product,
                        prices: {
                          ...product.prices,
                          wholesale: Number(value),
                        },
                      })
                    }
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div> */}
              {/*          <div className="w-full lg:w-6/12 px-4 py-2">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el nombre y apellidos del alumno
                  </label>
                  <TextField
                    label="    Precio Especial"
                    onChange={({ target: { value } }) =>
                      setProduct({
                        ...product,
                        prices: {
                          ...product.prices,
                          special: Number(value),
                        },
                      })
                    }
                    sx={{ backgroundColor: "#cbd5e1" }}
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div> */}
              <div className="w-full lg:w-6/12 px-4 py-2">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el precio para el staff
                  </label>
                  <TextField
                    placeholder="Precio Staff"
                    value={product.prices.staff}
                    onChange={({ target: { value } }) =>
                      setProduct({
                        ...product,
                        prices: {
                          ...product.prices,
                          staff: Number(value),
                        },
                      })
                    }
                    sx={{ backgroundColor: "#cbd5e1" }}
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div
                className="my-6 w-full"
                style={{ transform: "translateX(23px)" }}
              >
                <button
                  onClick={handleSubmit}
                  style={{ height: 50, width: "46%" }}
                  className=" bg-lightBlue-500  text-white active:bg-lightBlue-600 font-bold uppercase text-xs rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                  type="button"
                >
                  Publicar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
