import { TextField } from "@mui/material";
import Sidebar from "components/Sidebar/Sidebar";
import { Spinner } from "components/Spinner";
import { useLayout } from "context/layout/layout";
import { useStore } from "context/store/store";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import config from "services/config";
import { defaultMessage } from "utils/state";

export const MessageDetail = () => {
  return (
    <>
      <Sidebar />
      <Wrapper>
        <AddUserSettings />
      </Wrapper>
    </>
  );
};
const Wrapper = ({ children }) => {
  return (
    <div className="relative h-full" style={{ marginLeft: 200 }}>
      <div className="relative h-full" style={{ paddingBottom: 95 }}></div>
      <div className=" mx-auto w-full -m-24" style={{ height: "100vh" }}>
        <div className="flex flex-wrap h-full">
          <div className="w-full h-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

function AddUserSettings() {
  const params = useParams();
  const layout = useLayout();
  const store = useStore();
  const [loading, setLoading] = React.useState(false);
  const [message, setMessage] = React.useState(defaultMessage);

  useEffect(() => {
    console.log(params._id);
    fetch(`${config.url}/messages/find`, {
      method: "POST",
      body: JSON.stringify({ _id: params._id }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      res.json().then((response) => {
        setMessage({
          title: response.title,
          subtitle: response.subtitle,
          url: response.url,
        });
      });
    });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    store
      .createMessage(message)
      .then((res) => {
        setLoading(false);
        setMessage(defaultMessage);
        layout.successAlert({ message: "Mensaje creado con exito" });
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      {loading && <Spinner />}

      <div
        className="relative h-full flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
        style={{ backgroundColor: "rgb(17, 24, 39)" }}
      >
        <div
          className="rounded-t bg-white mb-0 py-6 "
          style={{ backgroundColor: "rgb(17, 24, 39)", paddingLeft: 57 }}
        >
          <div className="text-center flex justify-between">
            <h6 className="text-white text-xl font-bold">Agregar Mensaje</h6>
          </div>
        </div>

        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <div className="flex flex-wrap pt-6">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Titulo del mensaje
                  </label>
                  <TextField
                    className="border-0 px-3 py-3 placeholder-black text-blueGray-600  rounded text-sm shadow  w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    placeholder="Titulo"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={message.title}
                    onChange={(e) =>
                      setMessage({
                        ...message,
                        title: e.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    URL del mensaje
                  </label>
                  <TextField
                    className="border-0 px-3 py-3 placeholder-black text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    placeholder="URL"
                    variant="outlined"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={message.url}
                    onChange={(e) => {
                      setMessage({
                        ...message,
                        url: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Descripcion del mensaje
                  </label>
                  <TextField
                    className="border-0 px-3 py-3 placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    placeholder="Descripcion"
                    multiline
                    rows={4}
                    variant="outlined"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={message.subtitle}
                    onChange={(e) =>
                      setMessage({
                        ...message,
                        subtitle: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
            </div>

            <button
              onClick={handleSubmit}
              style={{ height: 50, width: "47.5%", marginLeft: 15 }}
              className=" bg-lightBlue-500 mt-8 text-white active:bg-lightBlue-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
              type="button"
            >
              Publicar
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
