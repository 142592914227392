import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import Sidebar from "components/Sidebar/Sidebar";
import { Spinner } from "components/Spinner";
import { useLayout } from "context/layout/layout";
import { useStore } from "context/store/store";
import React, { useEffect } from "react";
import { useState } from "react";
import config from "services/config";
import { defaultMember } from "utils/state";

export const AddMember = () => {
  return (
    <>
      <Sidebar />
      <Wrapper>
        <AddUserSettings />
      </Wrapper>
    </>
  );
};

const Wrapper = ({ children }) => {
  return (
    <div className="relative h-full" style={{ marginLeft: 200 }}>
      <div className="relative h-full" style={{ paddingBottom: 95 }}></div>
      <div className=" mx-auto w-full -m-24" style={{ height: "100vh" }}>
        <div className="flex flex-wrap h-full">
          <div className="w-full h-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

function AddUserSettings() {
  const layout = useLayout();
  const store = useStore();
  const [loading, setLoading] = React.useState(false);
  const [user, setUser] = React.useState(defaultMember);
  const [memberships, setMemberships] = useState([]);

  useEffect(() => {
    console.log({ user });
  }, [user]);

  /**
   *
   * Get the membership list
   */
  useEffect(() => {
    axios
      .get(`${config.url}/membership/list`)
      .then(({ data }) => {
        setMemberships(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = () => {
    setLoading(true);
    store
      .createMember(user)
      .then((res) => {
        setLoading(false);
        setUser(defaultMember);
        layout.successAlert({ message: "Usuario creado con exito" });
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        layout.errorAlert({ message: "Error al crear el alumno" });
        return;
      });
  };

  return (
    <>
      {loading && <Spinner />}

      <div
        className="relative h-full flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
        style={{ backgroundColor: "rgb(17, 24, 39)" }}
      >
        <div
          className="rounded-t  mb-0 py-6 "
          style={{ backgroundColor: "rgb(17, 24, 39)", paddingLeft: 57 }}
        >
          <div className="text-center flex justify-between ">
            <h6 className="text-white  text-xl font-bold">
              INSCRIPCIÓN NUEVO ALUMNO
            </h6>
          </div>
        </div>

        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <div className="flex flex-wrap pt-6">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-8">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el nombre y apellidos del alumno
                  </label>

                  <TextField
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow  w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={user.name}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el correo
                  </label>

                  <TextField
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={user.email}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-8">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Fecha de nacimiento
                  </label>
                  <TextField
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Fecha de nacimiento"
                    type={"date"}
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={user.birthday}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        birthday: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-8">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el rut
                  </label>
                  <TextField
                    className="border-0   placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    label="Rut"
                    variant="outlined"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={user.id_number}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        id_number: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-8">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el numero de teléfono celular
                  </label>
                  <TextField
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={user.phone}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        phone: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-8">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Selecciona el plazo del plan de elección
                  </label>
                  <Autocomplete
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="combo-box-demo"
                    placeholder="Plan"
                    options={memberships}
                    onChange={(e, value) =>
                      setUser({
                        ...user,
                        subscription: {
                          ...user.subscription,
                          type: value.title,
                          membership: value._id,
                        },
                      })
                    }
                    sx={{
                      padding: 0,
                      border: "unset",
                      backgroundColor: "#cbd5e1",
                    }}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Plan socio" />
                    )}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-8">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Fecha de inscripcion
                  </label>

                  <TextField
                    type="date"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={user.subscription.startDate}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        subscription: {
                          ...user.subscription,
                          startDate: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-8">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Sucursal
                  </label>
                  <Autocomplete
                    className="border-0  placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="combo-box-demo"
                    placeholder="Plan"
                    options={[
                      { label: "Temple Gym Malloco" },
                      { label: "Temple Gym Talagante" },
                    ]}
                    value={user.campus}
                    onChange={(e, value) =>
                      setUser({
                        ...user,
                        campus: value,
                      })
                    }
                    onInputChange={(e, value) => {
                      setUser({
                        ...user,
                        campus: value,
                      });
                    }}
                    inputValue={user.campus}
                    sx={{
                      padding: 0,
                      border: "unset",
                      backgroundColor: "#cbd5e1",
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </div>
            </div>

            <button
              onClick={handleSubmit}
              style={{ height: 45, width: "48%", marginLeft: 15 }}
              className=" bg-lightBlue-800 mt-8 text-white active:bg-lightBlue-600 font-bold uppercase text-xs  py-2 rounded shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150"
              type="button"
            >
              Publicar
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
