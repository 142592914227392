import React, { useEffect, useState } from "react";
import Sidebar from "components/Sidebar/Sidebar.js";
import CardTable from "components/Cards/CardTable";
import axios from "axios";
import { TableDataItem } from "components/Cards/CardTable";
/* import { useStore } from "../../context/store/store";
 */ import { Spinner } from "components/Spinner";
/* import { useStore } from "context/store/store";
 */ /* import VisibilityIcon from "@mui/icons-material/Visibility";
 */ import config from "services/config";
import { useAuth } from "context/auth/auth";
import { IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useStore } from "context/store/store";
import { useNavigate } from "react-router-dom";

const PRODUCT_HEADERS = ["Titulo", "Url"];

const ACTIONS = "Acciones";

const ADMIN_HEADERS = [...PRODUCT_HEADERS, ACTIONS];

export function MessagesList({ color = null }) {
  const navigate = useNavigate();
  const store = useStore();
  const auth = useAuth();
  const [products, setProductos] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    axios
      .get(`${config.url}/messages/showall`)
      .then((res) => {
        setProductos(res.data.messages);
        console.log(res.data.messages);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleDelete = async ({ message_id }) => {
    setLoading(true);
    try {
      await store.deleteMessage({ message_id });
      let newproducts = products.filter((p) => p._id !== message_id);
      setLoading(false);
      setProductos(newproducts);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  };

  const seeDetails = ({ message_id, message }) => {
    navigate(`/messages/view/${message_id}`);
  };

  return (
    <>
      {loading && <Spinner />}
      <Sidebar />
      <div className="relative" style={{ marginLeft: 200 }}>
        <div className="relative " style={{ paddingBottom: 95 }}></div>
        <div className=" mx-auto w-full -m-24">
          <div className="flex flex-wrap">
            <div className="w-full  h-full">
              <CardTable
                title={"Lista de mensajes"}
                headers={auth.isAdmin ? ADMIN_HEADERS : PRODUCT_HEADERS}
                add="Agregar mensaje"
                routeadd="/messages/add"
                color={"dark"}
              >
                {products &&
                  products.length > 0 &&
                  products.map((product, idx) => {
                    return (
                      <tr>
                        <TableDataItem
                          sx={{
                            fontWeight: 600,
                            textAlign: "left",
                            transform: "translateX(25px)",
                          }}
                        >
                          {product.title}
                        </TableDataItem>

                        <TableDataItem>{product.url}</TableDataItem>

                        {auth.isAdmin && (
                          <TableDataItem>
                            <IconButton
                              onClick={() =>
                                handleDelete({ message_id: product._id })
                              }
                            >
                              <DeleteIcon sx={{ color: "#b91c1c" }} />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                seeDetails({
                                  message_id: product._id,
                                  message: product,
                                })
                              }
                            >
                              <VisibilityIcon sx={{ color: "#ccc" }} />
                            </IconButton>
                          </TableDataItem>
                        )}
                      </tr>
                    );
                  })}
              </CardTable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
