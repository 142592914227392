import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import Sidebar from "components/Sidebar/Sidebar";
import { Spinner } from "components/Spinner";
import { useLayout } from "context/layout/layout";
import { useStore } from "context/store/store";
import React, { useEffect, useState } from "react";
import { prices } from "utils/state";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import { formatCLP } from "utils";
import config from "services/config";
import { useLocation, useNavigate } from "react-router-dom";
import { PAYMENTS } from "utils/state";

/* const TYPES_OF_CLIENT = [
  {
    name: "Alumno",
    code: "member",
  },
  {
    name: "Retail",
    code: "retail",
  },
]; */

export const SaleDetail = () => {
  return (
    <>
      <Sidebar />
      <Wrapper>
        <AddProductSettings />
      </Wrapper>
    </>
  );
};

const Wrapper = ({ children }) => {
  return (
    <div className="relative h-full" style={{ marginLeft: 200 }}>
      <div className="relative h-full" style={{ paddingBottom: 95 }}></div>
      <div className=" mx-auto w-full -m-24" style={{ height: "100vh" }}>
        <div className="flex flex-wrap h-full">
          <div className="w-full h-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default function AddProductSettings() {
  const location = useLocation();
  const navigate = useNavigate();
  const layout = useLayout();
  const store = useStore();

  const [loading, setLoading] = useState(false);
  const [, /* members */ setMembers] = useState([]);
  const [salesman, setSalesman] = useState([]);
  const [products, setProducts] = useState([]);
  const [salesmanId, setSalesmanId] = useState({ name: "" });
  const [productlist, setProductList] = useState([]);
  const [, /* retail */ setRetail] = useState(null);

  const [memberValue /* setMemberValue */] = useState("");

  const [retailValue /* setRetailValue */] = useState(null);

  const [detail, setDetail] = useState(null);

  const [payment, setPayment] = useState(PAYMENTS[0]);
  const [sale, setSale] = useState({
    totalAmount: 0,
    totalProducts: 0,
  });

  function getPaymentObj(payment) {
    let obj = PAYMENTS.find((p) => p.code === payment) || null;
    if (obj) {
      return obj;
    }
    return PAYMENTS[0];
  }

  useEffect(() => {
    let detail = location.state.detail;
    if (!detail) {
      return navigate(-1);
    }

    if (detail) {
      setDetail(detail);
      let payment_ = getPaymentObj(detail.payment);
      setSalesmanId(detail.salesman);
      setPayment(payment_);
      if (detail.products) {
        console.log({ detail });
        let newProductList = detail.products.map((productItem) => {
          let selectedPrice;

          for (const [key, value] of Object.entries(
            productItem.product.prices
          )) {
            if (productItem.price === value) {
              selectedPrice = {
                label: prices[key],
                price: value,
                priceType: key,
              };
            }
          }

          return {
            ...productItem.product,
            label: productItem._id,
            qty: productItem.quantity,
            selectedprice: selectedPrice,
            totalAmount: productItem.quantity * productItem.price,
          };
        });

        console.log(newProductList[0]);

        setProductList(newProductList);
      }
      console.log(detail);
    }
    // eslint-disable-next-line
  }, [location]);

  // Get the salesman
  useEffect(() => {
    axios.get(`${config.url}/users/list/salesman`).then(({ data }) => {
      let salesman = data.map((user) => {
        return {
          ...user,
          label: user.name,
        };
      });
      console.log(salesman);
      setSalesman(salesman);
    });
    // eslint-disable-next-line
  }, []);

  // Get the products
  useEffect(() => {
    axios.get(`${config.url}/products/showall`).then(({ data }) => {
      let products = data.products.map((prod) => {
        return {
          ...prod,
          label: prod._id,
        };
      });
      console.log(products);
      setProducts(products);
    });
    // eslint-disable-next-line
  }, []);

  // Get the members
  useEffect(() => {
    axios
      .get(`${config.url}/members/showall`)
      .then((res) => {
        setMembers(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  // Get the retail clients
  useEffect(() => {
    axios
      .get(`${config.url}/costumer/list`)
      .then((res) => {
        console.log({ res: res.data });
        setRetail(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (productlist.length > 0) {
      let amount = 0;
      let items = 0;
      for (let sale of productlist) {
        if (sale.qty && sale.totalAmount) {
          amount = amount + sale.totalAmount;
          items = items + sale.qty;
        }
      }

      setSale({
        totalAmount: detail.totalPrice,
        totalProducts: items,
      });
    }
    // eslint-disable-next-line
  }, [productlist]);

  function resetSaleState() {
    setProductList([]);
    setSale({
      totalAmount: 0,
      totalProducts: 0,
    });
  }

  function handleOptions(evt, value) {
    if (!value) {
      setProductList([]);
      return;
    }

    setProductList([value]);
  }

  function handleOption(value, idx) {
    console.log("value", value);
    let temp = [...productlist];
    let len = temp.length - 1;

    if (idx === len && !value) {
      temp.pop();
    }

    if (idx > len && value) {
      temp = [...temp, value];
    }

    if (idx === len && value) {
      temp[temp.length - 1] = value;
    }

    setProductList(temp);
  }

  function deleteoption(id) {
    let temp = [...productlist];
    let edited = temp.filter((prod) => prod._id !== id);
    setProductList(edited);
  }

  const getPricesOptions = (index = null) => {
    let options = [];
    if (productlist.length < 1) {
      return [];
    }

    if (!index) {
      for (let [key, value] of Object.entries(productlist[0].prices)) {
        if (value) {
          options = [
            ...options,
            { label: prices[key], price: value, priceType: key },
          ];
        }
      }

      return options;
    }

    if (index && productlist.length > index) {
      for (let [key, value] of Object.entries(productlist[index].prices)) {
        if (value) {
          options = [
            ...options,
            { label: prices[key], price: value, priceType: key },
          ];
        }
      }

      return options;
    }

    return [];
  };

  const handlePriceChange = (value, index = null) => {
    let templist = [...productlist];
    let qty = null;

    if (index) {
      qty = templist[index]?.qty;
      templist[index] = {
        ...templist[index],
        selectedprice: value,
        totalAmount: qty ? value.price * qty : "0.",
      };

      setProductList(templist);
      return;
    }

    qty = templist[0]?.qty;
    templist[0] = {
      ...templist[0],
      selectedprice: value,
      totalAmount: qty ? value.price * qty : "0.",
    };

    setProductList(templist);
  };

  const handleQuantity = (e = null, index = null) => {
    let templist = [...productlist];
    let newSale = { ...sale };
    let selectedprice = null;
    let qty = null;

    if (index) {
      selectedprice = templist[index].selectedprice?.price;
      qty = Number(e.target.value);
      templist[index] = {
        ...templist[index],
        qty: qty,
        totalAmount: selectedprice * qty,
      };

      newSale.totalAmount = newSale.totalAmount + selectedprice * qty;
      newSale.totalProducts = newSale.totalProducts + qty;
      console.log(newSale);
      setSale(newSale);
      setProductList(templist);
      return;
    }

    selectedprice = templist[0].selectedprice?.price;
    qty = Number(e.target.value);
    templist[0] = {
      ...templist[0],
      qty: qty,
      totalAmount: selectedprice * qty,
    };

    newSale.totalAmount = newSale.totalAmount + selectedprice * qty;
    newSale.totalProducts = newSale.totalProducts + qty;
    console.log(newSale);
    setSale(newSale);
    setProductList(templist);
  };

  const handleSubmit = async () => {
    setLoading(true);
    let selectedProducts = productlist.map((item) => ({
      productId: item._id,
      quantity: item.qty,
      priceType: item.selectedprice.priceType,
    }));

    console.log(retailValue);
    console.log(memberValue);

    let document = {
      selectedProducts,
      salesmanId: salesmanId._id,
      shiftId: location.state.shiftId,
      payment: payment.code,
      costumerId: retailValue && retailValue._id,
      memberId: memberValue && memberValue._id,
    };

    console.log({ document });
    setLoading(true);
    store
      .registerSale(document)
      .then((res) => {
        setLoading(false);
        console.log(res);
        resetSaleState();
        layout.successAlert({ message: `Venta creada exitósamente` });
      })
      .catch((err) => {
        setLoading(false);
        layout.errorAlert({ message: `Error en el servicio: ${err}` });
        resetSaleState();
        console.log(err);
      });
  };
  /* 
  const handleClienType = (e, v) => {
    if (v.code === "member") {
      setRetailValue(null);
      setRetailSelected(null);
      setClientType(v);
      return;
    }

    if (v.code === "retail") {
      setMemberSelected(null);
      setMemberValue(null);
      setClientType(v);
      return;
    }
  }; */

  return (
    <>
      {loading && <Spinner />}

      <div
        className="relative h-full flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
        style={{ backgroundColor: "rgb(17, 24, 39)" }}
      >
        <div
          className="rounded-t  mb-0 py-6 "
          style={{ backgroundColor: "rgb(17, 24, 39)", paddingLeft: 40 }}
        >
          <div className="text-center flex items-center">
            <AddShoppingCartIcon sx={{ marginRight: 2, color: "#ccc" }} />
            <h6 className="text-white text-xl">Ingresar nueva venta</h6>
          </div>
        </div>

        <div className="px-4 lg:px-10 pt-0">
          <form>
            <div className="flex  pt-2">
              <div className="flex items-center">
                <PersonSearchIcon
                  sx={{
                    color: "#fff",
                    fontSize: 29,
                    marginRight: 3,
                    transform: "translate(18px, 0)",
                  }}
                />
              </div>
              <div className="w-full  px-4">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Nombre del vendedor
                  </label>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Selecciona un vendedor"
                      />
                    )}
                    disabled
                    clearIcon={false}
                    options={salesman}
                    value={salesmanId}
                    inputValue={salesmanId.name}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option._id}>
                          {option.name}
                        </li>
                      );
                    }}
                    sx={{
                      padding: 0,
                      border: "none",
                      marginRight: 2,
                      backgroundColor: "#cbd5e1",
                    }}
                    onChange={(e, value) => setSalesmanId(value)}
                    getOptionLabel={(option) => option.name}
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full  px-4">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Fecha del pedido
                  </label>
                  <TextField
                    disabled
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    sx={{
                      padding: 0,
                      border: "unset",
                      backgroundColor: "#cbd5e1",
                    }}
                    value={detail && new Date(detail.date).toLocaleDateString()}
                  />
                </div>
              </div>
              <div className="w-full  px-4">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Método de pago
                  </label>
                  <Autocomplete
                    disabled
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Selecciona un método de pago"
                      />
                    )}
                    clearIcon={false}
                    options={PAYMENTS}
                    value={payment}
                    inputValue={payment.name}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option._id}>
                          {option.name}
                        </li>
                      );
                    }}
                    sx={{
                      padding: 0,
                      border: "none",
                      marginRight: 2,
                      backgroundColor: "#cbd5e1",
                    }}
                    onChange={(e, value) => setPayment(value)}
                    getOptionLabel={(option) => option.name}
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            <div className="flex  pt-2">
              <div className="flex items-center">
                <PersonSearchIcon
                  sx={{
                    color: "#fff",
                    fontSize: 29,
                    marginRight: 3,
                    transform: "translate(18px, 0)",
                  }}
                />
              </div>

              {detail && detail.costumer && detail.costumer.name && (
                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                      Nombre del cliente
                    </label>
                    <TextField
                      disabled
                      sx={{
                        padding: 0,
                        border: "none",
                        marginRight: 2,
                        backgroundColor: "#cbd5e1",
                      }}
                      value={detail.customer.name}
                      type="text"
                      className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
              )}
              {detail && detail.costumer && detail.costumer.email && (
                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                      Correo del cliente
                    </label>
                    <TextField
                      disabled
                      sx={{
                        padding: 0,
                        border: "none",
                        marginRight: 2,
                        backgroundColor: "#cbd5e1",
                      }}
                      value={detail.costumer.email}
                      type="text"
                      className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
              )}

              {detail && detail.member && detail.member.name && (
                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                      Nombre del cliente
                    </label>
                    <TextField
                      disabled
                      sx={{
                        padding: 0,
                        border: "none",
                        marginRight: 2,
                        backgroundColor: "#cbd5e1",
                      }}
                      value={detail.member.name}
                      type="text"
                      className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
              )}
              {detail && detail.member && detail.member.email && (
                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                      Correo del cliente
                    </label>
                    <TextField
                      disabled
                      sx={{
                        padding: 0,
                        border: "none",
                        marginRight: 2,
                        backgroundColor: "#cbd5e1",
                      }}
                      value={detail.member.email}
                      type="text"
                      className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
              )}

              {detail && detail.costumer && detail.costumer.phone && (
                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                      Numero del cliente
                    </label>
                    <TextField
                      disabled
                      sx={{
                        padding: 0,
                        border: "none",
                        marginRight: 2,
                        backgroundColor: "#cbd5e1",
                      }}
                      value={detail.costumer.phone}
                      type="text"
                      className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
              )}
            </div>
            {detail && detail.costumer && detail.costumer.address && (
              <div className="flex  pt-2">
                <div className="flex items-center">
                  <PersonSearchIcon
                    sx={{
                      color: "#fff",
                      fontSize: 29,
                      marginRight: 3,
                      transform: "translate(18px, 0)",
                    }}
                  />
                </div>

                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                      Dirección del cliente
                    </label>
                    <TextField
                      disabled
                      sx={{
                        padding: 0,
                        border: "none",
                        marginRight: 2,
                        backgroundColor: "#cbd5e1",
                      }}
                      value={detail.costumer.address}
                      type="text"
                      className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                      Status del pedido
                    </label>
                    <TextField
                      disabled
                      sx={{
                        padding: 0,
                        border: "none",
                        marginRight: 2,
                        backgroundColor: "#cbd5e1",
                      }}
                      value={detail && detail.status}
                      type="text"
                      className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
              </div>
            )}

            <hr className="mt-6 mb-6 border-b-1 border-blueGray-300" />
          </form>
        </div>

        <div
          className="flex-auto px-4 lg:px-10 pt-0"
          style={{ backgroundColor: "rgb(17, 24, 39)" }}
        >
          <form>
            <div className="flex flex-wrap pt-10">
              <div className="w-full lg:w-12/12 px-0 ">
                <div
                  className="relative w-full mb-3"
                  style={{ display: "flex" }}
                >
                  <div
                    className="flex items-center"
                    style={{
                      width: 100,
                      gap: 20,
                      padding: "0 15px",
                    }}
                  ></div>

                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        disabled
                        {...params}
                        placeholder="Selecciona un producto"
                      />
                    )}
                    disabled
                    options={products}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option._id}>
                          {option.name}
                        </li>
                      );
                    }}
                    sx={{
                      padding: 0,
                      border: "unset",
                      marginRight: 2,
                      width: 600,
                      backgroundColor: "#cbd5e1",
                    }}
                    value={productlist.length > 0 ? productlist[0].name : ""}
                    inputValue={
                      productlist.length > 0 ? productlist[0].name : ""
                    }
                    onChange={handleOptions}
                    getOptionLabel={(option) => option.name}
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                  <Autocomplete
                    disabled
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Lista de precio" />
                    )}
                    options={getPricesOptions()}
                    sx={{
                      padding: 0,
                      border: "unset",
                      marginRight: 2,
                      width: 300,
                      backgroundColor: "#cbd5e1",
                    }}
                    inputValue={
                      productlist.length > 0 && productlist[0].selectedprice
                        ? productlist[0].selectedprice.label
                        : ""
                    }
                    value={
                      productlist.length > 0 && productlist[0].selectedprice
                        ? productlist[0].selectedprice.label
                        : ""
                    }
                    onChange={(evt, value) => handlePriceChange(value)}
                    type="text"
                    className="border-0  placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                  />
                  <TextField
                    disabled
                    value={
                      productlist.length > 0
                        ? productlist[0].selectedprice?.price
                        : ""
                    }
                    placeholder="Precio"
                    sx={{ marginRight: 2, backgroundColor: "#cbd5e1" }}
                    className=" border-0   text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-1/4 ease-linear transition-all duration-150 "
                  />
                  <TextField
                    disabled
                    type="number"
                    sx={{ marginRight: 2, backgroundColor: "#cbd5e1" }}
                    onChange={handleQuantity}
                    value={
                      productlist.length > 0 && productlist[0].qty
                        ? productlist[0].qty
                        : ""
                    }
                    className=" border-0  placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-1/4 ease-linear transition-all duration-150 "
                  />
                  <TextField
                    value={
                      productlist.length > 0 && productlist[0].totalAmount
                        ? `$ ${productlist[0].totalAmount}`
                        : "$0."
                    }
                    disabled
                    placeholder="Total"
                    sx={{ color: "#000", backgroundColor: "#cbd5e1" }}
                    className=" border-0  text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-1/4 ease-linear transition-all duration-150 "
                  />
                </div>
              </div>
              {productlist.map((option, idx) => {
                if (idx === productlist.length - 1) {
                  return null;
                }
                return (
                  <div className="w-full lg:w-12/12 px-0" key={idx}>
                    <div
                      className="relative w-full mb-3"
                      style={{ display: "flex" }}
                    >
                      <div
                        className="flex items-center"
                        style={{
                          width: 100,
                          gap: 20,
                          padding: "0 15px",
                        }}
                      >
                        {idx < productlist.length - 1 && (
                          <i
                            onClick={() =>
                              deleteoption(productlist[idx + 1]._id)
                            }
                            class="fas fa-trash"
                            style={{
                              fontSize: 19,
                              color: "#dc2626",
                              cursor: "pointer",
                            }}
                          ></i>
                        )}
                      </div>
                      <Autocomplete
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Selecciona un producto"
                          />
                        )}
                        options={products}
                        renderOption={(props, option) => {
                          return (
                            <li {...props} key={option._id}>
                              {option.name}
                            </li>
                          );
                        }}
                        getOptionLabel={(option) => option.name}
                        sx={{
                          padding: 0,
                          border: "unset",
                          marginRight: 2,
                          width: 600,
                          backgroundColor: "#cbd5e1",
                        }}
                        inputValue={
                          productlist.length > idx + 1 &&
                          productlist[idx + 1].name
                            ? productlist[idx + 1].name
                            : ""
                        }
                        value={
                          productlist.length > idx + 1 &&
                          productlist[idx + 1].name
                            ? productlist[idx + 1].name
                            : ""
                        }
                        onChange={(evt, value) => handleOption(value, idx + 1)}
                        type="text"
                        placeholder="Ej: Sabores"
                        className="border-0 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      />
                      <Autocomplete
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Lista de precios"
                          />
                        )}
                        options={getPricesOptions(idx + 1)}
                        sx={{
                          padding: 0,
                          border: "unset",
                          marginRight: 2,
                          width: 300,
                          backgroundColor: "#cbd5e1",
                        }}
                        onChange={(evt, value) =>
                          handlePriceChange(value, idx + 1)
                        }
                        inputValue={
                          productlist.length > idx + 1 &&
                          productlist[idx + 1].selectedprice
                            ? productlist[idx + 1].selectedprice.label
                            : ""
                        }
                        value={
                          productlist.length > idx + 1 &&
                          productlist[idx + 1].selectedprice
                            ? productlist[idx + 1].selectedprice.label
                            : ""
                        }
                        type="text"
                        placeholder="Ej: Sabores"
                        className="border-0   text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                      />
                      <TextField
                        disabled
                        value={
                          productlist.length > idx + 1 &&
                          productlist[idx + 1].selectedprice
                            ? productlist[idx + 1].selectedprice?.price
                            : ""
                        }
                        placeholder="Precio"
                        sx={{ marginRight: 2, backgroundColor: "#cbd5e1" }}
                        className=" border-0   text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-1/4 ease-linear transition-all duration-150 "
                      />
                      <TextField
                        type="number"
                        placeholder="Cantidad"
                        value={
                          productlist.length > idx + 1 &&
                          productlist[idx + 1].qty
                            ? productlist[idx + 1].qty
                            : ""
                        }
                        onChange={(e) => handleQuantity(e, idx + 1)}
                        sx={{ marginRight: 2, backgroundColor: "#cbd5e1" }}
                        className=" border-0   text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring w-1/4 ease-linear transition-all duration-150 "
                      />
                      <TextField
                        disabled
                        placeholder="Total"
                        value={
                          productlist.length > idx + 1 &&
                          productlist[idx + 1].totalAmount
                            ? `$ ${productlist[idx + 1].totalAmount}`
                            : "$0."
                        }
                        sx={{ color: "#000", backgroundColor: "#cbd5e1" }}
                        className=" border-0  placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-1/4 ease-linear transition-all duration-150 "
                      />
                    </div>
                  </div>
                );
              })}
            </div>
            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <div>
              <div
                style={{
                  width: "100%",
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div className="flex flex-row mt-4 w-full items-center">
                  <ShoppingCartIcon sx={{ color: "#ccc", marginRight: 4 }} />
                  <h2 className="flex flex-row w-full items-center text-white">
                    Cantidad de productos{" "}
                    <p
                      style={{
                        marginLeft: "auto",
                        fontSize: 22,
                        fontWeight: 500,
                      }}
                    >
                      {sale.totalProducts} <span className="text-xs">uni.</span>
                    </p>
                  </h2>
                </div>
                <div className="flex flex-row mt-4 w-full items-center">
                  <LocalOfferIcon sx={{ color: "#ccc", marginRight: 4 }} />
                  <h2 className="flex flex-row w-full items-center text-white">
                    Monto total{" "}
                    <p
                      style={{
                        marginLeft: "auto",
                        fontSize: 22,
                        fontWeight: 500,
                      }}
                    >
                      {formatCLP(sale.totalAmount)}
                    </p>
                  </h2>
                </div>
              </div>
            </div>
            <div
              className="my-6 w-full"
              style={{ transform: "translateX(23px)" }}
            >
              <button
                onClick={handleSubmit}
                style={{ height: 50, width: "46%" }}
                className=" bg-lightBlue-500  text-white active:bg-lightBlue-600 font-bold uppercase text-xs rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
                Publicar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
