import { TextField } from "@mui/material";
import axios from "axios";
import Sidebar from "components/Sidebar/Sidebar";
import { Spinner } from "components/Spinner";
import { useLayout } from "context/layout/layout";
import React from "react";
import config from "services/config";

export const AddMembership = () => {
  return (
    <>
      <Sidebar />
      <Wrapper>
        <AddUserSettings />
      </Wrapper>
    </>
  );
};

const Wrapper = ({ children }) => {
  return (
    <div className="relative h-full" style={{ marginLeft: 200 }}>
      <div className="relative h-full" style={{ paddingBottom: 95 }}></div>
      <div className=" mx-auto w-full -m-24" style={{ height: "100vh" }}>
        <div className="flex flex-wrap h-full">
          <div className="w-full h-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

function AddUserSettings() {
  const layout = useLayout();
  const [loading, setLoading] = React.useState(false);
  const [membership, setMembership] = React.useState({
    title: "",
    price: "",
  });

  const handleSubmit = () => {
    setLoading(true);
    axios
      .post(
        `${config.url}/membership/create`,
        {
          ...membership,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      )
      .then((res) => {
        setLoading(false);
        layout.successAlert({ message: "Plan creado con exito" });
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        layout.errorAlert({ message: "Error al crear el plan" });
        return;
      });
  };

  return (
    <>
      {loading && <Spinner />}

      <div
        className="relative h-full flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
        style={{ backgroundColor: "rgb(17, 24, 39)" }}
      >
        <div
          className="rounded-t  mb-0 py-6 "
          style={{ backgroundColor: "rgb(17, 24, 39)", paddingLeft: 57 }}
        >
          <div className="text-center flex justify-between ">
            <h6 className="text-white  text-xl font-bold">
              INSCRIPCIÓN NUEVO PLAN
            </h6>
          </div>
        </div>

        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <div className="flex flex-wrap pt-6">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-8">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el nombre del plan
                  </label>

                  <TextField
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow  w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    value={membership.title}
                    onChange={(e) => {
                      setMembership({
                        ...membership,
                        title: e.target.value,
                      });
                    }}
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el precio
                  </label>

                  <TextField
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    variant="outlined"
                    value={membership.price}
                    onChange={(e) => {
                      setMembership({
                        ...membership,
                        price: e.target.value,
                      });
                    }}
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                  />
                </div>
              </div>
            </div>

            <button
              onClick={handleSubmit}
              style={{ height: 45, width: "48%", marginLeft: 15 }}
              className=" bg-lightBlue-800 mt-8 text-white active:bg-lightBlue-600 font-bold uppercase text-xs  py-2 rounded shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150"
              type="button"
            >
              Publicar
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
