import React, { useEffect, useState } from "react";
import Sidebar from "components/Sidebar/Sidebar.js";
import CardTable from "components/Cards/CardTable";
import axios from "axios";
import { TableDataItem } from "components/Cards/CardTable";
/* import { useStore } from "../../context/store/store";
 */ import { Spinner } from "components/Spinner";
/* import { useStore } from "context/store/store";
 */ /* import VisibilityIcon from "@mui/icons-material/Visibility";
 */ import config from "services/config";
import VisibilityIcon from "@mui/icons-material/Visibility";
import logo from "../../assets/LL.png";

import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  TextField,
  Tooltip,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatCLP } from "utils";
import LocalShippingIcon from "@mui/icons-material/LocalShipping";
import { useLayout } from "context/layout/layout";

export function DailyRetail({ color = null }) {
  const layout = useLayout();
  const navigate = useNavigate();
  const [, /* members */ setMembers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [temp, setTemp] = useState(null);
  const [query /* setQuery */] = useState("");
  const [orders, setOrders] = useState(null);
  const [message, setMessage] = useState({
    open: false,
    title: "Desea marcar este pedido como despachado",
    msg: "Esta accion es definitiva y no puede ser revertida",
    orderId: null,
  });

  const [courier, setCourier] = useState(null);
  const [trackingId, setTrackingId] = useState(null);

  useEffect(() => {
    if (!orders) {
      axios
        .get(`${config.url}/sales/retail-orders`)
        .then(({ data }) => {
          console.log(data);
          setOrders(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line
  }, [orders]);

  useEffect(() => {
    if (query.length > 0 && temp && temp.length > 0) {
      let filtered = temp.filter((p) => p.id_number.includes(query));
      setMembers(filtered);
    }

    if (query === "") {
      setMembers(temp);
    }
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    axios
      .get(`${config.url}/costumer/list`)
      .then((res) => {
        setMembers(res.data);
        setTemp(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  /*   const handleDelete = async ({ member_id }) => {
    setLoading(true);
    try {
      await store.deleteRetailClient(member_id);
      let newproducts = members.filter((p) => p._id !== member_id);
      setLoading(false);
      setMembers(newproducts);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }; */

  function seeDetails(detail) {
    navigate(`/sales/view/${detail._id}`, {
      state: { detail: detail },
    });
  }

  function getStatusText(status = null) {
    if (!status) {
      return "";
    }

    if (status === "PROCESSING") {
      return "Procesando";
    }

    if (status === "DISPATCHED") {
      return "Enviado";
    }
  }

  function getStatusColor(status = null) {
    if (!status) {
      return "";
    }

    if (status === "PROCESSING") {
      return "#f59e0b";
    }

    if (status === "DISPATCHED") {
      return "#15803d";
    }
  }

  async function delivery(orderId) {
    console.log(courier);
    console.log(trackingId);
    if (!courier || !trackingId || courier === "" || trackingId === "") {
      layout.errorAlert({
        message: `Debes ingresar el numero y empresa de seguimiento como mensaje`,
      });

      return;
    }
    let { data } = await axios.post(
      `${config.url}/sales/retail/order/dispatched/${orderId}`,
      {
        courier,
        trackingId,
      },
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );

    if (data) {
      setOrders(null);
      setMessage({
        ...message,
        open: false,
      });
    }
  }

  const confirmDelivery = async () => {
    await delivery(message.orderId);
  };

  const openMessage = (order) => {
    setMessage({
      ...message,
      open: true,
      orderId: order._id,
    });
  };

  const closeMessage = () => {
    setMessage({
      ...message,
      open: false,
    });
  };

  return (
    <>
      {loading && <Spinner />}
      <Sidebar />
      <div className="relative" style={{ marginLeft: 200 }}>
        <div className="relative " style={{ paddingBottom: 95 }}></div>
        <div className=" mx-auto w-full -m-24">
          <div className="flex flex-wrap">
            <div className="w-full  h-full">
              <CardTable
                title={"RETAIL LOBAREDE NUTRITION"}
                headers={[
                  "Status",
                  "Numero de orden",
                  "Cliente",
                  "Monto",
                  "Fecha",
                  "Detalle",
                ]}
                color={"dark"}
              >
                {orders &&
                  orders.length > 0 &&
                  orders.map((order, idx) => {
                    return (
                      <tr>
                        <TableDataItem
                          sx={{
                            transform: "translateX(25px)",
                          }}
                        >
                          <p
                            style={{
                              backgroundColor: getStatusColor(order.status),
                              borderRadius: "5px",
                              padding: 3,
                            }}
                          >
                            {getStatusText(order.status)}
                          </p>
                        </TableDataItem>
                        <TableDataItem># {order._id}</TableDataItem>
                        <TableDataItem>{order.customer.name}</TableDataItem>

                        <TableDataItem>
                          {formatCLP(order.totalPrice)}
                        </TableDataItem>
                        <TableDataItem>
                          {new Date(order.date).toLocaleDateString()}
                        </TableDataItem>

                        <TableDataItem>
                          <IconButton onClick={() => seeDetails(order)}>
                            <VisibilityIcon sx={{ color: "#ccc" }} />
                          </IconButton>
                          <Tooltip title="Marcar Despachado">
                            <IconButton onClick={() => openMessage(order)}>
                              <LocalShippingIcon sx={{ color: "#ccc" }} />
                            </IconButton>
                          </Tooltip>
                        </TableDataItem>
                      </tr>
                    );
                  })}
              </CardTable>
            </div>
          </div>
        </div>
      </div>
      <Dialog
        open={message.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "rgb(17, 24, 39)",
            borderRadius: 5,
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ backgroundColor: "#082f49", color: "#fff" }}
        >
          <div className="flex ">
            <img src={logo} width="18%" alt="img" />
          </div>
        </DialogTitle>
        <div className="px-4 py-2">
          <label className="text-white text-xs my-2">
            Ingresa el nombre de la empresa de transporte
          </label>
          <TextField
            type="text"
            onChange={({ target }) => {
              setCourier(target.value);
            }}
            placeholder="Empresa de transporte"
            sx={{ backgroundColor: "#cbd5e1" }}
            className="  border-0  placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 "
          />
        </div>
        <div className="px-4 py-2">
          <label className="text-white text-xs my-2">
            Ingresa el numero de seguimiento
          </label>
          <TextField
            placeholder="Numero de seguimiento"
            onChange={({ target }) => {
              setTrackingId(target.value);
            }}
            type="text"
            sx={{ backgroundColor: "#cbd5e1" }}
            className="  border-0  placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 "
          />
        </div>

        <DialogActions sx={{ backgroundColor: "rgb(17, 24, 39)", margin: 1 }}>
          <Button
            sx={{ color: "#fff", marginRight: 1 }}
            variant="contained"
            color="success"
            onClick={confirmDelivery}
          >
            Confirmar
          </Button>
          <Button
            sx={{ color: "#fff" }}
            variant="contained"
            color="error"
            onClick={closeMessage}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
