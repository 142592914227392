import axios from "axios";
import config from "services/config";

export class ReportsB2BService {
  static async getTotalSales() {
    try {
      const { data } = await axios.get(`${config.url}/reportsb2b/total-sales`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getTopSellingProducts() {
    try {
      const { data } = await axios.get(
        `${config.url}/reportsb2b/top-selling-products`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  static async getSalesBySalesman() {
    try {
      const { data } = await axios.get(
        `${config.url}/reportsb2b/sales-by-salesman`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getWeeklySales() {
    try {
      const { data } = await axios.get(`${config.url}/reportsb2b/weekly-sales`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getMonthlySales() {
    try {
      const { data } = await axios.get(
        `${config.url}/reportsb2b/monthly-sales`
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  static async getYearlySales() {
    try {
      const { data } = await axios.get(`${config.url}/reportsb2b/yearly-sales`);
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async getRisk() {
    try {
      const { data } = await axios.get(`${config.url}/reportsb2b/risk`);
      return data;
    } catch (error) {
      throw error;
    }
  }
}
