import React, { useEffect, useState } from "react";
import Sidebar from "components/Sidebar/Sidebar.js";
import CardTable from "components/Cards/CardTable";
import axios from "axios";
import { TableDataItem } from "components/Cards/CardTable";
/* import { useStore } from "../../context/store/store";
 */ import { Spinner } from "components/Spinner";
/* import { useStore } from "context/store/store";
 */ /* import VisibilityIcon from "@mui/icons-material/Visibility";
 */ import config from "services/config";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { formatCLP } from "utils";

const productsHeaders = ["Fecha", "ID", "Total", "Acciones"];

export function DailyHistory({ color = null }) {
  const navigate = useNavigate();
  const [, /* members */ setMembers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [temp /* setTemp */] = useState(null);
  const [query, setQuery] = useState("");
  const [history, setHistory] = useState(null);

  useEffect(() => {
    if (query.length > 0 && temp && temp.length > 0) {
      let filtered = temp.filter((p) => p.id_number.includes(query));
      setMembers(filtered);
    }

    if (query === "") {
      setMembers(temp);
    }
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    axios
      .get(`${config.url}/daily/list`)
      .then(({ data }) => {
        setHistory(data.reverse());
        console.log(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  const deleteShift = async (shiftId) => {
    axios
      .delete(`${config.url}/daily/delete/${shiftId}`)
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const seeDetails = (id) => {
    navigate(`/daily/view/${id}`);
  };

  return (
    <>
      {loading && <Spinner />}
      <Sidebar />
      <div className="relative" style={{ marginLeft: 200 }}>
        <div className="relative " style={{ paddingBottom: 95 }}></div>
        <div className=" mx-auto w-full -m-24">
          <div className="flex flex-wrap">
            <div className="w-full  h-full">
              <CardTable
                title={"HISTORIAL DE CAJAS"}
                headers={productsHeaders}
                add="Agregar Cliente"
                routeadd="/costumers/add"
                filterComponent={true}
                query={query}
                setQuery={setQuery}
                color={"dark"}
                filterPlaceholder="Buscar usuario por rut"
              >
                {history &&
                  history.length > 0 &&
                  history.map((daily, idx) => {
                    const utcDate = new Date(daily.date);
                    utcDate.setUTCHours(12, 0, 0, 0); // Set the time to noon UTC
                    const chileTimeZone = "America/Santiago";
                    const formattedDate = new Intl.DateTimeFormat("en-GB", {
                      timeZone: chileTimeZone,
                    }).format(utcDate);

                    return (
                      <tr>
                        <TableDataItem
                          sx={{
                            fontWeight: 600,
                            fontSize: 15,
                            textAlign: "left",
                            transform: "translateX(25px)",
                          }}
                        >
                          {formattedDate}
                        </TableDataItem>
                        <TableDataItem>{daily._id}</TableDataItem>

                        <TableDataItem>{formatCLP(daily.total)}</TableDataItem>

                        <TableDataItem>
                          <IconButton onClick={() => seeDetails(daily._id)}>
                            <VisibilityIcon sx={{ color: "#ccc" }} />
                          </IconButton>
                          <IconButton onClick={() => deleteShift(daily._id)}>
                            <DeleteIcon sx={{ color: "#ccc" }} />
                          </IconButton>
                        </TableDataItem>
                      </tr>
                    );
                  })}
              </CardTable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
