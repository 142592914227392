import { useAuth } from "context/auth/auth";
import { useLayout } from "context/layout/layout";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/temple.png";

export default function Login() {
  if (localStorage.getItem("userdata:admin"))
    window.location.replace("/daily/gym");
  const auth = useAuth();
  const layout = useLayout();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    email: "",
    password: "",
  });

  const handleChange = ({ target: { value, name } }) => {
    return setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (Object.values(user).some((v) => v === "")) {
      console.log({ user });
      return layout.errorAlert({
        message: "Todos los campos son obligatorios",
      });
    }

    try {
      let isAuthenticated = await auth.requestAuthentication({
        credentials: user,
      });

      if (isAuthenticated) {
        layout.successAlert({
          message: "Authenticacion realizada con exito",
        });

        setTimeout(() => {
          navigate("/admin/members");
        }, 2000);
      }
    } catch (err) {
      return layout.errorAlert({
        message: "Error al iniciar sesion",
      });
    }
  };

  return (
    <>
      <LoginContainer>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
          <div className="rounded-t mb-0 px-6 py-4">
            <img src={logo} alt="..." />
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Email
                </label>
                <input
                  type="email"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Email"
                  value={user.email}
                  onChange={handleChange}
                  name="email"
                />
              </div>

              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Contraseña
                </label>
                <input
                  type="password"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Password"
                  value={user.password}
                  onChange={handleChange}
                  name="password"
                />
              </div>

              <div className="text-center mt-6">
                <button
                  onClick={handleSubmit}
                  className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="button"
                >
                  Ingresar
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="flex flex-wrap mt-6 relative"></div>
      </LoginContainer>
    </>
  );
}

const LoginContainer = ({ children }) => {
  return (
    <div className="container mx-auto px-4 h-full">
      <div className="flex content-center items-center justify-center h-full">
        <div className="w-full lg:w-4/12 px-4">{children}</div>
      </div>
    </div>
  );
};
