import axios from "axios";
import config from "services/config";

export class MessageService {
  static async delete({ message_id }) {
    try {
      const { data } = await axios.delete(
        `${config.url}/messages/delete/${message_id}`
      );
      console.log(data);
      return data;
    } catch (error) {
      throw error;
    }
  }
  static async create(payload) {
    try {
      const { data } = await axios.post(
        `${config.url}/messages/create/`,
        payload,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
