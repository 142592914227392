import React, { useEffect, useState } from "react";
import { useAuth } from "../context/auth/auth";
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import { Alert } from "components/Alert";
import { routes } from "./routes";
import Confetti from "react-confetti";
import { useLayout } from "context/layout/layout";
import { useLegacy } from "context/legacy/legacy";
import axios from "axios";
import config from "services/config";
import { Cube } from "components/Loaders/Cube";
import { Spinner } from "components/Spinner";
import { CredentialsLoader } from "components/Spinner";

// Custom private route component
export const PrivateRoute = ({ children }) => {
  const auth = useAuth();

  if (!auth.verified) {
    return null;
  }

  if (!auth.user) {
    return <Navigate to="/" replace />;
  }

  return children;
};

export const RoutesApplication = () => {
  const { legacy, setLegacy } = useLegacy();
  const auth = useAuth();
  const [loading, setLoading] = useState(true);
  const layout = useLayout();

  React.useEffect(() => {
    if (!auth.user) {
      setTimeout(() => {
        auth.verifyAuthentication();
      });
    }

    if (
      !window.location.pathname.includes("member") &&
      auth &&
      auth.user &&
      auth.user.subscription
    ) {
      window.location.replace(`/member/${auth.user._id}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auth]);

  useEffect(() => {
    console.log(legacy.members);
    if (legacy.members.length < 1) {
      axios
        .get(`${config.url}/members/showall`)
        .then((res) => {
          setLegacy({ ...legacy, members: res.data });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {routes.map((route, idx) => {
          const AuthElement = route.isPrivate ? (
            <PrivateRoute>
              <route.component />
            </PrivateRoute>
          ) : (
            <route.component />
          );

          return (
            <Route
              key={idx}
              path={route.path}
              element={AuthElement}
              {...route.props}
            />
          );
        })}
      </Routes>
      <Alert />
      {layout.confetti && (
        <Confetti
          gravity={0.3}
          onConfettiComplete={() => layout.stopConfetti()}
        />
      )}
      {loading && <CredentialsLoader />}
    </BrowserRouter>
  );
};
