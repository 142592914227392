import React, { useEffect } from "react";
import "./index.css";
// components

import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/Stats2";
import { useStore } from "context/store/store";
import { useAuth } from "context/auth/auth";

export function AdminLayout({ children }) {
  const auth = useAuth();
  const store = useStore();

  useEffect(() => {
    console.log({ reports: store.reports });

    auth.user && !store.reports.totalSales && store.getInitialReports();
    // eslint-disable-next-line
  }, [auth.user]);

  return (
    <>
      <Sidebar />
      <div
        className="relative"
        id="dashboard"
        style={{
          backgroundColor: "rgb(17, 24, 39)",
          minHeight: "100vh",
          maxWidth: "100%",
        }}
      >
        <HeaderStats />
        <div className="mt-4">{children}</div>
      </div>
    </>
  );
}
