import axios from "axios";
import config from "services/config";

export class ProductService {
  static async delete({ product_id }) {
    try {
      const { data } = await axios.delete(
        `${config.url}/products/delete/${product_id}`
      );
      console.log(data);
      return data;
    } catch (error) {
      throw error;
    }
  }
  static async create(payload) {
    console.log({ payload });
    try {
      const { data } = await axios.post(
        `${config.url}/products/create/`,
        payload,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async update({ payload, id }) {
    try {
      const { data } = await axios.post(
        `${config.url}/products/update/${id}`,
        payload,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
