import React, { useRef } from "react";
import "./index.css";
import gym from "../../assets/logo_blanco.png";
import b2b from "../../assets/b2b.png";
import legacy from "../../assets/LL.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
// components

import CardStats from "components/Cards/CardStats.js";
import { useStore } from "context/store/store";
import { formatCLP, months } from "utils";

export default function HeaderStats() {
  const store = useStore();
  const gymref = useRef(null);
  const b2bref = useRef(null);
  const legacyref = useRef(null);
  const memberKpiDetailRef = useRef(null);
  const activeMemberKpiDetailRef = useRef(null);

  function handleGymStats() {
    gymref.current.style.display === "none"
      ? (gymref.current.style.display = "grid")
      : (gymref.current.style.display = "none");
  }

  function handleB2BStats() {
    b2bref.current.style.display === "none"
      ? (b2bref.current.style.display = "grid")
      : (b2bref.current.style.display = "none");
  }

  function handleLegacyStats() {
    legacyref.current.style.display === "none"
      ? (legacyref.current.style.display = "grid")
      : (legacyref.current.style.display = "none");
  }

  function handleMemberKpiDetailStat() {
    memberKpiDetailRef.current.style.display === "none"
      ? (memberKpiDetailRef.current.style.display = "unset")
      : (memberKpiDetailRef.current.style.display = "none");
  }

  function handleActiveMemberKpiDetailStat() {
    activeMemberKpiDetailRef.current.style.display === "none"
      ? (activeMemberKpiDetailRef.current.style.display = "unset")
      : (activeMemberKpiDetailRef.current.style.display = "none");
  }

  return (
    <>
      <div className="px-12 py-4 stats-container">
        <div>
          <div className="pb-6 flex" style={{ maxWidth: 1600 }}>
            <img
              alt="img"
              src={gym}
              width={120}
              style={{ cursor: "pointer", height: 35 }}
              onClick={handleGymStats}
            />
            <p
              className="border-b"
              style={{
                marginLeft: 10,
                borderBottom: "1px solid #ccc",
                width: "90%",
                transform: "translateY(-20px)",
              }}
            ></p>
            <p onClick={handleGymStats} style={{ cursor: "pointer" }}>
              <KeyboardArrowDownIcon
                style={{
                  fontSize: 30,
                  marginLeft: 15,
                  color: "#ccc",
                }}
              />
            </p>
          </div>
          <div
            ref={gymref}
            className="grid-container"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
              gridTemplateRows: "repeat(auto-fit, minmax(100px, 1fr))",
              gridAutoRows: "1fr",
              gridColumnGap: "10px",
              gridRowGap: "10px",
              maxWidth: 1600,
              transition: ".5",
            }}
          >
            <CardStats
              statSubtitle="Este mes"
              statTitle={
                store.reports.monthlySales
                  ? formatCLP(store.reports.monthlySales)
                  : null
              }
              statPercentColor="text-emerald-500"
              statDescripiron={months[new Date().getMonth()]}
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
            />
            <CardStats
              statSubtitle="HOY"
              statTitle={
                store.reports.totalToday
                  ? formatCLP(store.reports.totalToday)
                  : null
              }
              statPercentColor="text-emerald-500"
              statIconName="far fa-chart-bar"
              statDescripiron={new Date().toLocaleDateString()}
              statIconColor="bg-red-500"
            />
            <CardStats
              statSubtitle="Mercaderias este mes"
              statTitle={
                store.reports.bussines && store.reports.bussines.salesPercent
              }
              statPercent={
                store.reports.bussines &&
                store.reports.bussines.totalSalesAmount
              }
              statDescripiron="Monto"
              statIconName="fas fa-chart-pie"
            />
            <CardStats
              statSubtitle="Membresias este mes"
              statTitle={
                store.reports.bussines &&
                store.reports.bussines.enrollmentPercent
              }
              statPercent={
                store.reports.bussines &&
                store.reports.bussines.totalEnrollmentAmount
              }
              statDescripiron="Monto"
              statIconName="fas fa-chart-pie"
              statIconColor="bg-orange-500"
            />
            <CardStats
              statSubtitle="Promedio diario"
              statTitle={
                store.reports.risk
                  ? formatCLP(
                      store.reports.risk && store.reports.risk.daily_avg
                    )
                  : null
              }
              statPercentColor="text-emerald-500"
              statDescripiron={new Date().getDate() + " días"}
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
            />
            <CardStats
              statSubtitle="% Equilibrio mensual"
              statTitle={
                store.reports.risk &&
                `${store.reports.risk.percent_of_monthly_balance}%`
              }
              statDescripiron="-"
              statIconName="fas fa-chart-pie"
              statIconColor="bg-orange-500"
            />
            <CardStats
              statSubtitle="Punto equilibrio diario"
              statTitle={
                store.reports.risk
                  ? formatCLP(
                      store.reports.risk && store.reports.risk.balance_daily
                    )
                  : null
              }
              statPercentColor="text-emerald-500"
              statIconName="far fa-chart-bar"
              statDescripiron={new Date().toLocaleDateString()}
              statIconColor="bg-red-500"
            />
            <div style={{ position: "relative" }}>
              <CardStats
                statSubtitle="Socios Activos"
                statTitle={
                  store.reports.memberkpis &&
                  store.reports.memberkpis.activeMembers
                }
                statPercentColor="text-emerald-500"
                statIconName="far fa-chart-bar"
                statDescripiron={"-"}
                statIconColor="bg-red-500"
                icon={true}
                handleIconClick={handleActiveMemberKpiDetailStat}
              />
              <div
                ref={activeMemberKpiDetailRef}
                style={{
                  display: "none",
                  position: "absolute",
                  top: 25,
                  left: -135,
                  backgroundColor: "#0c4a6e",
                  width: "300px",
                  padding: 10,
                  zIndex: 100,
                  borderRadius: 5,
                }}
              >
                <div className="flex justify-between border-b pb-2 mb-2">
                  <p className="text-white text-xs">Nombre</p>
                  <p className="text-white text-xs">% - uni</p>
                </div>
                {store.reports.memberkpis &&
                  store.reports.memberkpis.activeType.map((d) => (
                    <div className="flex justify-between my-1">
                      <p className="text-white text-xs">{d.type}</p>
                      <p className="text-white text-xs">
                        {d.percentage}% - {d.count}
                      </p>
                    </div>
                  ))}
              </div>
            </div>

            <div style={{ position: "relative" }}>
              <CardStats
                statSubtitle="Inscripciones este mes"
                statTitle={
                  store.reports.memberkpis &&
                  store.reports.memberkpis.newEnrollmentsThisMonth
                }
                statPercentColor="text-emerald-500"
                statIconName="far fa-chart-bar"
                statDescripiron={months[new Date().getMonth()]}
                statIconColor="bg-red-500"
                icon={true}
                handleIconClick={handleMemberKpiDetailStat}
              />
              <div
                ref={memberKpiDetailRef}
                style={{
                  display: "none",
                  position: "absolute",
                  top: 25,
                  left: -135,
                  backgroundColor: "#0c4a6e",
                  width: "300px",
                  padding: 10,
                  zIndex: 100,
                  borderRadius: 5,
                }}
              >
                <div className="flex justify-between border-b pb-2 mb-2">
                  <p className="text-white text-xs">Nombre</p>
                  <p className="text-white text-xs">% - uni</p>
                </div>
                {store.reports.memberkpis &&
                  store.reports.memberkpis.thisMonthType.map((d) => (
                    <div className="flex justify-between my-1">
                      <p className="text-white text-xs">{d.type}</p>
                      <p className="text-white text-xs">
                        {d.percentage}% - {d.count}
                      </p>
                    </div>
                  ))}
              </div>
            </div>

            <CardStats
              statSubtitle="Inscripciones HOY"
              statTitle={
                store.reports.memberkpis &&
                store.reports.memberkpis.newEnrollmentsToday
              }
              statPercentColor="text-emerald-500"
              statIconName="far fa-chart-bar"
              statDescripiron={new Date().toLocaleDateString()}
              statIconColor="bg-red-500"
            />
            <CardStats
              statSubtitle="Expiran este mes"
              statTitle={
                store.reports.memberkpis &&
                store.reports.memberkpis.expiredMembersThisMonth
              }
              statPercentColor="text-emerald-500"
              statIconName="far fa-chart-bar"
              statDescripiron={"🚩🚩🚩🚩"}
              statIconColor="bg-red-500"
            />
          </div>
        </div>

        <div>
          <div className="pb-2 flex" style={{ maxWidth: 1600 }}>
            <img
              alt="img"
              src={b2b}
              width={120}
              style={{ cursor: "pointer", height: 118 }}
              onClick={handleB2BStats}
            />
            <p
              className="border-b"
              style={{
                marginLeft: 10,
                borderBottom: "1px solid #ccc",
                width: "90%",
                transform: "translateY(-60px)",
              }}
            ></p>
            <p onClick={handleB2BStats} style={{ cursor: "pointer" }}>
              <KeyboardArrowDownIcon
                style={{
                  fontSize: 30,
                  marginLeft: 15,
                  color: "#ccc",
                  transform: "translateY(42px)",
                }}
              />
            </p>
          </div>
          <div
            ref={b2bref}
            className="grid-container"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
              gridTemplateRows: "repeat(auto-fit, minmax(100px, 1fr))",
              gridAutoRows: "1fr",
              gridColumnGap: "10px",
              gridRowGap: "10px",
              maxWidth: 1600,
              transition: ".5",
            }}
          >
            <CardStats
              statSubtitle="Este mes"
              statTitle={
                store.reports.b2bMonthlySales
                  ? formatCLP(store.reports.b2bMonthlySales)
                  : null
              }
              statPercentColor="text-emerald-500"
              statDescripiron={months[new Date().getMonth()]}
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
            />
            <CardStats
              statSubtitle="Hoy"
              statTitle={formatCLP(store.reports.b2bTotalToday)}
              statPercentColor="text-emerald-500"
              statDescripiron={new Date().toLocaleDateString()}
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
            />
            <CardStats
              statSubtitle="Promedio diario"
              statTitle={
                store.reports.risk
                  ? formatCLP(
                      store.reports.risk && store.reports.b2bRisk.daily_avg
                    )
                  : null
              }
              statPercentColor="text-emerald-500"
              statDescripiron={new Date().getDate() + " días"}
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
            />
            <CardStats
              statSubtitle="% Equilibrio mensual"
              statTitle={
                store.reports.risk &&
                `${store.reports.b2bRisk.percent_of_monthly_balance}%`
              }
              statDescripiron="-"
              statIconName="fas fa-chart-pie"
              statIconColor="bg-orange-500"
            />
            <CardStats
              statSubtitle="Punto equilibrio diario"
              statTitle={formatCLP(
                store.reports.risk && store.reports.b2bRisk.balance_daily
              )}
              statPercentColor="text-emerald-500"
              statIconName="far fa-chart-bar"
              statDescripiron={new Date().toLocaleDateString()}
              statIconColor="bg-red-500"
            />
          </div>
        </div>

        <div>
          <div className="py-10 flex" style={{ maxWidth: 1600 }}>
            <img
              alt="img"
              src={legacy}
              width={120}
              style={{ cursor: "pointer", height: 36 }}
              onClick={handleLegacyStats}
            />
            <p
              className="border-b"
              style={{
                marginLeft: 12,
                borderBottom: "1px solid #ccc",
                width: "90%",
                transform: "translateY(-20px)",
              }}
            ></p>
            <p onClick={handleLegacyStats} style={{ cursor: "pointer" }}>
              <KeyboardArrowDownIcon
                style={{
                  fontSize: 30,
                  marginLeft: 15,
                  color: "#ccc",
                  transform: "translateY(1px)",
                }}
              />
            </p>
          </div>
          <div
            ref={legacyref}
            className="grid-container"
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr))",
              gridTemplateRows: "repeat(auto-fit, minmax(100px, 1fr))",
              gridAutoRows: "1fr",
              gridColumnGap: "10px",
              gridRowGap: "10px",
              maxWidth: 1600,
              transition: ".5",
            }}
          >
            <CardStats
              statSubtitle="Este mes"
              statTitle={
                store.reports.b2bMonthlySales
                  ? formatCLP(
                      store.reports.b2bMonthlySales + store.reports.monthlySales
                    )
                  : null
              }
              statPercentColor="text-emerald-500"
              statDescripiron={months[new Date().getMonth()]}
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
            />
            <CardStats
              statSubtitle="Hoy"
              statTitle={formatCLP(
                store.reports.b2bTotalToday + store.reports.totalToday
              )}
              statPercentColor="text-emerald-500"
              statDescripiron={new Date().toLocaleDateString()}
              statIconName="far fa-chart-bar"
              statIconColor="bg-red-500"
            />
          </div>
        </div>
      </div>
    </>
  );
}
