import axios from "axios";
import config from "services/config";

export class CostumerService {
  static async create(payload) {
    const { data } = await axios.post(
      `${config.url}/costumer/create`,
      payload,
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );

    return data;
  }

  static async delete(client_id) {
    const { data } = await axios.delete(
      `${config.url}/costumer/delete/${client_id}`
    );

    return data;
  }

  static async update(user) {
    const { data } = await axios.post(
      `${config.url}/costumer/update`,
      {
        ...user,
      },
      {
        headers: {
          "Content-type": "application/json",
        },
      }
    );
    return data;
  }
}
