import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import Sidebar from "components/Sidebar/Sidebar";
import { Spinner } from "components/Spinner";
import { useLayout } from "context/layout/layout";
import { useLegacy } from "context/legacy/legacy";
import { useStore } from "context/store/store";
import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import config from "services/config";
import { defaultMember } from "utils/state";
import { PAYMENTS } from "utils/state";

export const NewInscription = () => {
  return (
    <>
      <Sidebar />
      <Wrapper>
        <AddUserSettings />
      </Wrapper>
    </>
  );
};

const Wrapper = ({ children }) => {
  return (
    <div
      className="relative h-full"
      style={{ marginLeft: 200, backgroundColor: "rgb(17, 24, 39)" }}
    >
      <div className="relative h-full" style={{ paddingBottom: 95 }}></div>
      <div
        className=" mx-auto w-full -m-24"
        style={{ height: "100vh", backgroundColor: "rgb(17, 24, 39)" }}
      >
        <div
          className="flex flex-wrap h-full"
          style={{ backgroundColor: "rgb(17, 24, 39)" }}
        >
          <div
            className="w-full h-full"
            style={{ backgroundColor: "rgb(17, 24, 39)" }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

function AddUserSettings() {
  const layout = useLayout();
  const store = useStore();
  const location = useLocation();
  const navigate = useNavigate();

  const { legacy, setLegacy } = useLegacy();

  const [loading, setLoading] = React.useState(false);
  const [user, setUser] = React.useState(defaultMember);
  const [memberships, setMemberships] = useState([]);
  const [salesman, setSalesman] = useState([]);
  const [salesmanId, setSalesmanId] = useState({ name: "" });
  const [payment, setPayment] = useState(PAYMENTS[0]);
  const [referal, setReferral] = useState(null);

  useEffect(() => {
    if (!location.state?.shiftId) {
      navigate(-1);
    }
    // eslint-disable-next-line
  }, [location]);

  // Get the salesman
  useEffect(() => {
    axios.get(`${config.url}/users/list/salesman`).then(({ data }) => {
      let salesman = data.map((user) => {
        return {
          ...user,
          label: user.name,
        };
      });
      console.log(salesman);
      setSalesman(salesman);
    });
    // eslint-disable-next-line
  }, []);
  /**
   *
   * Get the membership list
   */
  useEffect(() => {
    axios
      .get(`${config.url}/membership/list`)
      .then(({ data }) => {
        setMemberships(data);
        console.log(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleSubmit = () => {
    if (!user.id_number) {
      layout.errorAlert({ message: "Debe ingresar el rut del usuario" });
      return;
    }
    if (!user.subscription.membership) {
      layout.errorAlert({ message: "Debe seleccionar el plan" });
      return;
    }

    setLoading(true);
    store
      .createMember(user)
      .then(async (res) => {
        layout.successAlert({ message: "Usuario creado con exito" });

        let membershipSelected = memberships.find(
          (m) => m._id === user.subscription.membership
        );

        let document = {
          salesmanId: salesmanId._id,
          shiftId: location.state.shiftId,
          payment: payment.code,
          memberId: res.member._id,
          campusId: "6511b30a218917abfb036577",
          membershipId: user.subscription.membership,
          totalPrice: membershipSelected.price,
          referral: referal?._id || null,
        };

        await axios.post(
          `${config.url}/enrollment/register`,
          { ...document },
          {
            headers: {
              "Content-type": "application/json",
            },
          }
        );

        layout.successAlert({ message: `Venta creada exitósamente` });
        layout.useConfetti();
        setLoading(false);
        navigate(-1);
        return;
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        layout.errorAlert({ message: "Error al crear el alumno" });
        navigate(-1);

        return;
      });
  };

  return (
    <>
      {loading && <Spinner />}

      <div
        className="relative h-full flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
        style={{ backgroundColor: "rgb(17, 24, 39)" }}
      >
        <div
          className="rounded-t  mb-0 py-6 "
          style={{ backgroundColor: "rgb(17, 24, 39)", paddingLeft: 52 }}
        >
          <div className="text-center flex justify-between ">
            <h6 className="text-white  text-xl font-bold">
              Ingresa los datos y completa el registro de un nuevo socio
            </h6>
          </div>
        </div>

        <div
          className="flex-auto px-4 lg:px-10 py-10 pt-0"
          style={{ backgroundColor: "rgb(17, 24, 39)" }}
        >
          <form>
            <div className="flex flex-wrap pt-6">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el nombre y apellidos del socio
                  </label>

                  <TextField
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow  w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    placeholder="Nombre del socio ej: Mark Zuckeru"
                    sx={{
                      width: "100%",
                      backgroundColor: "#cbd5e1",
                    }}
                    value={user.name}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el correo
                  </label>

                  <TextField
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Correo del socio ej: socio@tesla.com"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={user.email}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Fecha de nacimiento
                  </label>
                  <TextField
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    variant="outlined"
                    placeholder="Fecha de nacimiento"
                    type={"date"}
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={user.birthday}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        birthday: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el rut
                  </label>
                  <TextField
                    className="border-0   placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    placeholder="Rut"
                    variant="outlined"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={user.id_number}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        id_number: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              {/*         <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el numero de teléfono celular
                  </label>
                  <TextField
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    placeholder="Numero de contacto del socio"
                    variant="outlined"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={user.phone}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        phone: e.target.value,
                      });
                    }}
                  />
                </div>
              </div> */}
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa referido
                  </label>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Selecciona un referido "
                      />
                    )}
                    clearIcon={false}
                    options={legacy.members}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option._id}>
                          {option.id_number} {option.name}
                        </li>
                      );
                    }}
                    disablePortal
                    sx={{
                      padding: 0,
                      border: "none",
                      marginRight: 2,
                      backgroundColor: "#cbd5e1",
                    }}
                    onChange={(e, v) => setReferral(v)}
                    getOptionLabel={(option) =>
                      option.id_number || "Default Label"
                    }
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Selecciona el plazo del plan de elección
                  </label>
                  <Autocomplete
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="combo-box-demo"
                    placeholder="Plan"
                    options={memberships}
                    onChange={(e, value) =>
                      setUser({
                        ...user,
                        subscription: {
                          ...user.subscription,
                          type: value.title,
                          membership: value._id,
                        },
                      })
                    }
                    sx={{
                      padding: 0,
                      border: "unset",
                      backgroundColor: "#cbd5e1",
                    }}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Plan socio" />
                    )}
                  />
                </div>
              </div>

              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Fecha de inscripcion
                  </label>

                  <TextField
                    type="date"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%", backgroundColor: "#cbd5e1" }}
                    value={user.subscription.startDate}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        subscription: {
                          ...user.subscription,
                          startDate: e.target.value,
                        },
                      });
                    }}
                  />
                </div>
              </div>
              {/*   <div
                className="w-full lg:w-4/12 px-4"
                style={{ display: "none" }}
              >
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Sucursal
                  </label>
                  <Autocomplete
                    className="border-0  placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="combo-box-demo"
                    placeholder="Plan"
                    options={[
                      { label: "Temple Gym Malloco" },
                      { label: "Temple Gym Talagante" },
                    ]}
                    value={user.campus}
                    onChange={(e, value) =>
                      setUser({
                        ...user,
                        campus: value,
                      })
                    }
                    onInputChange={(e, value) => {
                      setUser({
                        ...user,
                        campus: value,
                      });
                    }}
                    inputValue={user.campus}
                    sx={{
                      padding: 0,
                      border: "unset",
                      backgroundColor: "#cbd5e1",
                    }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </div> */}
              <div className="w-full  lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el vendedor
                  </label>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Selecciona un vendedor"
                      />
                    )}
                    clearIcon={false}
                    options={salesman}
                    value={salesmanId}
                    inputValue={salesmanId.name}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option._id}>
                          {option.name}
                        </li>
                      );
                    }}
                    sx={{
                      padding: 0,
                      border: "none",
                      marginRight: 2,
                      backgroundColor: "#cbd5e1",
                    }}
                    onChange={(e, value) => setSalesmanId(value)}
                    getOptionLabel={(option) => option.name}
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full  lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Método de pago
                  </label>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Selecciona un método de pago"
                      />
                    )}
                    clearIcon={false}
                    options={PAYMENTS}
                    value={payment}
                    inputValue={payment.name}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option._id}>
                          {option.name}
                        </li>
                      );
                    }}
                    sx={{
                      padding: 0,
                      border: "none",
                      marginRight: 2,
                      backgroundColor: "#cbd5e1",
                    }}
                    onChange={(e, value) => setPayment(value)}
                    getOptionLabel={(option) => option.name}
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
            </div>

            <button
              onClick={handleSubmit}
              style={{ height: 50, width: "200px", marginLeft: 25 }}
              className=" bg-lightBlue-800 mt-8 text-white active:bg-lightBlue-600 font-bold uppercase text-xs  py-2 rounded shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150"
              type="button"
            >
              Publicar
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
