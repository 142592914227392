import Sidebar from "components/Sidebar/Sidebar";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import io from "socket.io-client";
import * as React from "react";
import Card from "@mui/material/Card";

import medal from "../../assets/Medalla.png";
import "./index.css";

export const VerifyMember = () => {
  const [member, readMember] = useState(null);

  useEffect(() => {
    const socket = io("https://api.lobarede.cl");

    socket.emit("chat_message", { data: "Hello from client" });

    socket.on("connect", () => {
      console.log("Socket.IO connected");
    });

    socket.on("scanned_member", (message) => {
      readMember(message);
      setTimeout(() => {
        readMember(null);
      }, 1000 * 30);
      console.log("Member Scanned", message);
    });

    socket.on("chat_message", (message) => {
      console.log("Message received from server:", message);
    });

    socket.on("error", (error) => {
      console.error("Socket.IO error:", error);
    });

    socket.on("disconnect", () => {
      console.log("Socket.IO disconnected");
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <>
      <Sidebar />
      <div
        className="relative"
        style={{
          marginLeft: 210,
          backgroundColor: "rgb(17, 24, 39)",
          height: "100%",
          minHeight: "100vh",
          padding: 50,
          display: "flex",
          gap: 50,
        }}
      >
        <div style={{ width: "50%" }}>
          {" "}
          <div
            style={{
              height: "70%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                background: "white",
                padding: "16px",
                width: "fit-content",
              }}
            >
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "350", width: "350" }}
                value={"http://192.168.1.24:9005/api/app/scan-member"}
                viewBox={`0 0 256 256`}
              />
            </div>
          </div>
          <div
            style={{
              height: "30%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Card
              sx={{
                minWidth: 275,
                width: "100%",
                height: "100%",
                borderRadius: 4,
                backgroundColor: "#1e293b",
                color: "#fff",
                visibility: "hidden",
              }}
            >
              <div className="py-4">
                <p className="font-bold">Status</p>
                <div className="flex justify-center w-full">
                  {member && (
                    <p
                      className="text-center mt-10 px-3 py-1 font-light rounded"
                      style={{
                        backgroundColor:
                          new Date(member.subscription.endDate) > new Date()
                            ? "#15803d"
                            : "red",
                        color: "#fff",
                      }}
                    >
                      {new Date(member.subscription.endDate) > new Date()
                        ? "En curso"
                        : "Vencido"}
                    </p>
                  )}
                  {
                    <p className="text-center mt-10 px-3 py-1 font-light rounded ">
                      Scanear datos
                    </p>
                  }
                </div>
              </div>
            </Card>
          </div>{" "}
        </div>
        <div style={{ width: "50%" }}>
          <div
            style={{
              height: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: 15,
            }}
          >
            <Card
              sx={{
                minWidth: 275,
                width: "100%",
                height: "90%",
                borderRadius: 4,
                backgroundColor: "#1e293b",
                color: "#fff",
              }}
            >
              <div className="flex w-full h-full">
                <div
                  className="flex gap-2 flex-col py-4 pl-10 justify-center w-full h-full"
                  style={{ width: "75%" }}
                >
                  <div>
                    <p className="font-light">Membresia Temple Legacy+</p>
                    <p className="font-bold text-3xl text-left">
                      {member ? member.name : "[Nombre]"}
                    </p>
                  </div>
                  <div className="mt-auto">
                    <p className="font-light text-sm">Status</p>
                    {member && (
                      <p
                        className="text-center w-1/2 font-bold mt-2"
                        style={{
                          backgroundColor:
                            new Date(member.subscription.endDate) > new Date()
                              ? "#15803d"
                              : "red",
                          color: "#fff",
                          borderRadius: 3,
                          padding: "2px 5px",
                        }}
                      >
                        {new Date(member.subscription.endDate) > new Date()
                          ? "En curso"
                          : "Vencido"}
                      </p>
                    )}
                  </div>
                  <div className="mt-auto">
                    <p className="font-light text-sm">Puntos</p>
                    <p className="text-3xl font-bold">
                      {member ? member.points : 0}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center w-50 h-full">
                  <img alt="img" src={medal} width={140} />
                </div>
              </div>
            </Card>
          </div>
          <div
            style={{
              height: "50%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: 15,
            }}
          >
            <Card
              sx={{
                minWidth: 275,
                width: "100%",
                height: "37%",
                borderRadius: 4,
                backgroundColor: "#1e293b",
                color: "#fff",
              }}
            >
              <div className="px-4 py-4">
                <p className="text-base font-light ml-3">Fecha de expiración</p>
                <p className="text-center font-bold text-xl ">
                  {member
                    ? new Date(member.subscription.endDate).toLocaleDateString()
                    : "-"}
                </p>
              </div>
            </Card>
            <Card
              sx={{
                visibility: "hidden",
                minWidth: 275,
                width: "100%",
                height: "62%",
                borderRadius: 4,
                backgroundColor: "#1e293b",
                color: "#fff",
              }}
            >
              <div className="p-4">
                <p>Asistencia del mes</p>
                <p className="text-center mt-10 text-2xl">10 días</p>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

/* 

    <div
              style={{
                background: "white",
                padding: "16px",
                width: "fit-content",
              }}
            >
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "300", width: "300" }}
                value={"http://192.168.1.24:9005/api/app/scan-member"}
                viewBox={`0 0 256 256`}
              />
            </div>
*/
