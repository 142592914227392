import axios from "axios";
import config from "services/config";

export class MemberService {
  static async delete({ member_id }) {
    try {
      const { data } = await axios.delete(
        `${config.url}/members/delete/${member_id}`
      );
      console.log(data);
      return data;
    } catch (error) {
      throw error;
    }
  }
  static async create(payload) {
    console.log({ payload });
    try {
      const { data } = await axios.post(
        `${config.url}/members/create`,
        payload,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  static async edit(payload) {
    try {
      const { data } = await axios.post(
        `${config.url}/members/edit/${payload._id}`,
        payload,
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
