export const DailyHeader = ({ shift, handleOpenShift }) => {
  return (
    <>
      <h6 className="text-white  text-lg font-light ">
        Punto de venta para el dia {new Date().toLocaleDateString()}
      </h6>
      {!shift.isInProgress && (
        <>
          <h6 className="text-white  text-sm my-4 font-normal ">
            - ¿Como funciona el punto de ventas?
          </h6>
          <h6 className="text-white  text-sm my-2 font-normal ">
            1. Los turnos funcionan exclusivamente para el dia vigente hasta las
            00:00 hrs
          </h6>
          <h6 className="text-white  text-sm my-2 font-normal ">
            2. Haz click en apertura turno
          </h6>
          <h6 className="text-white  text-sm my-2 font-normal ">
            3. Ingresa el efectivo que hay en caja en el momento de apertura del
            turno como "Saldo Inicial"
          </h6>
          <h6 className="text-white  text-sm my-2 font-normal ">
            4. El turno se cierra automaticamente a las 00:00 hrs
          </h6>
          <h6 className="text-white  text-sm my-2 font-normal ">
            5. Si el punto de ventas funciona hasta horas de la madrugada por ej
            01:00 hrs y necesitas realizar ventas solos debes aperturar el turno
            siguiendo los pasos anteriores
          </h6>
        </>
      )}

      {!shift.isInProgress && (
        <div className="w-full lg:w-6/12 my-4">
          <button
            onClick={handleOpenShift}
            style={{
              height: 45,
              width: "40%",
              backgroundColor: "#15803d",
            }}
            className="  text-white  font-bold uppercase text-xs rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
            type="button"
          >
            Apertura turno {new Date().toLocaleDateString()}
          </button>
        </div>
      )}
    </>
  );
};
