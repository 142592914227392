import { Autocomplete, TextField } from "@mui/material";
import axios from "axios";
import Sidebar from "components/Sidebar/Sidebar";
import { Spinner } from "components/Spinner";
import { useLayout } from "context/layout/layout";
import React, { useEffect, useState } from "react";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import config from "services/config";
import { useLocation, useNavigate } from "react-router-dom";
import { PAYMENTS } from "utils/state";
import { formatCLP } from "utils";

import PaidIcon from "@mui/icons-material/Paid";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import { useLegacy } from "context/legacy/legacy";

export const AddEnrollment = () => {
  return (
    <>
      <Sidebar />
      <Wrapper>
        <AddProductSettings />
      </Wrapper>
    </>
  );
};

const Wrapper = ({ children }) => {
  return (
    <div className="relative h-full" style={{ marginLeft: 200 }}>
      <div className="relative h-full" style={{ paddingBottom: 95 }}></div>
      <div className=" mx-auto w-full -m-24" style={{ height: "100vh" }}>
        <div className="flex flex-wrap h-full">
          <div className="w-full h-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default function AddProductSettings() {
  const { legacy, setLegacy } = useLegacy();

  const location = useLocation();
  const navigate = useNavigate();
  const layout = useLayout();

  const [loading, setLoading] = useState(false);
  const [salesman, setSalesman] = useState(null);
  const [salesmanId, setSalesmanId] = useState(null);
  const [referal, setReferral] = useState(null);
  const [headquarter, setHeadQuarter] = useState(null);

  const [, /* campusList */ setCampusList] = useState([]);

  const [memberValue, setMemberValue] = useState(null);
  const [memberships, setMemberships] = useState([]);
  const [membership, setMemberShip] = useState(null);
  const [payment, setPayment] = useState(null);

  const [email, newEmail] = useState(null);

  const [discount, setDiscount] = useState({
    points: 0,
    amount: 0,
    percentage: 10,
    newTotal: 0,
    isActive: false,
  });

  useEffect(() => {
    if (memberValue) {
      newEmail(memberValue.email);
    }
  }, [memberValue]);

  useEffect(() => {
    console.log({ location });
    if (!location.state?.shiftId) {
      navigate(-1);
    }
    // eslint-disable-next-line
  }, [location]);

  //Get the campus
  useEffect(() => {
    axios
      .get(`${config.url}/campus/list`)
      .then(({ data }) => {
        setCampusList(data);
        setHeadQuarter(data[0]);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  // Get the salesman
  useEffect(() => {
    axios.get(`${config.url}/users/list/salesman`).then(({ data }) => {
      let salesman = data.map((user) => {
        return {
          ...user,
          label: user.name,
        };
      });
      console.log(salesman);
      setSalesman(salesman);
    });
    // eslint-disable-next-line
  }, []);

  /**
   *
   * Get the membership list
   */
  useEffect(() => {
    axios
      .get(`${config.url}/membership/list`)
      .then(({ data }) => {
        setMemberships(data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    if (!email) {
      layout.errorAlert({ message: `Debes ingresar un correo para el alumno` });
      return;
    }

    if (!payment) {
      layout.errorAlert({ message: `Debe seleccionar un método de pago` });
      return;
    }
    if (!salesmanId) {
      layout.errorAlert({ message: `Debe seleccionar un vendedor` });
      return;
    }

    if (!memberValue) {
      layout.errorAlert({ message: `Debe ingresar un alumno` });
      return;
    }

    if (!membership) {
      layout.errorAlert({ message: `Debe ingresar un plan` });
      return;
    }
    setLoading(true);

    let PRICE = membership.price;

    if (discount.points > 0) {
      PRICE = PRICE - discount.points;
    }

    try {
      let document = {
        salesmanId: salesmanId._id, //
        shiftId: location.state.shiftId, //
        payment: payment.code, //
        memberId: memberValue && memberValue._id, //
        campusId: headquarter._id, //
        membershipId: membership._id,
        totalPrice: PRICE,
        points: discount.points > 0 ? true : false,
        newEmail: email,
        referral: referal?._id || null,
      };

      await axios.post(
        `${config.url}/enrollment/create`,
        { ...document },
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );

      layout.successAlert({ message: `Venta creada exitósamente` });
      layout.useConfetti();
      setLoading(false);
      navigate(-1);
    } catch (err) {
      console.log(err);
      layout.successAlert({ message: `Error al crear la venta` });

      setLoading(false);
    }
  };

  function handleLegacyPoints({ target: { value } }) {
    if (value === "true") {
      setDiscount({
        ...discount,
        points: 0,
        isActive: false,
        amount: 0,
        newTotal: 0,
      });
      return;
    }

    let points = memberValue.points; // 10% discount

    let discountedPrice = membership.price - points;
    let discountedAmount = membership.price - discountedPrice;

    setDiscount({
      ...discount,
      isActive: true,
      points: points,
      amount: discountedAmount,
      newTotal: discountedPrice,
    });
  }

  return (
    <>
      {loading && <Spinner />}

      <div
        className="relative h-full flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
        style={{ backgroundColor: "rgb(17, 24, 39)" }}
      >
        <div
          className="rounded-t  mb-0 py-6 "
          style={{ backgroundColor: "rgb(17, 24, 39)", paddingLeft: 40 }}
        >
          <div className="text-center flex items-center">
            <AddShoppingCartIcon sx={{ marginRight: 2, color: "#ccc" }} />
            <h6 className="text-white text-xl">
              Ingresa los datos para renovar membresia
            </h6>
          </div>
        </div>

        <div
          className="flex-auto px-4 lg:px-10 py-10 pt-0"
          style={{ backgroundColor: "rgb(17, 24, 39)" }}
        >
          <form>
            <div className="flex flex-wrap pt-6">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el vendedor
                  </label>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Selecciona un vendedor"
                      />
                    )}
                    clearIcon={false}
                    options={salesman}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option._id}>
                          {option.name}
                        </li>
                      );
                    }}
                    sx={{
                      padding: 0,
                      border: "none",
                      marginRight: 2,
                      backgroundColor: "#cbd5e1",
                    }}
                    onChange={(e, value) => setSalesmanId(value)}
                    getOptionLabel={(option) => option.name}
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Método de pago
                  </label>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Selecciona un método de pago"
                      />
                    )}
                    clearIcon={false}
                    options={PAYMENTS}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option._id}>
                          {option.name}
                        </li>
                      );
                    }}
                    sx={{
                      padding: 0,
                      border: "none",
                      marginRight: 2,
                      backgroundColor: "#cbd5e1",
                    }}
                    onChange={(e, value) => setPayment(value)}
                    getOptionLabel={(option) => option.name}
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa el alumno
                  </label>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Selecciona un alumno"
                      />
                    )}
                    clearIcon={false}
                    options={legacy.members}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option._id}>
                          {option.id_number} {option.name}
                        </li>
                      );
                    }}
                    disablePortal
                    sx={{
                      padding: 0,
                      border: "none",
                      marginRight: 2,
                      backgroundColor: "#cbd5e1",
                    }}
                    onChange={(e, v) => setMemberValue(v)}
                    getOptionLabel={(option) =>
                      option.id_number || "Default Label"
                    }
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Ingresa referido
                  </label>
                  <Autocomplete
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Selecciona un referido "
                      />
                    )}
                    clearIcon={false}
                    options={legacy.members}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option._id}>
                          {option.id_number} {option.name}
                        </li>
                      );
                    }}
                    disablePortal
                    sx={{
                      padding: 0,
                      border: "none",
                      marginRight: 2,
                      backgroundColor: "#cbd5e1",
                    }}
                    onChange={(e, v) => setReferral(v)}
                    getOptionLabel={(option) =>
                      option.id_number || "Default Label"
                    }
                    type="text"
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  />
                </div>
              </div>
              {memberValue && (
                <div className="w-full lg:w-4/12 px-4">
                  <div className="relative w-full mb-6">
                    <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                      Ingresa correo alumno
                    </label>
                    <TextField
                      placeholder="Correo ingresado"
                      value={email}
                      onChange={({ target }) => newEmail(target.value)}
                      sx={{
                        padding: 0,
                        marginRight: 2,
                        border: "none",
                        background: "#cbd5e1",
                      }}
                      variant="outlined"
                      type="text"
                      className="rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                  </div>
                </div>
              )}
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-6">
                  <label style={{ fontSize: 12, color: "#cbd5e1" }}>
                    Selecciona el plazo del plan de elección
                  </label>
                  <Autocomplete
                    className="border-0  placeholder-black text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="combo-box-demo"
                    placeholder="Plan"
                    options={memberships}
                    sx={{
                      padding: 0,
                      border: "none",
                      marginRight: 2,
                      backgroundColor: "#cbd5e1",
                    }}
                    onChange={(e, v) => setMemberShip(v)}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField {...params} placeholder="Plan socio" />
                    )}
                  />
                </div>
              </div>
            </div>

            <div>
              <div
                style={{
                  width: "100%",
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <div className="flex flex-row mt-2 w-full items-center">
                  <LoyaltyIcon sx={{ color: "#ccc", marginRight: 4 }} />
                  <h2 className="flex flex-row w-full items-center text-white">
                    ¿Usar Legacy puntos? - Tienes{" "}
                    {memberValue ? memberValue.points : 0} Legacy puntos
                    <span style={{ marginLeft: "auto" }}>
                      <label className="mr-2 text-xs">Aplicar</label>
                      <input
                        type={"checkbox"}
                        value={discount.isActive}
                        onChange={handleLegacyPoints}
                      />
                    </span>
                  </h2>
                </div>
                {/*     <div className="flex flex-row mt-2 w-full items-center">
                  <DiscountIcon sx={{ color: "#ccc", marginRight: 4 }} />
                  <h2 className="flex flex-row w-full items-center text-white">
                    ¿Tienes un descuento?
                    <Autocomplete
                      disabled
                      renderInput={(params) => (
                        <TextField {...params} placeholder="Cupones" />
                      )}
                      options={[{ label: "10%" }]}
                      sx={{
                        padding: 0,
                        border: "unset",
                        marginLeft: "auto",
                        width: 130,
                        backgroundColor: "#cbd5e1",
                      }}
                      onChange={handleDiscount}
                      type="text"
                      className="border-0   text-blueGray-600 rounded text-sm shadow focus:outline-none focus:ring ease-linear transition-all duration-150"
                    />
                  </h2>
                </div> */}

                <div className="flex flex-row mt-3 w-full items-center">
                  <PaidIcon sx={{ color: "#ccc", marginRight: 4 }} />
                  <h2 className="flex flex-row w-full items-center text-white">
                    Subtotal{" "}
                    <p
                      style={{
                        marginLeft: "auto",
                        fontSize: 22,
                        fontWeight: 500,
                      }}
                    >
                      {membership && formatCLP(membership.price)}
                    </p>
                  </h2>
                </div>
                <div className="flex flex-row mt-3 w-full items-center">
                  <PaidIcon sx={{ color: "#ccc", marginRight: 4 }} />
                  <h2 className="flex flex-row w-full items-center text-white">
                    Descuentos{" "}
                    <p
                      style={{
                        marginLeft: "auto",
                        fontSize: 22,
                        fontWeight: 500,
                      }}
                    >
                      {discount.isActive
                        ? formatCLP(discount.amount)
                        : formatCLP(0)}
                    </p>
                  </h2>
                </div>
                <div className="flex flex-row mt-3 w-full items-center">
                  <PaidIcon sx={{ color: "#ccc", marginRight: 4 }} />
                  <h2 className="flex flex-row w-full items-center text-white">
                    Total{" "}
                    <p
                      style={{
                        marginLeft: "auto",
                        fontSize: 22,
                        fontWeight: 500,
                      }}
                    >
                      {discount.isActive && formatCLP(discount.newTotal)}
                      {!discount.isActive &&
                        membership &&
                        formatCLP(membership.price)}
                      {!discount.isActive && !membership && formatCLP(0)}
                    </p>
                  </h2>
                </div>
              </div>
            </div>

            <button
              onClick={handleSubmit}
              style={{ height: 50, width: "200px", marginLeft: 25 }}
              className=" bg-lightBlue-800 mt-8 text-white active:bg-lightBlue-600 font-bold uppercase text-xs  py-2 rounded shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150"
              type="button"
            >
              Publicar
            </button>
          </form>
        </div>
      </div>
    </>
  );
}
