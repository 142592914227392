import { useAuth } from "context/auth/auth";
import { useLayout } from "context/layout/layout";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatRut } from "utils";
import logo from "../../../assets/temple.png";

export default function MemberLogin() {
  const auth = useAuth();
  const layout = useLayout();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    id_number: "",
    pass: "",
  });

  const handleChange = ({ target: { value, name } }) => {
    if (name === "id_number") {
      return setUser({
        ...user,
        id_number: formatRut(value),
      });
    }
    return setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    if (Object.values(user).some((v) => v === "")) {
      console.log({ user });
      return layout.errorAlert({
        message: "Todos los campos son obligatorios",
      });
    }

    try {
      let isAuthenticated = await auth.requestMemberAuthentication({
        credentials: user,
      });

      if (isAuthenticated) {
        layout.successAlert({
          message: "Authenticacion realizada con exito",
        });

        setTimeout(() => {
          navigate(`/member/${isAuthenticated._id}`);
        }, 2000);
      }
    } catch (err) {
      return layout.errorAlert({
        message: "Error al iniciar sesion",
      });
    }
  };

  return (
    <>
      <LoginContainer>
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
          <div className="rounded-t mb-0 px-6 py-6">
            <img src={logo} alt="..." />
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Ingresa tu rut
                </label>
                <input
                  type="email"
                  maxLength={10}
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Ej: 18767225-2"
                  value={formatRut(user.id_number)}
                  onChange={handleChange}
                  name="id_number"
                />
              </div>

              <div className="relative w-full mb-3">
                <label
                  className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                  htmlFor="grid-password"
                >
                  Ingresa los ultimos 4 digitos de tu rut
                </label>
                <input
                  type="password"
                  className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  placeholder="Ej: 2252"
                  value={user.pass}
                  onChange={handleChange}
                  name="pass"
                />
              </div>

              <div className="text-center mt-6">
                <button
                  onClick={handleSubmit}
                  className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                  type="button"
                >
                  Ingresar
                </button>
              </div>
            </form>
          </div>
        </div>
        <div className="flex flex-wrap mt-6 relative"></div>
      </LoginContainer>
    </>
  );
}

const LoginContainer = ({ children }) => {
  return (
    <div className="container mx-auto px-4" style={{ maxHeight: "100vh" }}>
      <div className="flex content-center items-center justify-center">
        <div className="w-full lg:w-4/12 px-4">{children}</div>
      </div>
    </div>
  );
};
