import React, { useEffect, useState } from "react";
import Sidebar from "components/Sidebar/Sidebar.js";
import CardTable from "components/Cards/CardTable";
import axios from "axios";
import { TableDataItem } from "components/Cards/CardTable";
import { useStore } from "../../context/store/store";
import { Spinner } from "components/Spinner";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { IconButton } from "@mui/material";
import "./index.css";
import config from "services/config";
import DoDisturbAltIcon from "@mui/icons-material/DoDisturbAlt";
import { formatCLP } from "utils";
import Tooltip from "@mui/material/Tooltip";
import VerifiedIcon from "@mui/icons-material/Verified";

const ACTIONS = "Acciones";

const PRODUCT_HEADERS = [
  "Nombre",
  "Código",
  "Stock",
  "Descripcion",
  "Habilitado",
  "Retail",
];
const ADMIN_HEADERS = [...PRODUCT_HEADERS, ACTIONS];

export function ProductsList({ color = null }) {
  const navigate = useNavigate();

  const store = useStore();
  const [products, setProductos] = useState(null);
  const [loading, setLoading] = useState(false);
  const [temp, setTemp] = useState(null);

  const [query, setQuery] = useState("");

  useEffect(() => {
    if (query.length > 0 && temp && temp.length > 0) {
      let filtered = temp.filter((p) =>
        p.name.toLowerCase().includes(query.toLocaleLowerCase())
      );
      setProductos(filtered);
    }

    if (query === "") {
      setProductos(temp);
    }
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    axios
      .get(`${config.url}/products/showall`)
      .then((res) => {
        let filtered = res.data.products.filter((p) => p.enabled);
        setProductos(filtered);
        setTemp(filtered);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  /*   const handleDelete = async ({ product_id }) => {
    setLoading(true);
    try {
      await store.deleteProduct({ product_id });
      let newproducts = products.filter((p) => p._id !== product_id);
      setLoading(false);
      setProductos(newproducts);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }; */

  const handleDisabled = async (product_id) => {
    try {
      let { data } = await axios.post(
        `${config.url}/products/update/${product_id}`,
        {
          enabled: false,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  function navigateToDetails(detail) {
    navigate(`/product/view/${detail._id}`, {
      state: { detail: detail },
    });
  }

  return (
    <>
      {loading && <Spinner />}
      <Sidebar />
      <div className="relative" style={{ marginLeft: 200 }}>
        <div className="relative " style={{ paddingBottom: 95 }}></div>
        <div className=" mx-auto w-full -m-24">
          <div className="flex flex-wrap">
            <div className="w-full  h-full">
              <CardTable
                title={"LISTA DE PRODUCTOS"}
                headers={ADMIN_HEADERS}
                add="Agregar producto"
                routeadd="/products/add"
                color={"dark"}
                filterComponent={true}
                query={query}
                setQuery={setQuery}
                filterPlaceholder="Buscar producto por nombre"
              >
                {products &&
                  products.length > 0 &&
                  products.map((product, idx) => {
                    return (
                      <tr>
                        <TableDataItem
                          sx={{
                            fontWeight: 600,
                            textAlign: "left",
                            transform: "translateX(25px)",
                            fontSize: 15,
                          }}
                        >
                          {product.name}
                        </TableDataItem>

                        <TableDataItem>{product.code}</TableDataItem>
                        <TableDataItem>{product.inventory}</TableDataItem>
                        <TableDataItem>{product.description}</TableDataItem>
                        <TableDataItem>
                          {product.enabled ? "true" : "false"}
                        </TableDataItem>
                        <TableDataItem
                          sx={{
                            fontWeight: 600,
                            fontSize: 16,
                          }}
                        >
                          {formatCLP(product.prices.retail)}
                        </TableDataItem>

                        <TableDataItem clickable>
                          <Tooltip title="Editar">
                            <IconButton
                              disableTouchRipple
                              onClick={() => navigateToDetails(product)}
                            >
                              <EditIcon sx={{ color: "#ccc" }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip
                            title={
                              product.enabled ? "Deshabilitar" : "Habilitar"
                            }
                          >
                            <IconButton
                              disableTouchRipple
                              onClick={() => handleDisabled(product._id)}
                            >
                              {product.enabled ? (
                                <DoDisturbAltIcon sx={{ color: "#ccc" }} />
                              ) : (
                                <VerifiedIcon sx={{ color: "#ccc" }} />
                              )}
                            </IconButton>
                          </Tooltip>
                        </TableDataItem>
                      </tr>
                    );
                  })}
              </CardTable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
