import axios from "axios";
import config from "services/config";

export class AuthService {
  static async authenticate({ credentials }) {
    console.log({ config });
    try {
      const { data } = await axios.post(
        `${config.url}/auth/authenticate`,
        credentials
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
  static async authenticateMember({ credentials }) {
    try {
      const { data } = await axios.post(
        `${config.url}/auth/authenticate/member`,
        credentials
      );
      return data;
    } catch (error) {
      throw error;
    }
  }
}
