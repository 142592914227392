import { Autocomplete, TextField } from "@mui/material";
import Sidebar from "components/Sidebar/Sidebar";
import { Spinner } from "components/Spinner";
import { useLayout } from "context/layout/layout";
import { useStore } from "context/store/store";
import React from "react";
import { validateEmail } from "utils";
import { defaultUser } from "utils/state";

export const AddUser = () => {
  return (
    <>
      <Sidebar />
      <Wrapper>
        <AddUserSettings />
      </Wrapper>
    </>
  );
};
const Wrapper = ({ children }) => {
  return (
    <div className="relative h-full" style={{ marginLeft: 200 }}>
      <div className="relative h-full" style={{ paddingBottom: 95 }}></div>
      <div className=" mx-auto w-full -m-24" style={{ height: "100vh" }}>
        <div className="flex flex-wrap h-full">
          <div className="w-full h-full">{children}</div>
        </div>
      </div>
    </div>
  );
};

function AddUserSettings() {
  const layout = useLayout();
  const store = useStore();
  const [loading, setLoading] = React.useState(false);
  const [user, setUser] = React.useState(defaultUser);

  const handleSubmit = () => {
    if (Object.values(user).some((v) => v === "")) {
      layout.errorAlert({ message: "Todos los campos sob obligatorios" });
      return;
    }

    let isValidEmail = validateEmail(user.email);

    if (!isValidEmail) {
      layout.errorAlert({ message: "Ingresar un correo valido" });
      return;
    }

    setLoading(true);
    store
      .createUser(user)
      .then((res) => {
        setLoading(false);
        setUser(defaultUser);
        layout.successAlert({ message: "Usuario creado con exito" });
        console.log(res);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  return (
    <>
      {loading && <Spinner />}

      <div
        className="relative h-full flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0"
        style={{ backgroundColor: "rgb(17, 24, 39)" }}
      >
        <div
          className="rounded-t  mb-0 py-6 "
          style={{ backgroundColor: "rgb(17, 24, 39)", paddingLeft: 57 }}
        >
          <div className="text-center flex justify-between">
            <h6 className="text-white  text-xl font-bold">CREAR USUARIO</h6>
          </div>
        </div>

        <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
          <form>
            <div className="flex flex-wrap pt-6">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Nombre
                  </label>
                  <TextField
                    className="border-0  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow  w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    sx={{ width: "100%" }}
                    value={user.name}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        name: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Email
                  </label>
                  <TextField
                    className="border-0  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    variant="outlined"
                    sx={{ width: "100%" }}
                    value={user.email}
                    onChange={(e) =>
                      setUser({
                        ...user,
                        email: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    password
                  </label>
                  <TextField
                    className="border-0  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="outlined-basic"
                    type={"password"}
                    variant="outlined"
                    sx={{ width: "100%" }}
                    value={user.password}
                    onChange={(e) => {
                      setUser({
                        ...user,
                        password: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="grid-password"
                  >
                    Rol
                  </label>
                  <Autocomplete
                    className="border-0  placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    id="combo-box-demo"
                    options={[{ label: "Usuario" }, { label: "SuperAdmin" }]}
                    value={user.role}
                    onChange={(e, value) =>
                      setUser({
                        ...user,
                        role: value,
                      })
                    }
                    onInputChange={(e, value) => {
                      setUser({
                        ...user,
                        role: value,
                      });
                    }}
                    inputValue={user.role}
                    sx={{ padding: 0, border: "unset" }}
                    renderInput={(params) => <TextField {...params} />}
                  />
                </div>
              </div>
            </div>
            <div
              className="my-6 w-full"
              style={{ transform: "translateX(23px)" }}
            >
              <button
                onClick={handleSubmit}
                style={{ height: 50, width: "46%" }}
                className=" bg-lightBlue-500  text-white active:bg-lightBlue-600 font-bold uppercase text-xs rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
              >
                Publicar
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
