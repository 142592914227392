import React from "react";
import "./index.css";
import ReactDOM from "react-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/styles/tailwind.css";

// layouts

import { RoutesApplication } from "routes";
import { AuthProvider } from "context/auth/auth";
import { LayoutProvider } from "context/layout/layout";
import { StoreProvider } from "context/store/store";
import { LegacyProvider } from "context/legacy/legacy";

const Application = () => {
  return (
    <LayoutProvider>
      <AuthProvider>
        <StoreProvider>
          <LegacyProvider>
            <RoutesApplication />
          </LegacyProvider>
        </StoreProvider>
      </AuthProvider>
    </LayoutProvider>
  );
};

ReactDOM.render(<Application />, document.getElementById("root"));
