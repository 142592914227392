import React, { useEffect, useState } from "react";
import Sidebar from "components/Sidebar/Sidebar.js";
import CardTable from "components/Cards/CardTable";
import axios from "axios";
import { TableDataItem } from "components/Cards/CardTable";
/* import { useStore } from "../../context/store/store";
 */ import { Spinner } from "components/Spinner";
/* import { useStore } from "context/store/store";
 */ /* import VisibilityIcon from "@mui/icons-material/Visibility";
 */ import config from "services/config";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { IconButton, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SwitchAccessShortcutAddIcon from "@mui/icons-material/SwitchAccessShortcutAdd";
import { useAuth } from "context/auth/auth";
import DoorbellIcon from "@mui/icons-material/Doorbell";
import { useLayout } from "context/layout/layout";
const productsHeaders = ["ID Alumno", "Fecha", "Ver"];
const ACTIONS = ["Acciones"];

export function ProgressList({ color = null }) {
  const auth = useAuth();
  const layout = useLayout();
  const navigate = useNavigate();
  const [members, setMembers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [temp, setTemp] = useState(null);
  const [query, setQuery] = useState("");

  useEffect(() => {
    if (query.length > 0 && temp && temp.length > 0) {
      let filtered = temp.filter((p) => p.id_number.includes(query));
      setMembers(filtered);
    }

    if (query === "") {
      setMembers(temp);
    }
    // eslint-disable-next-line
  }, [query]);

  useEffect(() => {
    axios
      .get(`${config.url}/progress/list`)
      .then((res) => {
        console.log(res.data);
        setMembers(res.data);
        setTemp(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  /*   const handleDelete = async ({ member_id }) => {
    console.log(member_id);
    setLoading(true);
    try {
      await store.deleteMember({ member_id });
      let newproducts = members.filter((p) => p._id !== member_id);
      setLoading(false);
      setMembers(newproducts);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }; */

  const seeDetails = ({ member_id, member }) => {
    navigate(`/members/view/${member_id}`);
  };

  const handleFreepass = ({ memberId }) => {
    setLoading(true);
    axios
      .get(`${config.url}/members/freepass/subtract/${memberId}`)
      .then(({ data }) => {
        setLoading(false);
        layout.successAlert({ message: "Freepass descontado exitosamente" });
      })
      .catch((err) => {
        setLoading(false);
        layout.errorAlert({ message: "Error en el servidor" });
      });
  };

  const handleGymVisit = ({ memberId }) => {
    setLoading(true);
    axios
      .get(`${config.url}/visit/new-visit/${memberId}`)
      .then(({ data }) => {
        setLoading(false);
        layout.successAlert({ message: "Visita registrada exitosamente" });
      })
      .catch((err) => {
        setLoading(false);
        layout.errorAlert({ message: "Error en el servidor" });
      });
  };

  return (
    <>
      {loading && <Spinner />}
      <Sidebar />
      <div className="relative" style={{ marginLeft: 200 }}>
        <div className="relative " style={{ paddingBottom: 95 }}></div>
        <div className=" mx-auto w-full -m-24">
          <div className="flex flex-wrap">
            <div className="w-full  h-full">
              <CardTable
                title={"Lista de Evaluaciones"}
                headers={productsHeaders}
                filterComponent={true}
                add={"Nueva evaluación"}
                routeadd={"/admin/progress"}
                query={query}
                setQuery={setQuery}
                color={"dark"}
                filterPlaceholder="Buscar usuario por rut"
              >
                {members &&
                  members.length > 0 &&
                  members.map((m, idx) => {
                    return (
                      <tr>
                        <TableDataItem
                          sx={{
                            fontWeight: 600,
                            fontSize: 12,
                            textAlign: "left",
                            transform: "translateX(20px)",
                          }}
                        >
                          {m.memberId}
                        </TableDataItem>

                        <TableDataItem sx={{ fontSize: 12 }}>
                          {new Date(m.date).toLocaleDateString()}
                        </TableDataItem>

                        <TableDataItem>
                          <IconButton
                            onClick={() =>
                              seeDetails({
                                member_id: m._id,
                                member: m,
                              })
                            }
                          >
                            <VisibilityIcon
                              sx={{ color: "#ccc", fontSize: 20 }}
                            />
                          </IconButton>
                        </TableDataItem>
                      </tr>
                    );
                  })}
              </CardTable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
