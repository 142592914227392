import React, { useEffect, useRef } from "react";
import * as d3 from "d3";
import { formatCLP } from "utils";

let colors = ["#24526e", "#0c1f33", "#1a3d54", "#3a6883", "#0e4d6c"];

export const SalesChart = ({ sales }) => {
  const [chartVisibility, setChartVisibility] = React.useState("none");
  const chartRef = useRef(null);

  useEffect(() => {
    if (!sales || !Array.isArray(sales) || sales.length === 0) return;

    const renderChart = () => {
      let newSales = sales.map((s, i) => ({ ...s, color: colors[i] }));

      const container = chartRef.current.parentElement;
      const width = container.clientWidth - 20;
      const height = 300;
      const svg = d3
        .select(chartRef.current)
        .attr("width", width)
        .attr("height", height);

      const radius = (Math.min(width, height) / 2) * 0.8;
      const colorsScale = d3.scaleOrdinal(colors);

      const arc = d3
        .arc()
        .innerRadius(radius * 0.6)
        .outerRadius(radius);

      const pie = d3.pie().value((d) => d.totalSales);

      const g = svg
        .append("g")
        .attr("transform", `translate(${width / 2}, ${height / 2})`);

      let tooltip = d3
        .select(".chart-container")
        .append("div")
        .style("visibility", "hidden")
        .style("position", "absolute")
        .style("padding", "0.2rem 1rem")
        .style("border-radius", "5px")
        .style("color", "#fff");
      const arcs = g
        .selectAll(".arc")
        .data(pie(newSales))
        .enter()
        .append("g")
        .attr("class", "arc")
        .style("cursor", "pointer")
        .on("mouseover", (e, d) => {
          tooltip
            .style("visibility", "visible")
            .style("background-color", d.data.color)
            .style("font-size", 12)
            .text(`${d.data.name}: ${formatCLP(d.data.totalSales)}`)
            .style("left", "-10px") // Position tooltip based on mouse position
            .style("top", "-10px");
        })
        .on("mousemove", (e) => {
          tooltip
            .style("top", e.pageY - 5 + "px")
            .style("left", e.pageX - 5 + "px");
        })
        .on("mouseout", () => {
          tooltip.style("visibility", "hidden");
        });

      arcs
        .append("path")
        .attr("d", arc)
        .attr("fill", (d, i) => colorsScale(i));
    };

    setTimeout(() => {
      setChartVisibility("");
      renderChart();
    }, 1500);
  }, [sales]);

  return (
    <div
      className="chart-container rounded"
      style={{
        textAlign: "center",
        backgroundColor: "#082f49",
        height: "auto",
        paddingBottom: "20px",
        marginBottom: "30px",
      }}
    >
      <p className="text-white py-3">Ventas por vendedor</p>
      <svg
        ref={chartRef}
        style={{
          display: chartVisibility,
          overflow: "visible",
          margin: "0 auto",
          width: "100%",
        }}
      />
      {sales &&
        sales.map((s, index) => {
          const percentage = (
            (s.totalSales /
              sales.reduce((acc, curr) => acc + curr.totalSales, 0)) *
            100
          ).toFixed(1);
          return (
            <div key={index} className="flex items-center px-3 py-1">
              <div
                style={{
                  backgroundColor: colors[index],
                  width: 20,
                  height: 10,
                  marginRight: 5,
                  border: ".5px solid #ccc",
                }}
              ></div>{" "}
              <p
                className="text-xs text-white"
                style={{
                  display: chartVisibility,
                  marginRight: "auto",
                  color: colors[index],
                }}
              >
                {s.name} ({percentage}%)
              </p>
              <p
                className="text-xs text-white"
                style={{ display: chartVisibility, color: colors[index] }}
              >
                {formatCLP(s.totalSales)}
              </p>
            </div>
          );
        })}
    </div>
  );
};
