const { createContext, useContext, useState } = require("react");

const LayoutContext = createContext(null);

let colors = {
  success: "bg-emerald-500",
  error: "bg-red-500",
  info: "bg-lightBlue-500",
};

let defaultLayout = {
  bg_theme: colors.info,
  message: null,
  alert: false,
  confetti: false,
};

export const LayoutProvider = ({ children }) => {
  const [layout, setLayout] = useState(defaultLayout);

  let actions = {};

  actions.error = ({ message }) => {
    setLayout({
      ...layout,
      bg_theme: colors.error,
      message: message,
      alert: true,
    });

    setTimeout(() => {
      setLayout(defaultLayout);
    }, 5000);
  };

  actions.success = ({ message }) => {
    setLayout({
      ...layout,
      bg_theme: colors.success,
      message: message,
      alert: true,
    });

    setTimeout(() => {
      setLayout(defaultLayout);
    }, 5000);
  };

  actions.useConfetti = () => {
    setLayout({
      ...layout,
      confetti: true,
    });
  };

  actions.stopConfetti = () => {
    setLayout({
      ...layout,
      confetti: false,
    });
  };

  let value = {
    bg_theme: layout.bg_theme,
    message: layout.message,
    alert: layout.alert,
    confetti: layout.confetti,
    errorAlert: actions.error,
    successAlert: actions.success,
    useConfetti: actions.useConfetti,
  };

  return (
    <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
  );
};

export const useLayout = () => useContext(LayoutContext);
