import axios from "axios";
import { createContext, useContext, useState } from "react";
import config from "services/config";
import { CostumerService } from "services/costumers";
import { DailyService } from "services/daily";
import { DailyB2bService } from "services/daily-b2b";
import { MemberService } from "services/members";
import { MessageService } from "services/messages";
import { ProductService } from "services/products";
import { ReportsService } from "services/reports";
import { ReportsB2BService } from "services/reports-b2b";
import { SalesService } from "services/sales";
import { UserService } from "services/users";
import { WarehouseService } from "services/warehouse";

const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const [totalSales, setTotalSales] = useState(null);
  const [totalProducts, setTotalProducts] = useState(null);
  const [topSellingProducts, setTopSellingProducts] = useState(null);
  const [salesBySalesman, setSalesBySalesman] = useState(null);
  const [weeklySales, setWeeklySales] = useState(null);
  const [monthlySales, setMonthlySales] = useState(null);
  const [bussines, setBussines] = useState(null);
  const [totalToday, setTotalToday] = useState(null);
  const [yearlySales, setYearlySales] = useState(null);
  const [risk, setRisk] = useState(null);
  const [debts, setDebts] = useState(null);

  //

  const [memberkpis, setMemberKpis] = useState(null);
  const [b2bMonthlySales, setb2bMonthlySales] = useState(null);
  const [b2bTopSellingProducts, setb2bTopSellingProducts] = useState(null);
  const [b2bWeeklySales, setb2bWeeklySales] = useState(null);
  const [b2bYearlySales, setb2bYearlySales] = useState(null);
  const [b2bTotalToday, setb2bTotalToday] = useState(null);
  const [b2bRisk, setB2BRisk] = useState(null);

  let actions = {};

  actions.getTotalProducts = async function () {
    let response = await ReportsService.getTotalProducts();
    setTotalProducts(response.totalProducts);
  };
  actions.getTotalSales = async function () {
    let response = await ReportsService.getTotalSales();
    setTotalSales(response.totalSales);
  };
  actions.getTopSellingProducts = async function () {
    let response = await ReportsService.getTopSellingProducts();
    setTopSellingProducts(response.topSellingProducts);
  };
  actions.getWeeklySales = async function () {
    let response = await ReportsService.getWeeklySales();
    setWeeklySales(response.weeklySales.totalSales);
  };

  actions.getMonthlySales = async function () {
    let response = await ReportsService.getMonthlySales();
    setMonthlySales(response.monthlySales.totalSales);
  };

  actions.getYearlySales = async function () {
    let response = await ReportsService.getYearlySales();
    let data = response.map((r) => r.totalSales);
    setYearlySales(data);
  };

  //

  actions.getB2BTopSellingProducts = async function () {
    let response = await ReportsB2BService.getTopSellingProducts();
    setb2bTopSellingProducts(response.topSellingProducts);
  };
  actions.getB2BWeeklySales = async function () {
    let response = await ReportsB2BService.getWeeklySales();
    setb2bWeeklySales(response.weeklySales.totalSales);
  };

  actions.getB2BMonthlySales = async function () {
    let response = await ReportsB2BService.getMonthlySales();
    setb2bMonthlySales(response.monthlySales.totalSales);
  };

  actions.getB2BYearlySales = async function () {
    let response = await ReportsB2BService.getYearlySales();
    let data = response.map((r) => r.totalSales);
    setb2bYearlySales(data);
  };

  actions.getB2BRisk = async function () {
    let risk = await ReportsB2BService.getRisk();
    setB2BRisk(risk);
  };

  //

  actions.getSalesBySalesman = async function () {
    let response = await ReportsService.getSalesBySalesman();
    setSalesBySalesman(response.salesBySalesman);
  };

  actions.deleteWarehouse = async function (warehouseId) {
    return Promise.resolve(await WarehouseService.delete(warehouseId));
  };

  actions.bussinesCompare = async function () {
    try {
      let response = await ReportsService.bussinesCompare();
      console.log({ response });
      setBussines(response);
      return response;
    } catch (err) {
      console.log(err);
    }
  };

  actions.verifyShift = async function () {
    let shift = await DailyService.verifyShift();
    let total = 0;
    setTotalToday(0);

    if (shift.paymentResume) {
      total = shift.paymentResume.find((p) => p.code === "TOTAL");
      setTotalToday(total.amount);
    }

    return shift;
  };

  actions.verifyShiftB2B = async function () {
    let shift = await DailyB2bService.verifyShift();
    let total = 0;
    setb2bTotalToday(0);

    if (shift.paymentResume) {
      total = shift.paymentResume.find((p) => p.code === "TOTAL");
      setb2bTotalToday(total.amount);
    }

    return shift;
  };

  actions.getRisk = async function () {
    let risk = await ReportsService.getRisk();
    setRisk(risk);
  };

  actions.getDebts = async function () {
    let { debts } = await ReportsService.getDebts();
    setDebts(debts);
  };

  actions.getMemberKpis = async function () {
    try {
      let { data } = await axios.get(`${config.url}/sales/reports/member_kpis`);
      setMemberKpis(data);
    } catch (err) {
      console.log(err);
    }
  };

  actions.getInitialReports = async function () {
    console.log("start reports");
    return Promise.all([
      await actions.getTotalSales(),
      await actions.getTotalProducts(),
      await actions.getTopSellingProducts(),
      await actions.getWeeklySales(),
      await actions.getMonthlySales(),
      await actions.getYearlySales(),
      await actions.getMemberKpis(),
      //
      await actions.getB2BTopSellingProducts(),
      await actions.getB2BWeeklySales(),
      await actions.getB2BMonthlySales(),
      await actions.getB2BYearlySales(),
      await actions.getB2BRisk(),
      //
      await actions.bussinesCompare(),
      await actions.verifyShift(),

      await actions.getSalesBySalesman(),
      await actions.getRisk(),
      await actions.getDebts(),
    ]);
  };

  actions.deleteProduct = async function ({ product_id }) {
    return Promise.resolve(await ProductService.delete({ product_id }));
  };
  actions.deleteMember = async function ({ member_id }) {
    return Promise.resolve(await MemberService.delete({ member_id }));
  };

  actions.deleteUser = async function ({ user_id }) {
    return Promise.resolve(await UserService.delete({ user_id }));
  };

  actions.deleteMessage = async function ({ message_id }) {
    return Promise.resolve(await MessageService.delete({ message_id }));
  };

  actions.createProduct = async function (payload) {
    console.log(payload);
    return Promise.resolve(await ProductService.create(payload));
  };

  actions.updateProduct = async function ({ payload, id }) {
    return Promise.resolve(await ProductService.update({ payload, id }));
  };

  actions.updateWarehouse = async function ({ payload, id }) {
    return Promise.resolve(await WarehouseService.update({ payload, id }));
  };

  actions.createUser = async function (payload) {
    console.log(payload);
    return Promise.resolve(await UserService.create(payload));
  };

  actions.createWarehouse = async function (payload) {
    console.log(payload);
    return Promise.resolve(await WarehouseService.create(payload));
  };

  actions.createMessage = async function (payload) {
    return Promise.resolve(await MessageService.create(payload));
  };

  actions.createMember = async function (payload) {
    console.log(payload);
    return Promise.resolve(await MemberService.create(payload));
  };
  actions.editMember = async function (payload) {
    return Promise.resolve(await MemberService.edit(payload));
  };

  actions.registerSale = async function (payload) {
    return Promise.resolve(await SalesService.create(payload));
  };

  actions.addInventory = async function (payload) {
    console.log({ payload });
    return Promise.resolve(await WarehouseService.addInventory(payload));
  };

  actions.removeInventory = async function (payload) {
    console.log({ payload });
    return Promise.resolve(await WarehouseService.removeInventory(payload));
  };

  actions.getInventory = async function (warehouseId) {
    return Promise.resolve(await WarehouseService.getInventory(warehouseId));
  };

  actions.getWarehouseMovementsFromToday = async function (warehouseId) {
    return Promise.resolve(
      await ReportsService.getWarehouseMovementsFromToday(warehouseId)
    );
  };

  actions.getWarehouse = async function (warehouseId) {
    return Promise.resolve(
      await WarehouseService.getWarehouseById(warehouseId)
    );
  };

  actions.openShift = async function ({ initialBalance }) {
    return Promise.resolve(await DailyService.openShift({ initialBalance }));
  };

  actions.openShiftB2B = async function ({ initialBalance }) {
    return Promise.resolve(await DailyB2bService.openShift({ initialBalance }));
  };

  actions.deleteShift = async function (shiftId) {
    return Promise.resolve(await DailyService.delete(shiftId));
  };

  actions.deleteShiftB2B = async function (shiftId) {
    return Promise.resolve(await DailyB2bService.delete(shiftId));
  };
  actions.createRetailClient = async function (payload) {
    return Promise.resolve(await CostumerService.create(payload));
  };
  actions.editCustomer = async function (payload) {
    return Promise.resolve(await CostumerService.update(payload));
  };

  actions.deleteRetailClient = async function (client_id) {
    return Promise.resolve(await CostumerService.delete(client_id));
  };

  let store = {
    reports: {
      b2bMonthlySales,
      b2bTopSellingProducts,
      b2bWeeklySales,
      b2bYearlySales,
      b2bTotalToday,
      b2bRisk,
      totalSales,
      totalProducts,
      debts,
      topSellingProducts,
      salesBySalesman,
      weeklySales,
      monthlySales,
      bussines,
      totalToday,
      yearlySales,
      risk,
      memberkpis,
    },
    getInitialReports: actions.getInitialReports,
    deleteProduct: actions.deleteProduct,
    deleteUser: actions.deleteUser,
    deleteMember: actions.deleteMember,
    createProduct: actions.createProduct,
    createUser: actions.createUser,
    createMember: actions.createMember,
    createWarehouse: actions.createWarehouse,
    registerSale: actions.registerSale,
    updateProduct: actions.updateProduct,
    updateWarehouse: actions.updateWarehouse,
    deleteMessage: actions.deleteMessage,
    createMessage: actions.createMessage,
    addInventory: actions.addInventory,
    removeInventory: actions.removeInventory,
    getInventory: actions.getInventory,
    getWarehouseMovementsFromToday: actions.getWarehouseMovementsFromToday,
    getWarehouse: actions.getWarehouse,
    verifyShift: actions.verifyShift,
    verifyShiftB2B: actions.verifyShiftB2B,
    openShift: actions.openShift,
    deleteShift: actions.deleteShift,
    openShiftB2B: actions.openShiftB2B,
    deleteShiftB2B: actions.deleteShiftB2B,
    editMember: actions.editMember,
    createRetailClient: actions.createRetailClient,
    deleteRetailClient: actions.deleteRetailClient,
    deleteWarehouse: actions.deleteWarehouse,
    editCustomer: actions.editCustomer,
  };

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
