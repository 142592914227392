import React, { useEffect, useState } from "react";
import Sidebar from "components/Sidebar/Sidebar.js";
import CardTable from "components/Cards/CardTable";
import axios from "axios";
import { TableDataItem } from "components/Cards/CardTable";
/* import { useStore } from "../../context/store/store";
 */ import { Spinner } from "components/Spinner";
/* import { useStore } from "context/store/store";
 */ import VisibilityIcon from "@mui/icons-material/Visibility";
import { date } from "utils";
import { useNavigate } from "react-router-dom";
import config from "services/config";

const productsHeaders = [
  "Fecha",
  "Vendedor",
  "Total Venta",
  "Total Productos",
  "Detalle",
];

export function SalesList({ color = null }) {
  const navigate = useNavigate();
  /*   const store = useStore(); */
  const [sales, setSales] = useState(null);
  const [loading /* setLoading */] = useState(false);

  useEffect(() => {
    axios
      .get(`${config.url}/sales/showall`)
      .then((res) => {
        setSales(res.data);
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);
  /* 
  const handleDelete = async ({ user_id }) => {
    setLoading(true);
    try {
      await store.deleteUser({ user_id });
      let newproducts = sales.filter((p) => p._id !== user_id);
      setLoading(false);
      setSales(newproducts);
    } catch (err) {
      setLoading(false);
      console.log(err);
    }
  }; */

  function navigateToDetails(detail) {
    navigate(`/sales/view/${detail._id}`, {
      state: { detail: detail },
    });
  }

  function getQuantity(list) {
    let counter = 0;
    for (let prod of list) {
      counter = counter + prod.quantity;
    }
    return counter;
  }

  return (
    <>
      {loading && <Spinner />}
      <Sidebar />
      <div className="relative md:ml-64 bg-blueGray-100">
        <div className="relative bg-lightBlue-600 md:pt-10 pb-32 pt-10"></div>
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <div className="flex flex-wrap mt-4">
            <div className="w-full mb-12 px-4 ">
              <CardTable
                title={"Listado de ventas"}
                headers={productsHeaders}
                add="Agregar usuario"
                routeadd="/warehouse/add"
              >
                {sales &&
                  sales.length > 0 &&
                  sales.map((sale, idx) => {
                    return (
                      <tr>
                        <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-left flex items-center">
                          <span
                            className={
                              "ml-3 font-bold " +
                              +(color === "light"
                                ? "text-blueGray-600"
                                : "text-white")
                            }
                          >
                            {date(sale.date)}
                          </span>
                        </th>
                        <TableDataItem>{sale.salesman.name}</TableDataItem>
                        <TableDataItem>{sale.totalPrice}</TableDataItem>
                        <TableDataItem>
                          {getQuantity(sale.products)}
                        </TableDataItem>
                        <TableDataItem
                          clickable
                          onClick={() => navigateToDetails(sale)}
                        >
                          <VisibilityIcon sx={{ color: "#60a5fa" }} />
                        </TableDataItem>
                      </tr>
                    );
                  })}
              </CardTable>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
