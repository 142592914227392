import React, { useEffect, useState } from "react";
import Sidebar from "components/Sidebar/Sidebar.js";
import { Spinner } from "components/Spinner";
import portada from "./portada.png";
import "./index.css";
import AttachEmailIcon from "@mui/icons-material/AttachEmail";
import { Button, IconButton } from "@mui/material";
import config from "services/config";
import SendIcon from "@mui/icons-material/Send";
import axios from "axios";
import serviceImage from "./images/service.png";
import renewImage from "./images/renew.png";
import ms from "./images/main-a.png";
import freepass from "./images/main-b.png";

import { useLayout } from "context/layout/layout";

let files = [
  {
    filename: "service.png",
    title: "Encuesta de servicio instructores",
    img: serviceImage,
    link: "https://forms.gle/zfZiB5x22Pr9pLra9",
  },
  {
    filename: "renew.png",
    title: "Encuesta de renovación alumnos",
    img: renewImage,
    link: "https://forms.gle/ZgBznBAtWxp66ePC6",
    isPrivate: true,
  },
  {
    filename: "renew.png",
    title: "Landing Temple Legacy+",
    img: ms,
    link: "https://templegym.lobarede.cl",
    isPrivate: true,
  },
  {
    filename: "main-b.png",
    title: "Landing Dia de prueba",
    img: freepass,
    link: "https://templegym.lobarede.cl/freepass.html",
    isPrivate: true,
  },
];

export function MarketingList({ color = null }) {
  const [loading, setLoading] = useState(false);
  const [isActive, activate] = useState(false);
  const [elem, elementor] = useState(null);
  const [members, setMembers] = useState(null);

  useEffect(() => {
    axios
      .get(`${config.url}/members/showall`)
      .then(({ data }) => {
        setMembers(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {loading && <Spinner />}
      <Sidebar />
      <div className="relative" style={{ marginLeft: 215 }}>
        <div className="py-3">
          <p
            className="text-white text-2xl font-bold border-b border-blueGray-500 px-4 pb-4"
            style={{ transform: "translateY(3px)" }}
          >
            Rutinas Legacy
          </p>
        </div>

        <div className="px-12 py-6 flex gap-5 overflow-x-scroll scroll-container">
          {files.map((f, idx) => (
            <CampaignFile
              name={f.title}
              filename={f.filename}
              activate={activate}
              isActive={isActive}
              idx={idx}
              elem={elem}
              elementor={elementor}
              members={members}
              img={f.img}
              link={f.link}
              isPrivate={f.isPrivate}
            />
          ))}
        </div>
      </div>
    </>
  );
}

const CampaignFile = ({
  img,
  name,
  filename,
  activate,
  isActive,
  elem,
  idx,
  elementor,
  members,
  link,
  isPrivate = false,
}) => {
  const [member, setMember] = useState(false);
  const layout = useLayout();
  const [isSearching, searching] = useState(false);
  // Asume que el servidor está sirviendo los PDFs desde http://localhost:3000/pdfs/
  // Ajusta la URL base según tu configuración
  const baseUrl = "http://localhost:9005/files";

  useEffect(() => {
    if (isSearching) {
      activate(true);
      elementor(idx);
    }
    if (!isSearching && !member) {
      console.log(member);
      activate(false);
      elementor(null);
    }
    // eslint-disable-next-line
  }, [isSearching, member]);

  async function handleSubmit() {
    try {
      let { data } = await axios.post(
        `${config.url}/marketing/send/${member._id}`,
        {
          link,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      layout.successAlert({ message: "Completado con éxito" });
      console.log({ data });
    } catch (err) {
      console.log(err);
    }
  }

  function handleId({ target: { value } }) {
    //Comenzar a buscar al comenzar a escribir
    if (!member && value.length >= 1) {
      searching(true);
    }

    // Si borra todo se pausa la busqueda y se borra el usuario temporal
    if (value.length < 1 || value === "") {
      searching(false);
      setMember(null);
      return;
    }

    // Si el input pasa la validación comienza a buscar
    if (value.length >= 9 && !member) {
      search(value);
      return;
    }

    if (member) {
      searching(false);
      return;
    }

    searching(true);
  }

  function search(value) {
    let M = members.find((m) => m.id_number === value);
    if (M) {
      setMember(M);
      searching(false);
    }
    if (M === undefined) {
      searching(true);
    }

    /*     if (value.length >= 9 && M === undefined) {
      searching(false);
    } */
  }

  return (
    <div
      className="flex flex-col items-center"
      style={{
        opacity: isActive ? (elem === idx ? 1 : 0.5) : 1,
        pointerEvents: isActive ? (elem === idx ? "unset" : "none") : "unset",
      }}
    >
      <a
        href={isPrivate ? null : link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <div
          className="file-container mx-3 mb-3 "
          style={{ boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1);" }}
        >
          <img alt="img" src={img} className="file-image rounded" />
          <p
            className="text-center mt-4 text-white text-base font-semibold"
            style={{ height: 45, width: 200 }}
          >
            {name}
          </p>
        </div>
      </a>
      <div className=" flex flex-col items-center w-full px-6">
        <label className="text-xs text-white text-left font-extralight  opacity-50 w-full">
          Buscar por rut
        </label>
        <input
          onChange={handleId}
          placeholder="Ej: 11767244-1"
          style={{ backgroundColor: "rgb(8, 47, 73)", borderRadius: 2 }}
          className=" form-input mt-1 block w-full px-3 py-2 text-sm    shadow-sm placeholder-white text-white focus:outline-none"
        />
        {/*    <div className="mt-3 w-full flex flex-col py-1">
          <div className="flex items-center  justify-between ">
            <p className="text-white text-xs font-light px-1">
              {member?.email}
            </p>
            <IconButton className="py-2">
              <AttachEmailIcon sx={{ color: "#ccc", fontSize: 20 }} />
            </IconButton>
          </div>
          <div
            className="flex items-center  justify-between "
            style={{ opacity: 0.3 }}
          >
            <p className="text-white text-xs font-light px-1">
              {member?.phone}
            </p>

            <IconButton className="py-2">
              <WhatsAppIcon sx={{ color: "#ccc", fontSize: 20 }} />
            </IconButton>
          </div>
        </div> */}

        {isSearching && (
          <div className="flex items-center  justify-between mt-4 w-full ">
            <p className="text-white text-xs font-light px-1">Buscando...</p>
            <IconButton className="py-1">
              <AttachEmailIcon sx={{ color: "#ccc", fontSize: 20 }} />
            </IconButton>
          </div>
          /*   <div className="mt-10">
            <MiniSpinner />
          </div> */
        )}
        {member && (
          <div className="flex items-center  justify-between mt-4 w-full ">
            <p className="text-white text-xs font-light px-1">
              {member?.email}
            </p>
            <IconButton className="py-1">
              <AttachEmailIcon sx={{ color: "#ccc", fontSize: 20 }} />
            </IconButton>
          </div>
        )}
        {member && (
          <Button
            startIcon={<SendIcon style={{ fontSize: 16 }} />}
            variant="contained"
            color="primary"
            className="px-3 py-2 mt-4"
            onClick={handleSubmit}
          >
            <p className="text-xs">Enviar</p>
          </Button>
        )}
      </div>
    </div>
  );
};
