import React, { useEffect, useState } from "react";
import Sidebar from "components/Sidebar/Sidebar.js";
import CardTable from "components/Cards/CardTable";
import axios from "axios";
import { TableDataItem } from "components/Cards/CardTable";
/* import { useStore } from "../../context/store/store";
 */ import { Spinner } from "components/Spinner";
import { useStore } from "context/store/store";
import config from "services/config";
import VisibilityIcon from "@mui/icons-material/Visibility";
import FilterListIcon from "@mui/icons-material/FilterList";
import DeleteIcon from "@mui/icons-material/Delete";

import { IconButton, TextField } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { useLayout } from "context/layout/layout";
import { PAYMENTS } from "utils/state";
import { formatCLP } from "utils";
import { useAuth } from "context/auth/auth";
import { DailyHeader } from "components/DailyHeader";

let icons = {
  color: "#cbd5e1",
  fontSize: 25,
  margin: "0 10px",
};

export function DailyB2B({ color = null }) {
  const store = useStore();
  const auth = useAuth();
  const layout = useLayout();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [initialBalance, setInitialBalance] = useState(null);

  const [shift, setShift] = useState({
    isInProgress: false,
    shift: null,
  });

  const handleInitialBalance = ({ target: { value } }) => {
    setInitialBalance(value);
  };

  const [message, setMessage] = useState({
    isOpen: false,
  });

  useEffect(() => {
    store
      .verifyShiftB2B()
      .then((res) => {
        console.log({ res });
        setLoading(false);
        setShift((prevState) => ({
          ...prevState,
          isInProgress: res.isInProgress,
          shift: res.shift,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
    // eslint-disable-next-line
  }, []);

  const handleOpenShift = () => {
    setMessage({
      ...message,
      isOpen: true,
      title: `Usted esta apunto de abrir caja para el dia ${new Date().toLocaleDateString()}`,
      body: "Confirme la acción selecionada",
    });
  };

  const handleCancel = () => {
    setMessage({
      ...message,
      isOpen: false,
    });
  };

  const handleConfirm = () => {
    setLoading(true);
    setMessage({
      ...message,
      isOpen: false,
    });
    openShift();
  };

  const openShift = () => {
    store
      .openShiftB2B({ initialBalance })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setShift((prevState) => ({
          ...prevState,
          isInProgress: true,
          shift: res,
        }));
        layout.successAlert({ message: "Apertura de turno exitosa" });
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        layout.errorAlert({ message: `Error en el servicio: ${err}` });
      });
  };

  const handleConfirmDelete = () => {
    store
      .deleteShiftB2B(shift.shift._id)
      .then((res) => {
        console.log(res);
        setShift({
          ...shift,
          isInProgress: false,
          shift: null,
        });
        setMessage({
          ...message,
          isOpen: false,
        });
        layout.successAlert({ message: `Turno eliminado con éxito` });
      })
      .catch((err) => {
        console.log(err);
        layout.errorAlert({ message: `Error en el servicio: ${err}` });
      });
  };

  const handleDelete = () => {
    setMessage({
      ...message,
      isOpen: true,
      title: "Desea eliminar este turno",
      body: "Esta accioón es irreversible, verifique antes de eliminar el turno",
    });
  };

  const navigateToNewOrder = () => {
    navigate(`/sales/add`, {
      state: { shiftId: shift.shift._id, type: "retail" },
    });
  };

  /*   function calculateCash() {
      if (!shift.paymentResume) {
        return 0;
      }
      let cashItem = shift.paymentResume.find((p) => p.code === "CASH") || null;
      return cashItem.amount;
    } */

  function navigateToDetails(detail) {
    console.log(detail);
    navigate(`/sales/view/${detail._id}`, {
      state: { detail: detail },
    });
  }

  function getPaymentName(payment) {
    let obj = PAYMENTS.find((p) => p.code === payment) || null;
    if (obj) {
      return obj.name;
    }
    return "";
  }

  function handleFilter() {
    let ID = "6530104efceb095a24e9f83d";
    let newSales = shift.shift.sales.filter((s) => s.salesman._id === ID);
    let newEnrollments = shift.shift.enrollments.filter(
      (s) => s.salesman._id === ID
    );
    setShift({
      ...shift,
      shift: {
        ...shift.shift,
        sales: newSales,
        enrollments: newEnrollments,
      },
    });
  }

  async function removeMovement(movementId, movementType) {
    setLoading(true);
    try {
      await axios.post(
        `${config.url}/access_log/remove/${movementId}`,
        {
          type: movementType,
        },
        {
          headers: {
            "Content-type": "application/json",
          },
        }
      );
      layout.successAlert({ message: "Movimiento eliminado correctamente" });
      store
        .verifyShiftB2B()
        .then((res) => {
          console.log({ res });
          setLoading(false);
          setShift(res);
        })
        .catch((err) => {
          setLoading(false);

          console.log(err);
        });
    } catch (err) {
      setLoading(false);
      layout.errorAlert({ message: "Error al eliminar el movimiento" });

      console.log(err);
    }
  }

  return (
    <>
      {loading && <Spinner />}
      <Sidebar />
      <div
        className="relative"
        style={{
          marginLeft: 210,
          backgroundColor: "rgb(17, 24, 39)",
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <div>
          <div className="py-4 px-4">
            <DailyHeader shift={shift} handleOpenShift={handleOpenShift} />

            {shift && shift.shift && (
              <div>
                <div className="flex items-center">
                  <p
                    style={{ background: "#15803d", width: "fit-content" }}
                    className="px-2 text-white rounded my-2 mr-2 font-light"
                  >
                    En progreso
                  </p>
                  {/*            <p
                    style={{ background: "#0369a1", width: "fit-content" }}
                    className="px-2 text-white rounded my-2 mr-2"
                  >
                    ID {shift.shift._id}
                  </p> */}
                  <p
                    style={{
                      background: "#374151",
                      width: "fit-content",
                      display: "flex",
                    }}
                    className="px-2 text-white rounded my-1 items-center font-light"
                  >
                    Apertura: {shift.shift.timeopen.split("T")[1].slice(0, 8)}
                  </p>
                </div>
              </div>
            )}
          </div>
          {shift && shift.shift && shift.shift.sales && (
            <div style={{ width: "100%" }} className=" py-1">
              <div className="flex justify-between items-center rounded-md px-3">
                <h6 className="text-white  text-lg font-bold py-2 ">Ventas</h6>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <div className="mx-1">
                    <Button
                      sx={{ color: "#fff" }}
                      variant="contained"
                      color="success"
                      size="small"
                      onClick={navigateToNewOrder}
                    >
                      Venta
                    </Button>
                  </div>

                  <IconButton
                    sx={{ margin: 0, padding: 0 }}
                    onClick={handleDelete}
                  >
                    <DeleteIcon sx={{ ...icons, color: "#b91c1c" }} />
                  </IconButton>

                  <IconButton
                    sx={{ margin: 0, padding: 0 }}
                    onClick={handleFilter}
                  >
                    <FilterListIcon sx={{ ...icons, color: "#ccc" }} />
                  </IconButton>
                </div>
              </div>
              <CardTable
                headers={[
                  "Vendedor",
                  "Hora",
                  "Monto",
                  "Método de pago",
                  "Acciones",
                ]}
                color={"dark"}
              >
                {shift.shift.sales.map((s) => {
                  return (
                    <tr>
                      <TableDataItem
                        sx={{
                          fontWeight: 500,
                          fontSize: 14,
                          textAlign: "left",
                          transform: "translateX(25px)",
                        }}
                      >
                        {s.salesman.name}
                      </TableDataItem>
                      <TableDataItem>
                        {s.date.split("T")[1].slice(0, 8)}
                      </TableDataItem>
                      <TableDataItem>{formatCLP(s.totalPrice)}</TableDataItem>
                      <TableDataItem>{getPaymentName(s.payment)}</TableDataItem>
                      <TableDataItem>
                        <IconButton onClick={() => navigateToDetails(s)}>
                          <VisibilityIcon sx={{ color: "#ccc" }} />
                        </IconButton>
                        {auth.isAdmin && (
                          <IconButton
                            onClick={() => removeMovement(s._id, "sale")}
                          >
                            <DeleteIcon sx={{ color: "#ccc" }} />
                          </IconButton>
                        )}
                      </TableDataItem>
                    </tr>
                  );
                })}
                {shift.shift.enrollments.map((s) => {
                  return (
                    <tr>
                      <TableDataItem
                        sx={{
                          fontWeight: 500,
                          fontSize: 14,
                          textAlign: "left",
                          transform: "translateX(25px)",
                        }}
                      >
                        {s.salesman.name}
                      </TableDataItem>
                      <TableDataItem>
                        {s.date.split("T")[1].slice(0, 8)}
                      </TableDataItem>
                      <TableDataItem>
                        <div onClick={() => alert("clicked")}>
                          {formatCLP(s.totalPrice)}
                        </div>
                      </TableDataItem>
                      <TableDataItem>{getPaymentName(s.payment)}</TableDataItem>
                      <TableDataItem>
                        <IconButton onClick={() => navigateToDetails(s)}>
                          <VisibilityIcon sx={{ color: "#ccc" }} />
                        </IconButton>
                        {auth.isAdmin && (
                          <IconButton
                            onClick={() => removeMovement(s._id, "enrollment")}
                          >
                            <DeleteIcon sx={{ color: "#ccc" }} />
                          </IconButton>
                        )}
                      </TableDataItem>
                    </tr>
                  );
                })}
              </CardTable>
            </div>
          )}

          {shift && shift.shift && shift.shift.expenses && (
            <div style={{ width: "100%" }} className=" py-2">
              <div className="flex justify-between items-center">
                <h6 className="text-white  text-lg font-bold py-3 px-3">
                  Gastos
                </h6>
              </div>
              <CardTable
                headers={["Stafff", "Monto", "Hora", "Comentarios"]}
                color={"dark"}
              >
                {shift.shift.expenses.map((s) => {
                  return (
                    <tr>
                      <TableDataItem
                        sx={{
                          textAlign: "left",
                          transform: "translateX(40px)",
                        }}
                      >
                        {s.staff.name}
                      </TableDataItem>
                      <TableDataItem>{formatCLP(s.amount)}</TableDataItem>
                      <TableDataItem>
                        {s.date.split("T")[1].slice(0, 8)}
                      </TableDataItem>

                      <TableDataItem>{s.comments}</TableDataItem>
                    </tr>
                  );
                })}
              </CardTable>
            </div>
          )}

          {shift && shift.shift && shift.shift.withdraw && (
            <div style={{ width: "100%" }}>
              <div className="flex justify-between items-center">
                <h6 className="text-white  text-lg font-bold py-3 px-3">
                  Retiro efectivo
                </h6>
              </div>
              <CardTable
                headers={["Stafff", "Monto", "Hora", "Comentarios"]}
                color={"dark"}
              >
                {shift.shift.withdraw.map((s) => {
                  return (
                    <tr>
                      <TableDataItem
                        sx={{
                          textAlign: "left",
                          transform: "translateX(40px)",
                        }}
                      >
                        {s.staff.name}
                      </TableDataItem>
                      <TableDataItem>{formatCLP(s.amount)}</TableDataItem>
                      <TableDataItem>
                        {s.date.split("T")[1].slice(0, 8)}
                      </TableDataItem>

                      <TableDataItem>{s.comments}</TableDataItem>
                    </tr>
                  );
                })}
              </CardTable>
            </div>
          )}

          <div>
            <p
              className="text-white text-lg  px-4 py-3"
              style={{ borderBottom: ".5px dotted #64748b" }}
            >
              Resumen del dia
            </p>
            <div
              className="flex justify-between px-4"
              style={{ borderBottom: ".5px dotted #64748b" }}
            >
              <p className="text-white text-sm font-light   my-2">
                Saldo inicial
              </p>
              <p className="text-white font-light text-sm my-2">
                {shift.shift && shift.shift.comments[0]}
              </p>
            </div>
            {shift.paymentResume &&
              shift.paymentResume.map((payment) => {
                if (payment.code === "WITHDRAW") {
                  return (
                    <div
                      className="flex justify-between px-4"
                      style={{ borderBottom: ".5px dotted #64748b" }}
                    >
                      <p className="text-white text-sm font-light   my-2">
                        {payment.name}
                      </p>
                      <p className="text-white font-light text-sm my-2">
                        {formatCLP(payment.amount)}
                      </p>
                    </div>
                  );
                }
                if (payment.code === "DEBT") {
                  return (
                    <div
                      className="flex justify-between   px-4"
                      style={{ borderBottom: ".5px dotted #64748b" }}
                    >
                      <p className="text-white text-sm font-light   my-2">
                        {payment.name}
                      </p>
                      <p className="text-white font-light text-sm my-2">
                        {formatCLP(payment.amount)}
                      </p>
                    </div>
                  );
                }
                if (payment.code === "TOTAL") {
                  return (
                    <div className="flex justify-between px-4 py-2">
                      <p className="text-white text-xl font-semibold my-2">
                        {payment.name}
                      </p>
                      <p className="text-white font-semibold text-xl my-2">
                        {formatCLP(payment.amount)}
                      </p>
                    </div>
                  );
                }
                return (
                  <div className="flex justify-between px-4">
                    <p className="text-white font-light text-sm my-2">
                      {payment.name}
                    </p>
                    <p className="text-white font-light text-sm my-2">
                      {formatCLP(payment.amount)}
                    </p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <Dialog
        open={message.isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "rgb(17, 24, 39)",
            borderRadius: 8,
          },
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{ backgroundColor: "rgb(17, 24, 39)", color: "#fff" }}
        >
          {message.title}
        </DialogTitle>

        <DialogContent sx={{ backgroundColor: "rgb(17, 24, 39)" }}>
          {message.title !== "Desea eliminar este turno" ? (
            <TextField
              type="number"
              onChange={handleInitialBalance}
              value={initialBalance}
              placeholder="Ingresa el saldo inicial"
              sx={{ marginRight: 2, backgroundColor: "#cbd5e1" }}
              className=" border-0  placeholder-blueGray-300 text-blueGray-600  rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150 "
            />
          ) : null}
        </DialogContent>
        <DialogActions
          sx={{
            backgroundColor: "rgb(17, 24, 39)",
            marginBottom: 1,
            marginRight: 2,
          }}
        >
          <Button
            sx={{ color: "#fff", marginRight: 1 }}
            variant="contained"
            color="success"
            onClick={
              message.title === "Desea eliminar este turno"
                ? handleConfirmDelete
                : handleConfirm
            }
          >
            Confirmar
          </Button>
          <Button
            sx={{ color: "#fff" }}
            variant="contained"
            color="error"
            onClick={handleCancel}
          >
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
