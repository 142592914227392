export const REGISTER_PRODUCT = {
  name: "",
  description: "",
  category: "",
  prices: {
    retail: "",
    wholesale: "",
    special: "",
    staff: "",
  },
  inventory: 0,
  code: "",
  limit_stock: "",
};

export const defaultProduct = {
  name: "",
  description: "",
  category: "",
  code: "",
  limit_stock: "",
  prices: {
    retail: "",
    wholesale: "",
    special: "",
  },
  options: [
    {
      name: "",
    },
  ],
  inventory: 0,
};

export const defaultUser = {
  name: "",
  email: "",
  password: "",
  role: "",
};

export const defaultMessage = {
  title: "",
  subtitle: "",
  url: "",
};

export const defaultMember = {
  name: "",
  email: "",
  phone: "",
  birthday: "",
  id_number: "",
  campus: "Temple Gym Malloco",
  subscription: {
    type: "Mensual",
    startDate: "",
  },
};

export const defaultRetailClient = {
  name: "",
  email: "",
  address: "",
  address_2: "",
  id_number: "",
  phone: "",
  type_of_client: "RETAIL",
};

export const defaultWarehouse = {
  name: "",
  campus: "",
  authorizedRoles: ["admin", "warehouse-manager", "superadmin"],
};

export const prices = {
  retail: "Retail",
  wholesale: "Mayorista",
  special: "Especial",
  staff: "Staff",
};

export const PAYMENTS = [
  {
    name: "Tarjeta débito o crédito",
    code: "CARD",
  },
  {
    name: "Efectivo",
    code: "CASH",
  },
  {
    name: "Transferencia bancaria",
    code: "BANK_TRANSFER",
  },
  {
    name: "Deuda",
    code: "DEBT",
  },
];

export const defaultStat = {
  weight: 0,
  pec: 0,
  bicep: 0,
  waist: 0,
  hips: 0,
  body_fat_omrom: 0,
  imc: 0,
  kg_fat: 0,
  muscle_fat_free: 0,
};
