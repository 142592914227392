import "./dashboard.css";
import CardTable from "components/Cards/CardTable";
import { TableDataItem } from "components/Cards/CardTable";
import { useStore } from "context/store/store";
import { formatCLP } from "utils";
import React, { useState } from "react";
import CardLineChart from "components/Cards/CardLineChart";
import {
  KeyboardDoubleArrowDown,
  KeyboardDoubleArrowRight,
} from "@mui/icons-material";
import { SalesChart } from "components/Cards/CardPieChart";

export default function Dashboard({ color = null }) {
  /*   const [debtsDetail, displayDetails] = useState(false);
   */ const [debtsDetail, displayDetails] = useState({
    active: false,
    index: 0,
  });
  const store = useStore();

  function handleDebtsDetail(debts, idx) {
    displayDetails({
      ...debtsDetail,
      active: !debtsDetail.active,
      index: idx,
    });
  }

  return (
    <div className="flex flex-col px-12 card-container">
      <div className="flex gap-4 table-data">
        <div className=" main-chart table-container" style={{ width: "60%" }}>
          <CardLineChart data={store.reports.yearlySales} />
        </div>
        {/*     <div style={{ width: "40%" }} className="table-container">
          <CardTable
            title={"Ventas por vendedor"}
            headers={["Nombre", "Total"]}
            color={"dark"}
            bgColor={"#082f49"}
            rounded={true}
          >
            {store.reports.salesBySalesman &&
              store.reports.salesBySalesman.length > 0 &&
              store.reports.salesBySalesman.map((product, idx) => {
                return (
                  <tr key={idx}>
                    <TableDataItem sx={{ textAlign: "left" }}>
                      <p className="pl-3">{product.name}</p>
                    </TableDataItem>

                    <TableDataItem sx={{ textAlign: "right" }}>
                      {" "}
                      <p className="pr-3">{formatCLP(product.totalSales)}</p>
                    </TableDataItem>
                  </tr>
                );
              })}
          </CardTable>
        </div> */}
        <div style={{ width: "40%" }} className="table-container">
          <SalesChart sales={store.reports.salesBySalesman} />
        </div>
      </div>

      <div className="flex gap-4 table-data">
        <div className="w-1/2 table-container">
          <CardTable
            title={"Consumos por vendedor"}
            headers={["Nombre", "Total", "Detalle"]}
            color={"dark"}
            bgColor={"#082f49"}
            rounded={true}
          >
            {store.reports.debts &&
              store.reports.debts.length > 0 &&
              store.reports.debts.map((staffDebt, idx) => {
                return (
                  <>
                    <tr key={idx}>
                      <TableDataItem sx={{ textAlign: "left" }}>
                        {staffDebt.name}
                      </TableDataItem>

                      <TableDataItem>
                        {" "}
                        {formatCLP(staffDebt.totalDebts)}
                      </TableDataItem>
                      <TableDataItem
                        sx={{ cursor: "pointer" }}
                        onClick={() => handleDebtsDetail(staffDebt, idx)}
                      >
                        {debtsDetail.active && debtsDetail.index === idx ? (
                          <KeyboardDoubleArrowDown sx={{ fontSize: 16 }} />
                        ) : (
                          <KeyboardDoubleArrowRight sx={{ fontSize: 16 }} />
                        )}
                      </TableDataItem>
                    </tr>
                    {debtsDetail.active && debtsDetail.index === idx && (
                      <tr style={{ backgroundColor: " #0c4a6e" }}>
                        <td colSpan="3">
                          <ul>
                            {staffDebt.sales.map((s, index) => (
                              <li
                                key={index}
                                className="w-full flex py-1"
                                style={{ opacity: 0.5 }}
                              >
                                <p className="w-full text-xs text-left pl-4 lowercase">
                                  {s.productName}
                                </p>
                                <p className="w-full text-center text-xs">
                                  {formatCLP(s.price)} - ({s.quantity}un)
                                </p>
                                <p className="w-full text-center text-xs">
                                  {new Date(s.date).toLocaleDateString() +
                                    " - " +
                                    new Date(s.date).toLocaleTimeString()}
                                </p>
                              </li>
                            ))}
                          </ul>
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
          </CardTable>
        </div>
        <div className="w-1/2 table-container">
          <CardTable
            title={"Productos mas vendidos"}
            headers={["Nombre", "Monto"]}
            color={"dark"}
            bgColor={"#082f49"}
            rounded={true}
          >
            {store.reports.topSellingProducts &&
              store.reports.topSellingProducts.length > 0 &&
              store.reports.topSellingProducts.map((product, idx) => {
                return (
                  <tr key={idx}>
                    <TableDataItem sx={{ textAlign: "left" }}>
                      {product.name}
                    </TableDataItem>
                    <TableDataItem>
                      {formatCLP(product.totalPrice)}
                    </TableDataItem>
                  </tr>
                );
              })}
          </CardTable>
        </div>
      </div>
    </div>
  );
}
